<template>
  <main class="entry_form">
    <form-policy v-if="isPolicy" @backFromPolicy="backFromPolicy" />
    <div v-else>
      <p class="entry_form_title">エントリー情報の登録</p>
      <p class="entry_form_caption">
        あなたの情報を登録フォームに入力してください。
        <br />
        全項目必須になります。
      </p>
      <template v-if="email">
        <p class="input_head">メールアドレス</p>
        <p class="email_display">{{ email }}</p>
      </template>
      <form name="applicantForm" class="input_wrapper">
        <template v-if="isFixedGraduated !== true">
          <p class="input_head">
            卒業年数・登録種別
            <span class="required">*</span>
          </p>
          <div class="select_wrapper">
            <select
              v-model="graduatedYear"
              :class="{ 'is-danger': !errors.graduatedYear.isValid }"
              name="graduated"
              required
              :disabled="isPreview === true"
            >
              <option value="">選択してください</option>
              <optgroup label="新卒">
                <option
                  v-for="graduated in graduateds.filter(
                    graduated => graduated.year < 3000,
                  )"
                  :key="graduated.year"
                  :label="`${graduatedYearToLabelName(
                    graduated.year,
                  )}年3月卒業予定`"
                  :value="graduated.year"
                >
                  {{
                    `${graduatedYearToLabelName(graduated.year)}年3月卒業予定`
                  }}
                </option>
              </optgroup>
              <optgroup />
              <option
                v-for="graduated in graduateds.filter(
                  graduated => graduated.year >= 9900,
                )"
                :key="graduated.year"
                :label="`${graduatedYearToLabelName(graduated.year)}`"
                :value="graduated.year"
              >
                {{ `${graduatedYearToLabelName(graduated.year)}` }}
              </option>
            </select>
            <p v-if="!errors.graduatedYear.isValid" class="fa-warning font-s">
              {{ errors.graduatedYear.message }}
            </p>
          </div>
        </template>
        <p class="input_head">
          姓名
          <span class="required">*</span>
        </p>
        <div class="two_input_wrapper">
          <div>
            <input
              v-model="applicantForm.data.lastname"
              :class="{ 'is-danger': !errors.name.isValid }"
              type="text"
              maxlength="14"
              placeholder="山田"
            />
            <input
              v-model="applicantForm.data.firstname"
              :class="{ 'is-danger': !errors.name.isValid }"
              type="text"
              maxlength="14"
              placeholder="太郎"
            />
          </div>
          <p v-if="!errors.name.isValid" class="fa-warning font-s">
            {{ errors.name.message }}
          </p>
        </div>
        <p class="input_head">
          セイメイ
          <span class="required">*</span>
        </p>
        <div class="two_input_wrapper">
          <div>
            <input
              v-model="applicantForm.data.lastnameKana"
              :class="{ 'is-danger': !errors.kana.isValid }"
              type="text"
              maxlength="14"
              placeholder="ヤマダ"
            />
            <input
              v-model="applicantForm.data.firstnameKana"
              :class="{ 'is-danger': !errors.kana.isValid }"
              type="text"
              maxlength="14"
              placeholder="タロウ"
            />
          </div>
          <p v-if="!errors.kana.isValid" class="fa-warning font-s">
            {{ errors.kana.message }}
          </p>
        </div>
        <div v-if="formSetting.data.birth">
          <p class="input_head">
            生年月日
            <span class="required">*</span>
          </p>
          <ul
            class="select_birth_wrapper"
            :class="{ 'is-danger': !errors.birth.isValid }"
          >
            <li>
              <select v-model="birthYear">
                <option value="">年</option>
                <option v-for="i in 69" :key="i">
                  {{ new Date().getFullYear() - 69 + i }}年
                </option>
              </select>
            </li>
            <li>
              <select v-model="birthMonth">
                <option value="">月</option>
                <option v-for="i in 12" :key="i">{{ i }}月</option>
              </select>
            </li>
            <li>
              <select v-model="birthDay">
                <option value="">日</option>
                <option v-for="i in 31" :key="i">{{ i }}日</option>
              </select>
            </li>
            <p v-if="!errors.birth.isValid" class="fa-warning font-s">
              {{ errors.birth.message }}
            </p>
          </ul>
        </div>
        <div v-if="formSetting.data.gender">
          <p class="input_head">
            性別
            <span class="required">*</span>
          </p>
          <div
            class="radio_wrapper"
            :class="{ 'is-danger': !errors.genderId.isValid }"
          >
            <input
              id="form_man"
              v-model="applicantForm.data.genderId"
              type="radio"
              name="gender"
              value="1"
            />
            <label for="form_man">男性</label>
            <input
              id="form_woman"
              v-model="applicantForm.data.genderId"
              type="radio"
              name="gender"
              value="2"
            />
            <label for="form_woman">女性</label>
            <input
              id="form_noanswer"
              v-model="applicantForm.data.genderId"
              type="radio"
              name="gender"
              value="4"
            />
            <label for="form_noanswer">回答しない</label>
            <p v-if="!errors.genderId.isValid" class="fa-warning font-s">
              {{ errors.genderId.message }}
            </p>
          </div>
        </div>
        <div v-if="formSetting.data.tel">
          <p class="input_head">
            電話番号
            <span class="required">*</span>
          </p>
          <input
            v-model="applicantForm.data.tel"
            :class="{ input: true, 'is-danger': !errors.tel.isValid }"
            type="tel"
            class="input"
            placeholder="電話番号を入力（ハイフンなし）"
            maxlength="11"
          />
          <span v-show="!errors.tel.isValid" class="fa fa-warning font-s">
            {{ errors.tel.message }}
          </span>
        </div>
        <div v-if="formSetting.data.zipcode">
          <p class="input_head">
            郵便番号
            <span class="required">*</span>
          </p>
          <input
            v-model="applicantForm.data.zipcode"
            class="input"
            :class="{ 'is-danger': !errors.zipcode.isValid }"
            type="tel"
            maxlength="7"
            placeholder="郵便番号（ハイフンなし）"
            @keyup="postalCodeJp"
          />
          <p v-if="!errors.zipcode.isValid" class="fa-warning font-s">
            {{ errors.zipcode.message }}
          </p>
        </div>
        <div v-if="formSetting.data.address">
          <p class="input_head">
            住所
            <span class="required">*</span>
          </p>
          <input
            v-model="applicantForm.data.address"
            class="input"
            :class="{ 'is-danger': !errors.address.isValid }"
            maxlength="99"
            type="text"
            placeholder="住所（都道府県から入力）"
          />
          <p v-if="!errors.address.isValid" class="fa-warning font-s">
            {{ errors.address.message }}
          </p>
        </div>
        <div v-if="formSetting.data.school">
          <p class="input_head">
            学校名
            <span class="required">*</span>
          </p>
          <input
            v-model="applicantForm.data.school"
            class="input"
            :class="{ 'is-danger': !errors.school.isValid }"
            maxlength="50"
            type="text"
            placeholder="学校名"
          />
          <p v-if="!errors.school.isValid" class="fa-warning font-s">
            {{ errors.school.message }}
          </p>
        </div>
        <div v-if="formSetting.data.faculty">
          <p class="input_head">
            学部
            <span class="required">*</span>
          </p>
          <input
            v-model="applicantForm.data.faculty"
            class="input"
            :class="{ 'is-danger': !errors.faculty.isValid }"
            maxlength="50"
            type="text"
            placeholder="学部"
          />
          <p v-if="!errors.faculty.isValid" class="fa-warning font-s">
            {{ errors.faculty.message }}
          </p>
        </div>
        <div v-if="formSetting.data.department">
          <p class="input_head">
            学科
            <span class="required">*</span>
          </p>
          <input
            v-model="applicantForm.data.department"
            class="input"
            :class="{ 'is-danger': !errors.department.isValid }"
            maxlength="50"
            type="text"
            placeholder="学科"
          />
          <p v-if="!errors.department.isValid" class="fa-warning font-s">
            {{ errors.department.message }}
          </p>
        </div>
        <div v-if="formSetting.data.department_category">
          <p class="input_head">
            文理
            <span class="required">*</span>
          </p>
          <div
            class="radio_wrapper"
            :class="{ 'is-danger': !errors.departmentCategoryId.isValid }"
          >
            <input
              id="form_bun"
              v-model="applicantForm.data.departmentCategoryId"
              type="radio"
              name="departmentCategory"
              value="1"
            />
            <label for="form_bun">文</label>
            <input
              id="form_ri"
              v-model="applicantForm.data.departmentCategoryId"
              type="radio"
              name="departmentCategory"
              value="2"
            />
            <label for="form_ri">理</label>
            <input
              id="form_other"
              v-model="applicantForm.data.departmentCategoryId"
              type="radio"
              name="departmentCategory"
              value="3"
            />
            <label for="form_other">その他</label>
            <p
              v-if="!errors.departmentCategoryId.isValid"
              class="fa-warning font-s"
            >
              {{ errors.departmentCategoryId.message }}
            </p>
          </div>
        </div>
        <flagsurvey-questions-selector
          v-if="questions.length > 0 && isPreviewFlagsurvey === true"
          :questions="questions"
          @on-select-answer="onSelectAnswer"
        />
        <p
          v-if="!errors.questions.isValid"
          class="fa-warning font-s fa-questions"
        >
          {{ errors.questions.message }}
        </p>
      </form>
      <check-policy
        v-model="checkedPolicy"
        @changeCheckPolicy="changeCheckPolicy"
        @toPolicy="toPolicy"
      />
      <div class="input_footer">
        <button
          class="submit_button"
          :class="{ 'is-disabled': checkedPolicy !== true }"
          @click.prevent="preview"
        >
          確認に進む
        </button>
        <button class="cancel_button" @click.prevent="$emit('backPage')">
          前のページに戻る
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import zipcode from 'japan-postal-code';

import GraduatedDefines from '@/defines/graduated';
import useValidation from '@/composables/useValidation';
import { setIsSelectedAnswer } from '@/components/page/line_entry/helpers/FlagsurveyQuestionsSelector';
import CheckPolicy from '@/components/page/line_entry/components/CheckPolicy';
import FlagsurveyQuestionsSelector from '@/components/page/line_entry/components/FlagsurveyQuestionsSelector';
import FormPolicy from '@/components/features/policy/components/FormPolicy';

const formSettingDefault = {
  name: false,
  gender: false,
  zipcode: false,
  address: false,
  school: false,
  faculty: false,
  department: false,
  department_category: false,
  tel: false,
  birth: false,
};

export default defineComponent({
  name: 'EntryForm',
  components: { FormPolicy, FlagsurveyQuestionsSelector, CheckPolicy },
  props: {
    email: {
      type: String,
      default: null,
    },
    graduateds: {
      type: Array,
      default: () => [],
    },
    initialGraduatedYear: {
      type: Number,
      default: null,
    },
    isPreviewFlagsurvey: {
      type: Boolean,
      default: true,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    isFixedGraduated: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['preview', 'backPage'],
  setup(props, context) {
    const store = useStore();
    const graduatedYear = ref(props.initialGraduatedYear);
    const birthYear = ref(`${new Date().getFullYear() - 21}年`);
    const birthMonth = ref('');
    const birthDay = ref('');
    const isPolicy = ref(false);
    const checkedPolicy = ref(false);
    const formSetting = reactive({ data: {} });
    const createEntryForm = (obj = {}) => ({
      email: obj.email !== undefined ? obj.email : null,
      firstname: obj.firstname !== undefined ? obj.firstname : null,
      lastname: obj.lastname !== undefined ? obj.lastname : null,
      firstnameKana: obj.firstnameKana !== undefined ? obj.firstnameKana : null,
      lastnameKana: obj.lastnameKana !== undefined ? obj.lastnameKana : null,
      genderId: obj.genderId !== undefined ? obj.genderId : null,
      graduated: obj.graduated !== undefined ? obj.graduated : null,
      school: obj.school !== undefined ? obj.school : null,
      faculty: obj.faculty !== undefined ? obj.faculty : null,
      department: obj.department !== undefined ? obj.department : null,
      departmentCategoryId:
        obj.departmentCategoryId !== undefined
          ? obj.departmentCategoryId
          : null,
      birth: obj.birth !== undefined ? obj.birth : null,
      tel: obj.tel !== undefined ? obj.tel : null,
      zipcode: obj.zipcode !== undefined ? obj.zipcode : null,
      address: obj.address !== undefined ? obj.address : null,
      tagIds: obj.tagIds !== undefined ? obj.tagIds : null,
    });
    const applicantForm = reactive({ data: createEntryForm() });
    const questions = ref([]);
    const {
      errors,
      resetErrors,
      updateErrors,
      validateRequire,
      validateMaxLength,
      validateTel,
      validateZipcode,
      validateKana,
    } = useValidation([
      'graduatedYear',
      'name',
      'kana',
      'birth',
      'genderId',
      'tel',
      'zipcode',
      'address',
      'school',
      'faculty',
      'department',
      'departmentCategoryId',
      'questions',
    ]);

    // watch
    watch(graduatedYear, val => {
      resetApplicantForm();
      fetchFormSetting();
    });
    watch(birthYear, val => {
      parseBirth(val, birthMonth.value, birthDay.value);
    });
    watch(birthMonth, val => {
      parseBirth(birthYear.value, val, birthDay.value);
    });
    watch(birthDay, val => {
      parseBirth(birthYear.value, birthMonth.value, val);
    });

    const fetchFormSetting = () => {
      if (graduatedYear.value) {
        if (props.isPreview !== true) {
          const selectedId = graduatedYearToId(graduatedYear.value);
          const yearFormSet = props.graduateds.filter(
            graduated => graduated.id === selectedId,
          );
          formSetting.data.name = yearFormSet[0].name === true;
          formSetting.data.address = yearFormSet[0].address === true;
          formSetting.data.birth = yearFormSet[0].birth === true;
          formSetting.data.department = yearFormSet[0].department === true;
          formSetting.data.department_category =
            yearFormSet[0].department_category === true;
          formSetting.data.faculty = yearFormSet[0].faculty === true;
          formSetting.data.gender = yearFormSet[0].gender === true;
          formSetting.data.school = yearFormSet[0].school === true;
          formSetting.data.zipcode = yearFormSet[0].zipcode === true;
          formSetting.data.tel = yearFormSet[0].tel === true;
          if (
            props.graduateds.find(
              graduated => graduated.year === graduatedYear.value,
            ).questions
          ) {
            questions.value = cloneDeep(props.graduateds)
              .find(graduated => graduated.year === graduatedYear.value)
              .questions.map(question => {
                question.answers = question.answers.map(answer => {
                  answer.isSelected = false;
                  return cloneDeep(answer);
                });
                return cloneDeep(question);
              });
          } else {
            questions.value = [];
          }
        }
      } else {
        formSetting.data = cloneDeep(formSettingDefault);
        questions.value = [];
      }
      resetErrors();
    };
    const resetApplicantForm = () => {
      applicantForm.data = createEntryForm();
      birthYear.value = '';
      birthMonth.value = '';
      birthDay.value = '';
      resetErrors();
    };
    const parseBirth = (tmpYear, tmpMonth, newDay) => {
      // suffixの「年」「月」「日」を削除
      if (tmpYear) tmpYear = tmpYear.slice(0, -1);
      if (tmpMonth) tmpMonth = tmpMonth.slice(0, -1);
      if (newDay) newDay = newDay.slice(0, -1);
      if (tmpYear === '' || tmpMonth === '' || newDay === '') {
        // 空選択の場合はnull
        applicantForm.data.birth = null;
      } else {
        // 年月日を0埋めしてYYYY-MM-DDに変換
        const year = `0000${tmpYear}`.slice(-4);
        const month = `00${tmpMonth}`.slice(-2);
        const day = `00${newDay}`.slice(-2);
        applicantForm.data.birth = `${year}-${month}-${day}`;
      }
    };
    const setPreviewData = (
      tmpGraduatedYear,
      previewFormSetting,
      previewQuestions,
    ) => {
      graduatedYear.value = tmpGraduatedYear;
      formSetting.data = cloneDeep(previewFormSetting);
      questions.value = cloneDeep(previewQuestions);
    };
    const graduatedYearToLabelName = year => {
      const res = GraduatedDefines.find(
        defineTitle => defineTitle.year === year,
      );
      if (res !== undefined) return res.name_entry;
      return `${year}`;
    };
    const graduatedYearToId = year => {
      const res = props.graduateds.find(
        defineTitle => defineTitle.year === year,
      );
      return res.id;
    };
    const postalCodeJp = () => {
      zipcode.get(applicantForm.data.zipcode, addr => {
        applicantForm.data.address = `${addr.prefecture}${addr.city}${addr.area}`;
      });
    };
    const toPolicy = () => {
      if (props.isPreview === true) return;
      isPolicy.value = true;
    };
    const backFromPolicy = () => {
      if (props.isPreview === true) return;
      isPolicy.value = false;
    };
    const changeCheckPolicy = val => {
      if (props.isPreview === true) return;
      checkedPolicy.value = val;
    };
    const changeGraduatedMode = () => {
      resetApplicantForm();
      graduatedYear.value = '';
    };
    const onSelectAnswer = (questionIndex, answerIndex) => {
      questions.value = setIsSelectedAnswer(
        questions.value,
        questionIndex,
        answerIndex,
      );
    };
    const validate = async () => {
      resetErrors();
      let isValid = true;
      isValid = validateRequire('graduatedYear', graduatedYear.value, 'select');
      // 姓名・姓名のバリデーション
      if (
        !validateRequire('name', applicantForm.data.lastname) ||
        !validateRequire('name', applicantForm.data.firstname) ||
        !validateMaxLength('name', applicantForm.data.lastname, 14) ||
        !validateMaxLength('name', applicantForm.data.firstname, 14)
      ) {
        isValid = false;
      }
      if (
        !validateKana('kana', applicantForm.data.lastnameKana) ||
        !validateKana('kana', applicantForm.data.firstnameKana) ||
        !validateRequire('kana', applicantForm.data.lastnameKana) ||
        !validateRequire('kana', applicantForm.data.firstnameKana) ||
        !validateMaxLength('name', applicantForm.data.lastnameKana, 14) ||
        !validateMaxLength('name', applicantForm.data.firstnameKana, 14)
      ) {
        isValid = false;
      }
      Object.keys(formSetting.data).forEach(key => {
        if (formSetting.data[key] === true) {
          switch (key) {
            case 'birth':
              // 生年月日バリデーション
              if (
                !validateRequire(key, birthYear.value, 'select') ||
                !validateRequire(key, birthMonth.value, 'select') ||
                !validateRequire(key, birthDay.value, 'select')
              ) {
                isValid = false;
              }
              break;
            case 'gender':
              // 性別バリデーション
              isValid = validateRequire(
                'genderId',
                applicantForm.data.genderId,
                'select',
              );
              break;
            case 'department_category':
              // 文理バリデーション
              isValid = validateRequire(
                'departmentCategoryId',
                applicantForm.data.departmentCategoryId,
                'select',
              );
              break;
            case 'tel':
              // 電話番号バリデーション
              if (
                !validateRequire(key, applicantForm.data[key]) ||
                !validateTel(key, applicantForm.data[key])
              ) {
                isValid = false;
              }
              break;
            case 'zipcode':
              // 郵便番号バリデーション
              if (
                !validateRequire(key, applicantForm.data[key]) ||
                !validateZipcode(key, applicantForm.data[key])
              ) {
                isValid = false;
              }
              break;
            case 'address':
              // 住所バリデーション
              if (
                !validateRequire(key, applicantForm.data[key]) ||
                !validateMaxLength(key, applicantForm.data[key], 99)
              ) {
                isValid = false;
              }
              break;
            case 'school':
              // 学校名バリデーション
              if (
                !validateRequire(key, applicantForm.data[key]) ||
                !validateMaxLength(key, applicantForm.data[key], 99)
              ) {
                isValid = false;
              }
              break;
            case 'faculty':
              // 学部バリデーション
              if (
                !validateRequire(key, applicantForm.data[key]) ||
                !validateMaxLength(key, applicantForm.data[key], 50)
              ) {
                isValid = false;
              }
              break;
            case 'department':
              // 学科バリデーション
              if (
                !validateRequire(key, applicantForm.data[key]) ||
                !validateMaxLength(key, applicantForm.data[key], 50)
              ) {
                isValid = false;
              }
              break;
            default:
              // その他必須項目バリデーション
              isValid = validateRequire(key, applicantForm.data[key]);
              break;
          }
        }
      });
      // 質問のバリデーション
      let checkAnswers = [];
      questions.value.forEach(question => {
        const answersId = question.answers.filter(answer => answer.isSelected);
        if (answersId.length === 0) {
          checkAnswers = checkAnswers.concat(false);
        } else {
          checkAnswers = checkAnswers.concat(true);
        }
      });
      const checkSurvey = checkAnswers.find(val => val === false);
      if (
        questions.value.length > 0 &&
        (checkAnswers.length === 0 || checkSurvey === false)
      ) {
        updateErrors('questions', '全ての質問に回答して下さい');
        isValid = false;
      }
      if (isValid === false) {
        return {
          success: false,
          message: '入力内容を確認して下さい',
        };
      }
      return { success: true, message: '' };
    };
    const preview = async () => {
      if (props.isPreview === true) return;
      if (checkedPolicy.value !== true) return;
      const valid = await validate();
      if (valid.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: valid.message,
          type: false,
        });
        return;
      }
      // アンケートの回答IDをまとめる
      let selectedAnswers = [];
      questions.value.forEach(question => {
        const answerIds = question.answers
          .filter(answer => answer.isSelected)
          .map(answer => answer.flag_id);
        selectedAnswers = selectedAnswers.concat(answerIds);
      });
      applicantForm.data.graduated = graduatedYear.value;
      const entryForm = cloneDeep(applicantForm.data);
      if (props.email) {
        entryForm.email = props.email;
      }
      if (selectedAnswers.length > 0) {
        entryForm.tagIds = cloneDeep(selectedAnswers);
      }
      // null, 空文字の項目をリクエストから削除
      const requestEntryFrom = {};
      Object.keys(entryForm).forEach(key => {
        if (entryForm[key]) requestEntryFrom[key] = entryForm[key];
      });
      context.emit('preview', {
        entryForm: requestEntryFrom,
        questions: cloneDeep(questions.value),
      });
    };

    // lifecycle
    onBeforeMount(() => {
      if (applicantForm.data.birth !== null) {
        const birth = moment(applicantForm.data.birth);
        birthYear.value = birth.year();
        birthMonth.value = birth.month() + 1;
        birthDay.value = birth.date();
      }
      formSetting.value = cloneDeep(formSettingDefault);
      fetchFormSetting();
    });

    return {
      checkedPolicy,
      birthYear,
      birthMonth,
      birthDay,
      isPolicy,
      formSetting,
      graduatedYear,
      questions,
      applicantForm,
      errors,
      // methods
      postalCodeJp,
      toPolicy,
      backFromPolicy,
      changeCheckPolicy,
      changeGraduatedMode,
      graduatedYearToLabelName,
      graduatedYearToId,
      onSelectAnswer,
      setPreviewData,
      preview,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include validation_effects();

.entry_form {
  font-size: 14px;
}

.entry_form_title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.entry_form_caption {
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 4px solid #f0f0f0;
  letter-spacing: 0;
}

.radio_wrapper {
  margin-bottom: 30px;
  &.is-danger {
    border: none !important;
    > input[type='radio'] + label {
      border: 1px #c31e1e solid;
    }
  }
  > input[type='radio'] {
    display: none;
    & + label {
      display: block;
      position: relative;
      padding: 18px 13px 18px 42px;
      margin-bottom: 5px;
      line-height: 1;
      background-color: #fafafa;
      border: 1px solid #bcbcbc;
      box-sizing: border-box;
      border-radius: 4px;
      transition: all ease 0.3s;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 16px;
        left: 16px;
        width: 18px;
        height: 18px;
        border: 1px solid #bcbcbc;
        border-radius: 9px;
        background-color: #fff;
      }
    }
  }
  > input[type='radio']:checked + label {
    font-weight: bold;
    border: 1px solid #20a8e9;
    color: #20a8e9;
    background-color: #e9f7fd;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 16px;
      left: 16px;
      width: 18px;
      height: 18px;
      border: 1px solid #20a8e9;
      border-radius: 9px;
      background-color: #fff;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 19px;
      left: 19px;
      width: 12px;
      height: 12px;
      border: 1px solid #20a8e9;
      border-radius: 6px;
      background-color: #20a8e9;
    }
  }
}

.input_head {
  margin: 0 auto 3px 0;
  font-weight: bold;
  line-height: 1.3;
  word-break: break-all;
  white-space: pre-wrap;
  margin-bottom: 8px;
}

.required {
  color: #ee3030;
  font-weight: bold;
  font-size: 14px;
}

.email_display {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
  line-height: 1.3;
  word-break: break-all;
  white-space: pre-wrap;
}

.two_input_wrapper {
  margin-bottom: 30px;
  > div > input {
    width: calc(50% - 5px);
    margin-right: 10px;
    height: 48px;
    min-height: 48px;
    max-height: 48px;
    font-size: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
  > p > span {
    display: block;
  }
}

.input {
  width: 100%;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  margin-bottom: 30px;
  font-size: 16px;
  border-color: #c4c4c4;
  border-radius: 4px;
  & + .fa-warning {
    display: block;
    margin-top: -30px;
    margin-bottom: 30px;
  }
}

.select_wrapper {
  position: relative;
  margin-bottom: 30px;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 20px;
    left: 210px;
    width: 10px;
    height: 67px;
    background-image: url('../../../../assets/img/icon_select.svg');
    background-size: 10px auto;
    background-position: 0 0;
    background-repeat: no-repeat;
    pointer-events: none;
  }
  > select {
    appearance: none;
    width: 230px;
    height: 48px;
    min-height: 48px;
    max-height: 48px;
    margin-right: 6px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
  }
}

.select_birth_wrapper {
  margin-bottom: 30px;
  &.is-danger {
    border: none !important;
    > li > select {
      border: 1px #c31e1e solid;
    }
  }
  > li {
    display: inline-block;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 19px;
      left: 77px;
      width: 10px;
      height: 67px;
      background-image: url('../../../../assets/img/icon_select.svg');
      background-size: 10px auto;
      background-position: 0 0;
      background-repeat: no-repeat;
      pointer-events: none;
    }
    > select {
      appearance: none;
      width: 87px;
      height: 48px;
      min-height: 48px;
      max-height: 48px;
      margin-left: 10px;
      padding-left: 15px;
      padding-right: 15px;
      line-height: 1;
      border: 1px solid #c9c9c9;
      border-radius: 4px;
    }
    &:first-child {
      &::before {
        left: 120px;
      }
      > select {
        width: 140px;
        margin-left: 0;
      }
    }
  }
}

.fa-questions {
  display: block;
  margin-top: -30px;
  margin-bottom: 30px;
}

.input_footer {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #f0f0f0;
}

.submit_button {
  width: 100%;
  height: 65px;
  padding: 10px 0;
  margin-bottom: 10px;
  font-weight: bold;
  color: #fff;
  background: #545454;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 38px;
  transition: opacity ease 0.3s;
  cursor: pointer;

  &.is-disabled {
    opacity: 0.3;
    cursor: default;
  }
}

.cancel_button {
  width: 100%;
  height: 65px;
  padding: 10px 0;
  margin-bottom: 10px;
  font-weight: normal;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 38px;
  cursor: pointer;
}
</style>
