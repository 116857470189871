import FormAssignProgress from '@/components/features/autoActions/forms/AssignProgress.vue';
import FormAnnounce from '@/components/features/autoActions/forms/Announce.vue';
import FormResult from '@/components/features/autoActions/forms/Result.vue';
import FormRichMessage from '@/components/features/autoActions/forms/RichMessage.vue';
import FormFlagSurvey from '@/components/features/autoActions/forms/FlagSurvey.vue';
import FormNotice from '@/components/features/autoActions/forms/Notice.vue';

import ConfirmAssignProgress from '@/components/features/autoActions/confirm/AssignProgress.vue';
import ConfirmAnnounce from '@/components/features/autoActions/confirm/Announce.vue';
import ConfirmResult from '@/components/features/autoActions/confirm/Result.vue';
import ConfirmRichMessage from '@/components/features/autoActions/confirm/RichMessage.vue';
import ConfirmFlagSurvey from '@/components/features/autoActions/confirm/FlagSurvey.vue';
import ConfirmNotice from '@/components/features/autoActions/confirm/Notice.vue';

import EditFormNotice from '@/components/features/autoActions/forms/edit/Notice.vue';
import EditConfirmNotice from '@/components/features/autoActions/confirm/edit/Notice.vue';

// アクションの種類
export const ACTION_TYPES = Object.freeze({
  assignProgress: 1,
  announce: 2,
  results: 3,
  richMessage: 4,
  flagSurvey: 5,
  notice: 6,
});

export const ACTION_TYPE_LABELS = Object.freeze({
  [ACTION_TYPES.assignProgress]: '新しく選考を割り当てる',
  [ACTION_TYPES.announce]: '選考の案内を通知',
  [ACTION_TYPES.results]: '選考の結果を通知',
  [ACTION_TYPES.richMessage]: 'リッチメッセージを通知',
  [ACTION_TYPES.flagSurvey]: 'アンケートを通知',
  [ACTION_TYPES.notice]: '連絡事項を通知',
});

export const ACTION_LOG_STATUSES = Object.freeze({
  reserved: 1,
  failed: 2,
  done: 3,
  cancel: 4,
  confirmed: 5,
});

// アクションの状態
export const ACTION_STATUSES = Object.freeze({
  disabled: 0,
  enabled: 1,
});

export const RESULTS_COPY_TAGS = [
  {
    text: '[applicant_name]',
    description: '応募者の姓名',
  },
  {
    text: '[selection_name]',
    description: '選考名',
  },
];

export const NOTICE_COPY_TAGS = [
  {
    text: '[applicant_name]',
    description: '応募者の姓名',
  },
];

export const FORM_COMPONENTS = {
  [ACTION_TYPES.assignProgress]: FormAssignProgress,
  [ACTION_TYPES.announce]: FormAnnounce,
  [ACTION_TYPES.results]: FormResult,
  [ACTION_TYPES.richMessage]: FormRichMessage,
  [ACTION_TYPES.flagSurvey]: FormFlagSurvey,
  [ACTION_TYPES.notice]: FormNotice,
};

export const CONFIRM_COMPONENTS = {
  [ACTION_TYPES.assignProgress]: ConfirmAssignProgress,
  [ACTION_TYPES.announce]: ConfirmAnnounce,
  [ACTION_TYPES.results]: ConfirmResult,
  [ACTION_TYPES.richMessage]: ConfirmRichMessage,
  [ACTION_TYPES.flagSurvey]: ConfirmFlagSurvey,
  [ACTION_TYPES.notice]: ConfirmNotice,
};

export const EDIT_FORM_COMPONENTS = {
  [ACTION_TYPES.results]: FormResult,
  [ACTION_TYPES.notice]: EditFormNotice,
};

export const EDIT_CONFIRM_COMPONENTS = {
  [ACTION_TYPES.results]: ConfirmResult,
  [ACTION_TYPES.notice]: EditConfirmNotice,
};

export const DEFAULT_RESULTS_TEXT = {
  passed: {
    email: {
      subject: '選考結果のお知らせ【[company_name]】',
      content: `こんにちは。
[company_name]の採用担当です。

先日は、当社の[selection_name]にご参加いただき、誠にありがとうございました。
厳正なる選考の結果、[applicant_name]さんには、是非次の選考にお進みいただきたくご連絡いたしました。
後ほど詳細をご案内いたしますので、ご予約をお願いいたします。

[applicant_name]さんのご参加を一同心よりお待ちしております。
引き続きどうぞよろしくお願いいたします。

[company_name]`,
    },
    line: {
      content: `こんにちは。[company_name]の採用担当です。
先日ご参加いただいた[selection_name]の選考結果についてご連絡させていただきました。

先日は、当社の[selection_name]にご参加いただき、誠にありがとうございました。
厳正なる選考の結果、[applicant_name]さんには、是非次の選考にお進みいただきたくご連絡いたしました。
後ほど詳細をご案内いたしますので、ご予約をお願いいたします。

[applicant_name]さんのご参加を一同心よりお待ちしております。
引き続きどうぞよろしくお願いいたします。`,
    },
  },
  offered: {
    email: {
      subject: '選考結果のお知らせ【[company_name]】',
      content: `こんにちは。
[company_name]の採用担当です。

先日は、当社の[selection_name]にご参加いただきまして、誠にありがとうございました。
厳正なる選考の結果、[applicant_name]さんの採用が内定いたしましたので、ご連絡いたします。
内定をお伝えできたことを大変嬉しく思います。

今後の流れに関しましては、後ほど詳細をお送りします。
[applicant_name]さんと働けますこと、弊社一同心より楽しみにしております！
引き続きどうぞよろしくお願いします。

[company_name]`,
    },
    line: {
      content: `こんにちは。[company_name]の採用担当です。
先日ご参加いただいた[selection_name]の選考結果についてご連絡させていただきました。

先日は、当社の[selection_name]にご参加いただきまして、誠にありがとうございました。
厳正なる選考の結果、[applicant_name]さんの採用が内定いたしましたので、ご連絡いたします。
内定をお伝えできたことを大変嬉しく思います。

今後の流れに関しましては、後ほど詳細をお送りします。
[applicant_name]さんと働けますこと、弊社一同心より楽しみにしております！
引き続きどうぞよろしくお願いします。`,
    },
  },
  dropped: {
    email: {
      subject: '選考結果のお知らせ【[company_name]】',
      content: `お世話になっております。
[company_name]の採用担当です。

先日は、当社の[selection_name]にご参加いただき、誠にありがとうございました。

[applicant_name]さんのご意向とこれまでの選考を踏まえて慎重に検討いたしましたが、
誠に残念ながら今回は採用を見合わせて頂くこととなりました。

ご期待に沿えず大変心苦しいですが、何卒ご了承頂きますようお願い申し上げます。

[applicant_name]様の、より一層のご活躍を弊社一同心よりお祈り申し上げます。

[company_name]`,
    },
    line: {
      content: `お世話になっております。[company_name]の採用担当です。
先日ご参加いただいた[selection_name]の選考結果についてご連絡させていただきました。

先日は、当社の[selection_name]にご参加いただき、誠にありがとうございました。
[applicant_name]さんのご意向とこれまでの選考を踏まえて慎重に検討いたしましたが、
誠に残念ながら今回は採用を見合わせて頂くこととなりました。

ご期待に沿えず大変心苦しいですが、何卒ご了承頂きますようお願い申し上げます。

[applicant_name]様の、より一層のご活躍を弊社一同心よりお祈り申し上げます。`,
    },
  },
};
