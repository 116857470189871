<template>
  <div>
    <div class="ar-inputGrowup">
      <dl class="item ar-inputElement">
        <dt>選考名</dt>
        <dd>
          <drop-down
            :placeholder="'選考名'"
            :selected-id="searchFilters.eventId.value"
            :list="getEventIds"
            :is-view="visible.eventId"
            @onOpen="openDropDownVisible('eventId')"
            @onClose="closeAllDropDown"
            @onSelected="onSelectedEventId"
          />
        </dd>
      </dl>
      <dl class="item ar-inputElement">
        <dt>
          参加日
          <span v-if="!searchFilters.eventId.value">
            （先に選考名をお選び下さい）
          </span>
          <span v-if="searchFilters.eventId.value">
            （検索対象：応募者一覧の参加日）
          </span>
        </dt>
        <dd
          :class="{
            dd_deactive:
              !searchFilters.eventId.value || getAttendances.length === 0,
          }"
        >
          <drop-down
            :placeholder="'参加日'"
            :selected-id="searchFilters.attendanceId.value"
            :list="getAttendances"
            :is-view="visible.attendanceId"
            @onOpen="openDropDownVisible('attendanceId')"
            @onClose="closeAllDropDown"
            @onSelected="onSelectedAttendanceId"
          />
        </dd>
      </dl>
      <dl class="item ar-inputElement">
        <dt>ステータス</dt>
        <dd>
          <div
            class="status_wrapper"
            :class="{
              'is-empty':
                searchFilters.progressStatusIds &&
                searchFilters.progressStatusIds.value.length === 0,
            }"
            @click="openDropDownVisible('progressStatusIds')"
          >
            {{
              getProgressStatusIdLabels === ''
                ? 'ステータス'
                : getProgressStatusIdLabels
            }}
          </div>
          <float-context-menu
            class="status_multiselector"
            :is-view="visible.progressStatusIds"
            :list="getProgressStatuses"
            :is-multiple-select="true"
            @onClose="closeAllDropDown"
            @onSelected="onSelectedProgressStatus"
          />
        </dd>
      </dl>
      <dl class="item ar-inputElement">
        <dt>
          参加確認
          <span v-if="!searchFilters.eventId.value">
            （先に選考名をお選び下さい）
          </span>
        </dt>
        <dd
          :class="{
            dd_deactive:
              !searchFilters.eventId.value || getAttendances.length === 0,
          }"
        >
          <drop-down
            :placeholder="'参加確認'"
            :selected-id="searchFilters.reminderCheck.value"
            :list="getReminderChecks"
            :is-view="visible.reminderCheck"
            @onOpen="openDropDownVisible('reminderCheck')"
            @onClose="closeAllDropDown"
            @onSelected="onSelectedFilterItem('reminderCheck', $event)"
          />
        </dd>
      </dl>
      <dl class="item ar-inputElement">
        <dt>登録経路</dt>
        <dd>
          <drop-down
            :placeholder="'登録経路'"
            :selected-id="searchFilters.channelId.value"
            :list="getChannels"
            :is-view="visible.channelId"
            @onOpen="openDropDownVisible('channelId')"
            @onClose="closeAllDropDown"
            @onSelected="onSelectedFilterItem('channelId', $event)"
          />
        </dd>
      </dl>
      <dl class="item ar-inputElement">
        <dt>連絡方法</dt>
        <dd>
          <drop-down
            :placeholder="'連絡方法'"
            :selected-id="searchFilters.contactTypeId.value"
            :list="getContactTypeIds"
            :is-view="visible.contactTypeId"
            @onOpen="openDropDownVisible('contactTypeId')"
            @onClose="closeAllDropDown"
            @onSelected="onSelectedFilterItem('contactTypeId', $event)"
          />
        </dd>
      </dl>
    </div>
    <div class="ar-inputGrowup">
      <dl class="item ar-inputElement">
        <dt>応募者の名前</dt>
        <dd>
          <input
            class="search_input"
            type="text"
            :value="searchFilters.name.value"
            placeholder="名前"
            @input="setSearchFilters('name', $event.target.value)"
          />
        </dd>
      </dl>
      <dl class="item ar-inputElement">
        <dt>フリガナ</dt>
        <dd>
          <input
            class="search_input"
            type="text"
            :value="searchFilters.nameKana.value"
            placeholder="フリガナ"
            @input="setSearchFilters('nameKana', $event.target.value)"
          />
        </dd>
      </dl>
      <dl class="item ar-inputElement">
        <dt>性別</dt>
        <dd class="category_dd">
          <drop-down
            :placeholder="'性別'"
            :selected-id="searchFilters.genderId.value"
            :list="getGenderIds"
            :is-view="visible.genderId"
            @onOpen="openDropDownVisible('genderId')"
            @onClose="closeAllDropDown"
            @onSelected="onSelectedFilterItem('genderId', $event)"
          />
        </dd>
      </dl>
      <dl class="item ar-inputElement">
        <dt>メール</dt>
        <dd>
          <input
            class="search_input"
            type="text"
            :value="searchFilters.email.value"
            placeholder="メール"
            @input="setSearchFilters('email', $event.target.value)"
          />
        </dd>
      </dl>
      <dl class="item ar-inputElement">
        <dt>電話番号</dt>
        <dd>
          <input
            class="search_input"
            type="text"
            :value="searchFilters.tel.value"
            placeholder="電話番号"
            @input="setSearchFilters('tel', $event.target.value)"
          />
        </dd>
      </dl>
      <dl class="item ar-inputElement">
        <dt>住所</dt>
        <dd>
          <input
            class="search_input"
            type="text"
            :value="searchFilters.address.value"
            placeholder="住所"
            @input="setSearchFilters('address', $event.target.value)"
          />
        </dd>
      </dl>
    </div>
    <div class="ar-inputGrowup">
      <dl class="item ar-inputElement">
        <dt>学校名</dt>
        <dd>
          <input
            class="search_input"
            type="text"
            :value="searchFilters.school.value"
            placeholder="学校名"
            @input="setSearchFilters('school', $event.target.value)"
          />
        </dd>
      </dl>
      <dl class="item ar-inputElement">
        <dt>学部</dt>
        <dd>
          <input
            class="search_input"
            type="text"
            :value="searchFilters.faculty.value"
            placeholder="学部"
            @input="setSearchFilters('faculty', $event.target.value)"
          />
        </dd>
      </dl>
      <dl class="item ar-inputElement">
        <dt>学科</dt>
        <dd>
          <input
            class="search_input"
            type="text"
            :value="searchFilters.department.value"
            placeholder="学科"
            @input="setSearchFilters('department', $event.target.value)"
          />
        </dd>
      </dl>
      <dl class="item ar-inputElement">
        <dt>文理</dt>
        <dd class="category_dd">
          <drop-down
            :placeholder="'文理'"
            :selected-id="searchFilters.departmentCategoryId.value"
            :list="getDepartmentCategories"
            :is-view="visible.departmentCategories"
            @onOpen="openDropDownVisible('departmentCategories')"
            @onClose="closeAllDropDown"
            @onSelected="onSelectedFilterItem('departmentCategoryId', $event)"
          />
        </dd>
      </dl>
    </div>
    <div class="ar-inputGrowup">
      <dl class="item ar-inputElement">
        <dt>登録日</dt>
        <dd>
          <el-date-picker
            id="el-input__inner"
            type="date"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            placeholder="登録日"
            name="createdAt"
            :model-value="searchFilters.createdAt.value"
            @update:model-value="setSearchFilters('createdAt', $event)"
            @focus="visible.date = true"
            @blur="visible.date = false"
          />
        </dd>
      </dl>
      <dl v-if="displayActivatedAt" class="item ar-inputElement">
        <dt>エントリー日</dt>
        <dd>
          <el-date-picker
            id="el-input__inner--entry"
            type="date"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            placeholder="エントリー日"
            name="activatedAt"
            :model-value="searchFilters.activatedAt.value"
            @update:model-value="setSearchFilters('activatedAt', $event)"
            @focus="visible.date = true"
            @blur="visible.date = false"
          />
        </dd>
      </dl>
      <dl class="item ar-inputElement">
        <dt>ブロック状況</dt>
        <dd class="">
          <drop-down
            :placeholder="'ブロック状況'"
            :selected-id="searchFilters.lineBlock.value"
            :list="getLineBlock"
            :is-view="visible.lineBlock"
            @onOpen="openDropDownVisible('lineBlock')"
            @onClose="closeAllDropDown"
            @onSelected="onSelectedFilterItem('lineBlock', $event)"
          />
        </dd>
      </dl>
      <dl class="item ar-inputElement">
        <dt>担当者</dt>
        <dd>
          <drop-down
            :placeholder="'担当者'"
            :selected-id="searchFilters.staffId.value"
            :list="getStaffs"
            :is-view="visible.staffId"
            @onOpen="openDropDownVisible('staffId')"
            @onClose="closeAllDropDown"
            @onSelected="onSelectedFilterItem('staffId', $event)"
          />
        </dd>
      </dl>
      <dl v-if="staff.admin === 2" class="item ar-inputElement">
        <dt>応募者ID</dt>
        <dd>
          <input
            class="search_input"
            type="text"
            :value="searchFilters.appId.value"
            placeholder="応募者ID"
            @input="setSearchFilters('appId', $event.target.value)"
          />
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  nextTick,
} from 'vue';
import { useStore } from 'vuex';

import {
  channels,
  genders,
  departmentCategories,
  lineBlocks,
  contactTypes,
} from '@/defines/applicant';
import ProgressStatuses from '@/components/features/searchApplicants/defines/progress-statuses';
import eventsService from '@/services/events';
import DropDown from '@/components/ui/menus/components/DropDown';
import FloatContextMenu from '@/components/ui/menus/components/FloatContextMenu';

export default defineComponent({
  name: 'SearchApplicantsFilters',
  components: { DropDown, FloatContextMenu },
  props: {
    searchFilters: {
      type: Object,
      default: () => ({ value: [] }),
    },
    events: {
      type: Array,
      default: () => [],
    },
    staffs: {
      type: Array,
      default: () => [],
    },
    setSearchFilters: {
      type: Function,
      default: () => {},
    },
    setProgressStatusIds: {
      type: Function,
      default: () => {},
    },
    displayActivatedAt: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const store = useStore();
    const visible = reactive({
      progressStatusIds: false,
      departmentCategories: false,
      genderId: false,
      lineBlock: false,
      channelId: false,
      eventId: false,
      staffId: false,
      attendanceId: false,
      reminderCheck: false,
      date: false,
      contactTypeId: false,
    });
    const selectedGraduatedId = store.getters['graduateds/selectedGraduatedId'];
    const progressStatuses = ProgressStatuses.map(x => ({ ...x }));
    const attendances = ref([]);

    // computed
    const staff = computed(() => store.getters['staff/staff']);
    const getGenderIds = computed(() => {
      return genders.map(gid => {
        const category = genders.find(item => item.id === gid.id);
        return {
          id: gid.id,
          name: category.description,
          selected: gid.id === props.searchFilters.genderId.value,
        };
      });
    });
    const getLineBlock = computed(() => {
      return lineBlocks.map(blockObj => {
        const category = lineBlocks.find(item => item.id === blockObj.id);
        return {
          id: blockObj.id,
          name: category.description,
          selected: blockObj.id === props.searchFilters.lineBlock.value,
        };
      });
    });
    const getDepartmentCategories = computed(() => {
      return departmentCategories.map(dc => {
        const category = departmentCategories.find(item => item.id === dc.id);
        return {
          id: dc.id,
          name: category.description,
          selected: dc.id === props.searchFilters.departmentCategoryId.value,
        };
      });
    });
    const getChannels = computed(() => {
      return Object.keys(channels).map(key => {
        const intKey = parseInt(key, 10);
        const list = {
          id: intKey,
          name: channels[intKey],
          selected: intKey === props.searchFilters.channelId.value,
        };
        return list;
      });
    });
    const getProgressStatuses = computed(() => {
      return progressStatuses.map(status => {
        const list = Object.assign({}, status, {
          selected:
            props.searchFilters.progressStatusIds &&
            props.searchFilters.progressStatusIds.value.includes(status.id),
        });
        return list;
      });
    });
    const getEventIds = computed(() => {
      return props.events.map(event => {
        const list = Object.assign({}, event, {
          selected: event.id === props.searchFilters.eventId.value,
          name: event.title,
        });
        return list;
      });
    });
    const getStaffs = computed(() => {
      return props.staffs.map(staff => {
        const list = Object.assign({}, staff, {
          selected: staff.id === props.searchFilters.staffId.value,
          name: staff.lastname + staff.firstname,
        });
        return list;
      });
    });
    const getAttendances = computed(() => {
      if (!attendances.value) return [];
      return attendances.value.map((date, index) => ({
        selected: index === props.searchFilters.attendanceId.value,
        name: date,
        title: date,
        id: index,
      }));
    });
    const getReminderChecks = computed(() => {
      // 参加日が存在しない場合は選択肢は0
      if (
        !props.searchFilters.eventId.value ||
        getAttendances.value.length === 0
      )
        return [];
      return [
        {
          id: 1,
          name: 'あり',
          selected: props.searchFilters.reminderCheck.value === 1,
        },
        {
          id: 0,
          name: 'なし',
          selected: props.searchFilters.reminderCheck.value === 0,
        },
      ];
    });
    const getProgressStatusIdLabels = computed(() => {
      if (!props.searchFilters.progressStatusIds) return '';
      return props.searchFilters.progressStatusIds.value
        .map(
          progressStatusId =>
            progressStatuses.find(
              progressStatus => progressStatus.id === progressStatusId,
            ).name,
        )
        .join(', ');
    });
    const getContactTypeIds = computed(() => {
      return Object.keys(contactTypes).map(key => {
        const intKey = parseInt(key, 10);
        const list = {
          id: intKey,
          name: contactTypes[intKey],
          selected: intKey === props.searchFilters.contactTypeId.value,
        };
        return list;
      });
    });

    // methods
    const openDropDownVisible = key => {
      closeAllDropDown();
      visible[key] = true;
    };
    const closeAllDropDown = () => {
      Object.keys(visible).forEach(key => {
        visible[key] = false;
      });
    };
    const onSelectedFilterItem = (key, payload) => {
      props.setSearchFilters(key, payload.item.id);
      // nextTickが効かない
      setTimeout(() => closeAllDropDown(), 100);
    };
    const onSelectedProgressStatus = payload => {
      props.setProgressStatusIds(payload.item.id);
      // nextTickが効かない
      setTimeout(() => {
        closeAllDropDown();
        // 複数選択なので閉じない
        visible.progressStatusIds = true;
      }, 100);
    };
    const onSelectedEventId = async payload => {
      props.setSearchFilters('eventId', payload.item.id);
      await setAttendances();
      props.setSearchFilters('attendanceId', null);
      props.setSearchFilters('attendanceDate', null);
      props.setSearchFilters('reminderCheck', null);
      nextTick(() => closeAllDropDown());
    };
    const onSelectedAttendanceId = payload => {
      props.setSearchFilters('attendanceId', payload.item.id);
      props.setSearchFilters('attendanceDate', payload.item.name);
      // nextTickが効かない
      setTimeout(() => closeAllDropDown(), 100);
    };
    const setAttendances = async () => {
      if (props.searchFilters.eventId.value !== undefined) {
        const res = await eventsService.fetchAttendance({
          event_id: props.searchFilters.eventId.value,
          graduated_id: selectedGraduatedId,
        });
        attendances.value = cloneDeep(res.event_dates);
      } else {
        attendances.value = [];
      }
    };

    // lifecycles
    onMounted(async () => {
      await setAttendances();
    });

    return {
      visible,
      staff,
      getGenderIds,
      getLineBlock,
      getDepartmentCategories,
      getChannels,
      getProgressStatuses,
      getEventIds,
      getStaffs,
      getReminderChecks,
      getProgressStatusIdLabels,
      getContactTypeIds,
      openDropDownVisible,
      closeAllDropDown,
      onSelectedFilterItem,
      onSelectedProgressStatus,
      onSelectedEventId,
      onSelectedAttendanceId,
      getAttendances,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/datepicker.scss';

.dd_deactive :deep(p.button_content) {
  background-color: #eee;
}

.ar-inputGrowup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  + .ar-inputGrowup {
    margin-top: 20px;
  }
  > .item {
    width: 48.5%;
  }
}

.ar-inputElement {
  margin-bottom: 20px;
  > dt {
    font-weight: bold;
    margin-bottom: 10px;
  }
  > dd > input {
    width: 100%;
  }
  > dd :deep(.el-date-editor.el-input) {
    width: 100%;
  }
}

.search_input {
  font-size: 14px;
  &::placeholder {
    color: #aaa;
  }
}
:deep(.el-input__inner) {
  font-size: 14px;
  &::placeholder {
    color: #aaa;
  }
}
.status_wrapper {
  height: 36px;
  padding: 10px 25px 10px 10px;
  line-height: 1;
  border: solid 1px #adadad;
  border-radius: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  background-image: url('../../../../assets/img/arrow_down_blue.svg');
  background-size: 10px auto;
  background-position: right 10px center;
  background-repeat: no-repeat;

  &.is-empty {
    color: #aaa;
  }
}

:deep(.button_content) {
  min-height: 14.4px;
}

@media (max-width: ($media_query_sp)) {
  .ar-inputGrowup {
    display: block;
    > .item {
      width: 100%;
    }
  }
}
</style>
