<template>
  <div v-if="$store.getters['page/isPageLoaded']" class="selections-search">
    <div v-if="isNotAnnounce === true" class="sec-search-condition">
      <p v-if="isAnnounceModal" class="list-applicant-title -mb20">
        {{ announceTitle }}
      </p>
      <div class="btn-area">
        <div class="btn-menu">
          <button-base
            class="btn-selections-search"
            button-text="絞り込み検索"
            icon-file-name="search_white"
            @click="isVisibleSearchForm = !isVisibleDisplayItem"
          />
          <float-selections-form
            :is-view="isVisibleSearchForm"
            :init-graduated-id="initGraduatedId"
            @onClose="isVisibleSearchForm = false"
            @onSearch="onSearch"
            @onReset="onSearchReset"
          />
        </div>
        <div class="btn-menu">
          <button-base
            class="btn-selections-search"
            button-text="表示する選考の種類"
            icon-file-name="icon_eye_white"
            @click="isVisibleDisplayItem = !isVisibleDisplayItem"
          />
          <balloon-menu
            :is-view="isVisibleDisplayItem"
            @close="isVisibleDisplayItem = false"
          >
            <template #content>
              <div class="balloon_content">
                <div class="balloon_btn" @click="isVisibleProgressTypes = true">
                  <span class="balloon_btn_title">{{ progressType }}</span>
                </div>
                <float-context-menu-for-selection
                  :is-view="isVisibleProgressTypes"
                  :list="progressTypes"
                  @onClose="isVisibleProgressTypes = false"
                  @onSelected="onSelectedProgressType"
                />
                <div
                  class="balloon_btn"
                  @click="isVisibleSelectionTypes = true"
                >
                  <span class="balloon_btn_title">{{ selectionType }}</span>
                </div>
                <float-context-menu-for-selection
                  :is-view="isVisibleSelectionTypes"
                  :list="selectionTypes"
                  @onClose="isVisibleSelectionTypes = false"
                  @onSelected="onSelectedSelectionTypes"
                />
                <div class="balloon_btn" @click="isVisibleOrderTypes = true">
                  <span class="balloon_btn_title">{{ orderType }}</span>
                </div>
                <float-context-menu-for-selection
                  :is-view="isVisibleOrderTypes"
                  :list="orderTypes"
                  @onClose="isVisibleOrderTypes = false"
                  @onSelected="onSelectedOrderTypes"
                />
                <div class="balloon_btn" @click="isVisiblePagerLoad = true">
                  <span class="balloon_btn_title">
                    表示する選考の件数：{{ loadSetting }}件
                  </span>
                </div>
                <float-context-menu-for-selection
                  :is-view="isVisiblePagerLoad"
                  :list="loadSelection"
                  @onClose="isVisiblePagerLoad = false"
                  @onSelected="onSelectedPageLoad"
                />
              </div>
            </template>
          </balloon-menu>
        </div>
      </div>

      <div class="area-pager">
        <pager
          :page="page"
          :count="count"
          :page-count="pageCount"
          :load="loadSetting"
          @move="movePager"
        />
      </div>
    </div>

    <div v-if="isNotAnnounce === true" class="sec-selection-list">
      <table class="common_table" cellpadding="0" cellspacing="1">
        <thead class="common_thead">
          <tr class="common_thead_row align-left">
            <th
              v-for="(col, iCol) in filteredHeader"
              :key="iCol"
              class="common_thead_item"
            >
              <span>{{ col.name_jp }}</span>
            </th>
          </tr>
        </thead>
        <tbody class="common_tbody">
          <tr
            v-for="(selection, iSelection) in selections"
            :key="selection.id"
            class="common_tbody_row align-left"
            :class="
              disabledIds.findIndex(v => selections[iSelection].id === v) !== -1
                ? ['is-disabled']
                : []
            "
            :style="styles(iSelection)"
          >
            <td
              v-for="(col, iCol) in filteredHeader"
              :key="iCol"
              class="common_tbody_item"
              @click="selectCol(selection, col.relation)"
            >
              <span
                v-for="(relation, iRelation) in col.relation"
                :key="iRelation"
              >
                <span
                  v-if="relation === 'reserve'"
                  class="selection_reserve"
                  :class="{ active: selection.reserve }"
                  @click="selectionReserveToggle(selection)"
                >
                  {{ selection.reserve ? '表示' : 'OFF' }}
                </span>
                <span
                  v-else-if="
                    relation === 'notAnnouncedCount' ||
                    relation === 'notResultedCount'
                  "
                  class="not_announce_result_count"
                  :class="{
                    count_red: $utils.getValueFromNestedObject(
                      selection,
                      relation,
                    ),
                  }"
                >
                  {{ $utils.getValueFromNestedObject(selection, relation) }}
                </span>
                <span v-else-if="relation === 'reminder_check'">
                  {{ selection.reminder_check ? 'ON' : 'OFF' }}
                </span>
                <span v-else-if="relation !== 'role.description'">
                  {{ $utils.getValueFromNestedObject(selection, relation) }}
                </span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="isNotAnnounce === true" class="area-search-after">
      <pager
        :page="page"
        :count="count"
        :page-count="pageCount"
        :load="loadSetting"
        @move="movePager"
      />
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  getSelectionListColumns,
  getSelectionListNotAnnouncedColumns,
  getSelectionListNotResultedColumns,
  getSelectionFlowColumns,
} from '@/components/features/selectionsSearch/defines/selection-list-columns';
import selectionService from '@/services/selection';
import selectionsService from '@/services/selections';
import BalloonMenu from '@/components/ui/menus/components/BalloonMenu';
import Pager from '@/components/ui/pager/components/Pager';
import FloatSelectionsForm from '@/components/features/selectionsSearch/components/FloatSelectionsForm';
import FloatContextMenuForSelection from '@/components/features/selectionsSearch/components/FloatContextMenuForSelection';

export default defineComponent({
  name: 'SelectionsSearch',
  components: {
    BalloonMenu,
    Pager,
    FloatContextMenuForSelection,
    FloatSelectionsForm,
  },
  props: {
    tableType: {
      type: String,
      default: '',
    },
    announceType: {
      type: String,
      default: '',
    },
    isAnnounceModal: {
      type: Boolean,
      default: false,
    },
    initGraduatedId: {
      type: Number,
      default: null,
    },
    announceTitle: {
      type: String,
      default: '',
    },
    // 応募者の有無に関わらず、選択したい場合
    isJustSelect: {
      type: Boolean,
      default: false,
    },
    // isJustSelectと併用してください。選択を無効化したい選考IDを指定します。
    disabledIds: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onSelected'],
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const loadSelection = ref([
      { id: 20, name: '20', selected: true },
      { id: 50, name: '50', selected: false },
      { id: 100, name: '100', selected: false },
    ]);
    const progressTypes = ref([
      { id: 'live', name: '進行中の選考', selected: false },
      { id: 'finished', name: '終了済みの選考', selected: false },
      { id: null, name: '全ての選考', selected: true },
    ]);
    const selectionTypes = ref([
      { id: null, name: '全ての選考', selected: true },
      { id: 'one', name: '単数人の選考', selected: false },
      { id: 'multi', name: '複数人の選考', selected: false },
    ]);
    const orderTypes = ref([
      { id: 'recent', name: '開催日が近い順', selected: true },
      { id: 'new', name: '作成日が新しい順', selected: false },
      { id: 'old', name: '作成日が古い順', selected: false },
    ]);
    const header = ref([]);
    const selections = ref([]);
    const searchBody = reactive({ data: {} });
    const pageCount = ref(0);
    const count = ref(0);
    const page = ref(1);
    const isVisiblePagerLoad = ref(false);
    const isVisibleProgressTypes = ref(false);
    const isVisibleSelectionTypes = ref(false);
    const isVisibleOrderTypes = ref(false);
    const isVisibleSearchForm = ref(false);
    const isVisibleDisplayItem = ref(false);

    // computed
    const loadSetting = computed(() => {
      const targets = loadSelection.value.filter(v => v.selected === true);
      return targets && targets.length > 0
        ? targets[0].id
        : loadSelection.value[0].id;
    });
    const progressType = computed(() => {
      const targets = progressTypes.value.filter(v => v.selected === true);
      return targets && targets.length > 0
        ? targets[0].name
        : progressTypes.value[0].name;
    });
    const selectionType = computed(() => {
      const targets = selectionTypes.value.filter(v => v.selected === true);
      return targets && targets.length > 0
        ? targets[0].name
        : selectionTypes.value[0].name;
    });
    const orderType = computed(() => {
      const targets = orderTypes.value.filter(v => v.selected === true);
      return targets && targets.length > 0
        ? targets[0].name
        : orderTypes.value[0].name;
    });
    const selectedGraduatedId = computed(() => {
      return store.getters['graduateds/selectedGraduatedId'];
    });
    const setGraduatedId = computed(() => {
      return props.initGraduatedId !== null
        ? props.initGraduatedId
        : selectedGraduatedId.value;
    });
    const filteredHeader = computed(() => {
      return header.value.filter(col => col.name !== 'ActialPerLimit');
    });
    const isNotAnnounce = computed(() => {
      return props.announceType !== 'select-applicant';
    });

    // watch
    watch(
      () => selectedGraduatedId.value,
      async () => await fetchAll(),
    );

    // methods
    const fetchAll = async () => {
      await fetchSelectionsSearch();
      if (props.tableType === 'notAnnounced') {
        await fetchNotAnnouncedSelections();
      } else if (props.tableType === 'notResulted') {
        await fetchNotResultedSelections();
      }
    };
    const fetchSelectionsSearch = async () => {
      const res = await selectionService.fetchSelectionSearch(
        setGraduatedId.value,
        page.value,
        loadSetting.value,
        Object.assign({}, searchBody.data, {
          type: selectionTypes.value.filter(v => v.selected === true)[0].id,
          progress: progressTypes.value.filter(v => v.selected === true)[0].id,
          order: orderTypes.value.filter(v => v.selected === true)[0].id,
        }),
      );
      selections.value = res.selections;
      page.value = res.page;
      pageCount.value = res.page_count;
      count.value = res.count;
    };
    const fetchNotAnnouncedSelections = async () => {
      const selectionIds = selections.value.map(x => x.id);
      selectionIds.sort();
      const res = await selectionService.fetchSelectionNotAnnounced({
        selection_ids: selectionIds,
      });
      selections.value = selections.value.map(selection => {
        if (
          res.not_announced_selections.map(x => x.id).includes(selection.id)
        ) {
          selection.notAnnouncedCount = res.not_announced_selections.find(
            x => x.id === selection.id,
          ).progress_ids.length;
        } else {
          selection.notAnnouncedCount = 0;
        }
        return selection;
      });
    };
    const fetchNotResultedSelections = async () => {
      const selectionIds = selections.value.map(x => x.id);
      selectionIds.sort();
      const res = await selectionService.fetchSelectionNotResulted({
        selection_ids: selectionIds,
      });
      selections.value = selections.value.map(selection => {
        if (res.not_resulted_selections.map(x => x.id).includes(selection.id)) {
          selection.notResultedCount = res.not_resulted_selections.find(
            x => x.id === selection.id,
          ).progress_ids.length;
        } else {
          selection.notResultedCount = 0;
        }
        return selection;
      });
    };
    const selectCol = async (selection, arrRelation) => {
      if (arrRelation[0] === 'reserve') return;
      let targetSelection = selection;
      if (props.isJustSelect === true) {
        // isJustSelect:trueの場合は選考データ取得しない
        if (props.disabledIds.findIndex(v => selection.id === v) !== -1) {
          // disabledIdsが指定されていた場合は何もしない
          return;
        }
      } else {
        // isJustSelect:falseの場合のみ選考データ取得
        const res = await selectionsService.fetchSelection(selection.id);
        targetSelection = res.selection;
      }
      if (router.currentRoute.value.name === 'SelectionsOverview') {
        // 選考一覧画面のみ選考詳細画面へ飛ぶ
        router.push(`/selections/${targetSelection.id}`);
      } else {
        context.emit('onSelected', targetSelection);
      }
    };
    const selectionReserveToggle = async selection => {
      const res = await selectionsService.selectionReserveToggle(selection.id, {
        reserve: !selection.reserve,
      });
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success === true) await fetchAll();
    };
    const onSearch = async tmpSearchBody => {
      searchBody.data = tmpSearchBody;
      await fetchAll();
      isVisibleSearchForm.value = false;
    };
    const onSearchReset = async () => {
      searchBody.data = {};
      await fetchAll();
      isVisibleSearchForm.value = false;
    };
    const movePager = async tmpPage => {
      if (tmpPage.next) page.value = tmpPage.next;
      await fetchAll();
    };
    const onSelectedPageLoad = async ev => {
      loadSelection.value = loadSelection.value.map(load => {
        return Object.assign({}, load, {
          selected: load.id === ev.item.id,
        });
      });
      isVisiblePagerLoad.value = false;
      await fetchAll();
    };
    const onSelectedProgressType = async ev => {
      progressTypes.value = progressTypes.value.map(load => {
        return Object.assign({}, load, {
          selected: load.id === ev.item.id,
        });
      });
      isVisibleProgressTypes.value = false;
      await fetchAll();
    };
    const onSelectedSelectionTypes = async ev => {
      selectionTypes.value = selectionTypes.value.map(load => {
        return Object.assign({}, load, {
          selected: load.id === ev.item.id,
        });
      });
      isVisibleSelectionTypes.value = false;
      await fetchAll();
    };
    const onSelectedOrderTypes = async ev => {
      orderTypes.value = orderTypes.value.map(load => {
        return Object.assign({}, load, {
          selected: load.id === ev.item.id,
        });
      });
      isVisibleOrderTypes.value = false;
      await fetchAll();
    };
    const styles = i => {
      const style = {};
      const opacity = 0.4;
      if (
        props.disabledIds.findIndex(v => selections.value[i].id === v) !== -1
      ) {
        style.opacity = opacity;
        style.cursor = 'auto';
      }
      return style;
    };

    // lifecycle
    onBeforeMount(async () => {
      if (props.tableType === 'notAnnounced') {
        header.value = getSelectionListNotAnnouncedColumns();
      } else if (props.tableType === 'notResulted') {
        header.value = getSelectionListNotResultedColumns();
      } else if (props.tableType === 'selectionFlow') {
        header.value = getSelectionFlowColumns();
      } else {
        header.value = getSelectionListColumns();
      }
      await fetchAll();
      await store.dispatch('page/SET_LOADED');
    });

    return {
      fetchAll,
      loadSelection,
      progressTypes,
      selectionTypes,
      orderTypes,
      header,
      selections,
      pageCount,
      count,
      page,
      isVisiblePagerLoad,
      isVisibleProgressTypes,
      isVisibleSelectionTypes,
      isVisibleOrderTypes,
      isVisibleSearchForm,
      isVisibleDisplayItem,
      loadSetting,
      progressType,
      selectionType,
      orderType,
      selectedGraduatedId,
      setGraduatedId,
      filteredHeader,
      isNotAnnounce,
      selectCol,
      selectionReserveToggle,
      onSearch,
      onSearchReset,
      movePager,
      onSelectedPageLoad,
      onSelectedProgressType,
      onSelectedSelectionTypes,
      onSelectedOrderTypes,
      styles,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/table-normal.scss';
@include common_table_style(
  150px,
  100px,
  100px,
  100px,
  100%,
  $col_2_ta: center
);

.selections-search {
  background: #fff;
}
.balloon_btn {
  display: flex;
  width: 19rem;
  height: 3rem;
  position: relative;
  padding: 0 1rem 0.3rem 1rem;
  margin-bottom: 0.5rem;
  border: 1px solid #9d9d9d;
  border-radius: 0.3rem;
  .balloon_btn_title {
    display: flex;
    font-size: 1.1rem;
    padding: 0 1.4rem 0 0.1rem;
    align-items: center;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 11px;
      width: 6px;
      height: 6px;
      margin-top: -6px;
      border: 0px;
      border-bottom: solid 2px #178fd0;
      border-right: solid 2px #178fd0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.balloon_btn:last-child {
  margin-bottom: 0;
}
.applicant_column_block {
  margin-right: 20px;
}

.search_count_block {
  margin-right: 20px;
  .btn {
    font-size: 1.2rem;
    padding-right: 2.4rem;
    display: flex;
    align-items: center;
    &::after {
      content: '';
      width: 6px;
      height: 6px;
      border: 0px;
      border-bottom: solid 2px #178fd0;
      border-right: solid 2px #178fd0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -4px;
    }
  }
}

.table-scrollable {
  text-align: left;
  thead {
    tr {
      th {
        table-layout: fixed;
        height: 3.6rem;
        padding-left: 1rem;
      }
    }
  }
  tbody {
    overflow-y: auto;
    height: 400px;
    tr {
      td {
        table-layout: fixed;
        height: 3.6rem;
        padding-left: 1rem;
      }
    }
  }
}

.sec-search-condition {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  background: #fff;
}
.btn-area {
  display: flex;
  // padding: 1rem;
}
.btn-menu {
  .balloon_content {
    padding: 10px;
  }
  .balloon_btn {
    cursor: pointer;
  }
  .float-box {
    position: relative;
    margin-bottom: 5px;
  }
  .basic-box-shadow {
    box-shadow: none;
  }
  .btn-selections-search {
    padding: 10px 55px 10px 15px;
    background-image: url('../../../../assets/img/rect_arrow_down_white.svg');
    background-position: right 10px center;
    background-size: 10px;
    background-repeat: no-repeat;
  }
}
.btn-menu + .btn-menu {
  margin-left: 10px;
}
.selection_reserve {
  padding: 6px 14px;
  border: 1px solid #ccc;
  border-radius: 16px;
  color: #ccc;
  font-size: 1.2rem;
}
.active {
  padding: 6px 16px;
  border: 1px solid #16b2d9;
  color: #fff;
  font-weight: bold;
  background-color: #16b2d9;
}
.not_announce_result_count {
  font-weight: bold;
  &.count_red {
    color: #e83d86;
  }
}

@media (max-width: ($media_query_sp)) {
  .selections-search {
    background: #fff;
  }
  .sec-search-condition {
    display: block;
    > .area-pager {
      display: none;
    }
  }
  .btn-menu {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    .btn-selections-search {
      width: 100%;
      text-align: left;
    }
  }
  .btn-menu + .btn-menu {
    margin-left: 0;
  }
  .btn-area {
    display: block;
    padding: 0;
  }
}

.list-applicant-title {
  width: 100%;
  padding: 10px;
  line-height: 1.4;
  color: #1899d6;
  font-weight: bold;
  background-color: #e9f3f8;
}
.section-title {
  margin: 0rem 0 2rem;
}
.sec-selection-list {
  overflow: auto;
  .common_table {
    .align-left {
      text-align: left;
    }
    .common_tbody_row {
      height: 4.4rem;
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .sec-selection-list {
    margin-bottom: 20px;
  }
}
.area-search-after {
  margin-top: 20px;
}
.area-pager {
  display: flex;
  margin-left: auto;
  .pager {
  }
}
@media (max-width: ($media_query_sp)) {
  .area-pager {
    margin-left: 0;
  }
}
</style>
