<script setup>
import { ref, onMounted } from 'vue';
import { useField } from 'vee-validate';
import FormEmail from '@/components/ui/forms/FormEmail.vue';
import FormLine from '@/components/ui/forms/FormLine.vue';
import ProgressStatusLabel from '@/components/features/progress/ProgressStatusLabel.vue';
import { RESULTS_COPY_TAGS, DEFAULT_RESULTS_TEXT } from '@/defines/autoActions';

import {
  FINISHED_PROGRESS_STATUSES,
  FINISHED_PROGRESS_STATUSES_LABEL,
} from '@/defines/progresses';

const formValues = ref(
  ['passed', 'offered', 'dropped'].map(key => {
    const {
      value: emailSubject,
      errorMessage: emailSubjectErrorMessage,
      handleChange: handleChangeEmailSubject,
    } = useField(`results.${key}.email.subject`);
    const {
      value: emailContent,
      errorMessage: emailContentErrorMessage,
      handleChange: handleChangeEmailContent,
    } = useField(`results.${key}.email.content`);
    const {
      value: lineContent,
      errorMessage: lineContentErrorMessage,
      handleChange: handleChangeLineContent,
    } = useField(`results.${key}.line.content`);
    return {
      key,
      emailSubject,
      emailSubjectErrorMessage,
      handleChangeEmailSubject,
      emailContent,
      emailContentErrorMessage,
      handleChangeEmailContent,
      lineContent,
      lineContentErrorMessage,
      handleChangeLineContent,
    };
  }),
);
onMounted(() => {
  formValues.value.map(val => {
    val.emailSubject = DEFAULT_RESULTS_TEXT[val.key].email.subject;
    val.emailContent = DEFAULT_RESULTS_TEXT[val.key].email.content;
    val.lineContent = DEFAULT_RESULTS_TEXT[val.key].line.content;
  });
});
</script>

<template>
  <div class="action-inner">
    <div class="action-title">
      <h3 class="title">選考の結果を通知</h3>
      <div class="desc">
        選考でステータスが「通過・内定・不採用」になった人に通知を送信します。
        <br />
        それぞれのステータスの人に送るメール・LINEの文面を入力してください。
      </div>
    </div>
    <div class="action-contents auto-action">
      <div class="action-forms">
        <div v-for="value in formValues" :key="value.key" class="action-form">
          <div class="action-form-title" :class="value.key">
            <ProgressStatusLabel
              :label-name="
                FINISHED_PROGRESS_STATUSES_LABEL[
                  FINISHED_PROGRESS_STATUSES[value.key]
                ]
              "
            />
            の人に送信するメール・LINE
          </div>
          <div class="action-form-contents">
            <div class="form-wrapper">
              <form-email
                :subject="{
                  value: value.emailSubject,
                  errorMessage: value.emailSubjectErrorMessage,
                }"
                :content="{
                  value: value.emailContent,
                  errorMessage: value.emailContentErrorMessage,
                }"
                :copy-tags="RESULTS_COPY_TAGS"
                @blur-subject="value.handleChangeEmailSubject"
                @blur-content="value.handleChangeEmailContent"
              />
            </div>
            <div class="form-wrapper">
              <form-line
                :content="{
                  value: value.lineContent,
                  errorMessage: value.lineContentErrorMessage,
                }"
                :copy-tags="RESULTS_COPY_TAGS"
                @blur-content="value.handleChangeLineContent"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include actionCommon;
@include actionTemplate;
</style>
