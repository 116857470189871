import { reactive } from 'vue';
import { cloneDeep } from 'lodash';
import emojiRegex from 'emoji-regex';

/**
 * @param {[string]} errorKeys - バリデーション対象key配列
 */
export default function useValidation(errorKeys) {
  const defaultErrors = {};
  /**
   * パスワードの最小文字数
   */
  const PASSWORD_LENGTH_MIN = 8;
  /**
   * パスワードの最大文字数
   */
  const PASSWORD_LENGTH_MAX = 64;
  /**
   * 名前の最大文字数
   */
  const NAME_LENGTH_MAX = 64;
  /**
   * 開催時間参加人数の最大人数
   */
  const TIMETABLE_LIMIT_LENGTH_MAX = 30000;
  /**
   * 開催時間出席確認パスワードの最大文字数
   */
  const REMINDER_PASSWORD_LENGTH_MAX = 8;

  errorKeys.forEach(
    key => (defaultErrors[key] = { isValid: true, message: '' }),
  );
  const errors = reactive(cloneDeep(defaultErrors));

  /**
   * バリデーション用のエラーオブジェクトを全てリセット
   */
  const resetErrors = () => {
    errorKeys.forEach(key => {
      errors[key].isValid = true;
      errors[key].message = '';
    });
  };

  /**
   * バリデーション用のエラーオブジェクトをリセット
   */
  const resetError = key => {
    errors[key].isValid = true;
    errors[key].message = '';
  };

  /**
   * errorsの手動更新
   * @param {string} key - 対象Key
   * @param {string} message - エラー文言
   */
  const updateErrors = (key, message) => {
    errors[key].isValid = false;
    errors[key].message = message;
  };

  /**
   * 入力の必須項目汎用バリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @param {string} type - バリデーション対象種別 input | select (default: input)
   * @return {boolean} バリデーション結果
   */
  const validateRequire = (key, val, type = 'input') => {
    if (!val || val === '') {
      const message =
        type === 'input' ? '入力してください' : '選択してください';
      errors[key].isValid = false;
      errors[key].message = message;
    }
    return errors[key].isValid;
  };

  /**
   * 最小入力文字数の汎用バリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @param {int} limit - 最小文字数
   * @return {boolean} バリデーション結果
   */
  const validateMinLength = (key, val, limit) => {
    if (val.length < limit) {
      const message = `${limit}文字以上で入力してください`;
      errors[key].isValid = false;
      errors[key].message = message;
    }
    return errors[key].isValid;
  };

  /**
   * 最大入力文字数のバリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @param {int} limit - 最大文字数
   * @return {boolean} バリデーション結果
   */
  const validateMaxLength = (key, val, limit) => {
    if (val.length > limit) {
      const message = `${limit}文字以下で入力してください`;
      errors[key].isValid = false;
      errors[key].message = message;
    }
    return errors[key].isValid;
  };

  /**
   * 数値の汎用バリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @return {boolean} バリデーション結果
   */
  const validateNumber = (key, val) => {
    errors[key].isValid = true;
    errors[key].message = '';
    // 以下の文字を許容 "0,1,2,3,4,5,6,7,8,9"
    if (!String(val).match(/^[0-9]+$/u)) {
      errors[key].isValid = false;
      errors[key].message = '番号のみで入力してください';
    }
    return errors[key].isValid;
  };

  /**
   * 半角英数字+記号の汎用バリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @return {boolean} バリデーション結果
   */
  const validateASCII = (key, val) => {
    // 半角英数字+記号のみ許容
    errors[key].isValid = true;
    errors[key].message = '';
    if (
      typeof val !== 'string' ||
      String(val)
        .split('')
        .every(val => val.charCodeAt(0) >= 33 && val.charCodeAt(0) <= 126) !==
        true
    ) {
      errors[key].isValid = false;
      errors[key].message = '利用できない文字が含まれています';
    }
    return errors[key].isValid;
  };

  /**
   * カナの専用バリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @return {boolean} バリデーション結果
   */
  const validateKana = (key, val) => {
    // 以下の文字を許容 "ァアィイゥウェエォオカガキギクグケゲコゴサザシジスズセゼソゾタダチヂッツヅテデトドナニヌネノハバパヒビピフブプヘベペホボポマミムメモャヤュユョヨラリルレロヮワヰヱヲンヴー"
    if (!String(val).match(/^[ァ-ヴー]+$/u)) {
      errors[key].isValid = false;
      errors[key].message = '全角カタカナで入力してください';
    }
    return errors[key].isValid;
  };

  /**
   * 大文字が1文字以上含まれるかの汎用バリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @return {boolean} バリデーション結果
   */
  const validateIncludeUppercase = (key, val) => {
    errors[key].isValid = true;
    errors[key].message = '';
    // 大文字が1文字以上含まれれば許容
    if (typeof val !== 'string' || !/[A-Z]/.test(val)) {
      errors[key].isValid = false;
      errors[key].message = '1文字以上の大文字を含めて入力してください';
    }
    return errors[key].isValid;
  };

  /**
   * 絵文字が含まれていないかのバリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @return {boolean} バリデーション結果
   */
  const validateEmoji = (key, val) => {
    errors[key].isValid = true;
    errors[key].message = '';
    // 絵文字が含まれていなければ許容
    if (typeof val !== 'string' || emojiRegex().test(val)) {
      errors[key].isValid = false;
      errors[key].message = '利用できない文字が含まれています';
      return false;
    }
    return errors[key].isValid;
  };

  /**
   * Emailの専用バリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @param {boolean} isRequired - 必須フラグ（Optional。デフォルト: true）
   * @return {boolean} バリデーション結果
   */
  const validateEmail = (key, val, isRequired = true) => {
    // 必須でない場合はvalは空でもOK
    if (isRequired !== true && !val) return true;
    if (!String(val).match(/.+@.+\..+/)) {
      errors[key].isValid = false;
      errors[key].message = '正しいメールアドレスを入力してください';
    }
    return errors[key].isValid;
  };

  /**
   * URLのバリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @param {boolean} isRequired - 必須フラグ（Optional。デフォルト: true）
   * @return {boolean} バリデーション結果
   */
  const validateUrl = (key, val, isRequired = true) => {
    // 必須でない場合はvalは空でもOK
    if (isRequired !== true && !val) return true;
    // http:// or https:// のどちらかのみチェック
    if (!String(val).match(/^(http|https):\/\/[^ "]+$/)) {
      errors[key].isValid = false;
      errors[key].message = '正しいURLを入力してください';
    }
    return errors[key].isValid;
  };

  /**
   * リッチメッセージ専用のURLのバリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @param {boolean} isRequired - 必須フラグ（Optional。デフォルト: true）
   * @return {boolean} バリデーション結果
   */
  const validateRichMessageUrl = (key, val, isRequired = true) => {
    // 必須でない場合はvalは空でもOK
    if (isRequired !== true && !val) return true;
    // https:// or line:// のどちらかかつ英数記号のみ（ただし {}[]\^´｀|<>" の記号は許可しない）
    if (
      !String(val).match(/^(https|line):\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+$/)
    ) {
      errors[key].isValid = false;
      errors[key].message = '正しいURLを入力してください';
    }
    return errors[key].isValid;
  };

  /**
   * 電話番号の専用バリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @param {boolean} isRequired - 必須フラグ（Optional。デフォルト: true）
   * @return {boolean} バリデーション結果
   */
  const validateTel = (key, val, isRequired = true) => {
    // 必須でない場合はvalは空でもOK
    if (isRequired !== true && !val) return true;
    // 数値のみで10,11桁のみ許容
    if (val.length < 10 || val.length > 11 || String(val).match(/[^0-9]/)) {
      errors[key].isValid = false;
      errors[key].message = '正しい電話番号を入力してください';
    }
    return errors[key].isValid;
  };

  /**
   * 郵便番号の専用バリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @param {boolean} isRequired - 必須フラグ（Optional。デフォルト: true）
   * @return {boolean} バリデーション結果
   */
  const validateZipcode = (key, val, isRequired = true) => {
    // 必須でない場合はvalは空でもOK
    if (isRequired !== true && !val) return true;
    // 数値のみで7桁のみ許容
    if (val.length !== 7 || String(val).match(/[^0-9]/)) {
      errors[key].isValid = false;
      errors[key].message = '正しい郵便番号を入力してください';
    }
    return errors[key].isValid;
  };

  /**
   * パスワードの専用バリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @param {boolean} isRequired - 必須フラグ（Optional。デフォルト: true）
   * @return {boolean} バリデーション結果
   */
  const validatePassword = (key, val) => {
    errors[key].isValid = true;
    errors[key].message = '';
    if (!validateMinLength(key, val, PASSWORD_LENGTH_MIN)) {
      return false;
    }
    if (!validateMaxLength(key, val, PASSWORD_LENGTH_MAX)) {
      return false;
    }
    if (!validateASCII(key, val)) {
      return false;
    }
    if (!validateIncludeUppercase(key, val)) {
      return false;
    }
    return true;
  };

  /**
   * パスワードと確認用パスワードの専用比較
   * @param {string} password - パスワードの値
   * @param {string} confirmPassword - 確認用パスワードの値
   * @param {string} confirmPasswordKey - 確認用パスワードKey (default: 'confirmPassword')
   * @return {boolean} バリデーション結果
   */
  const validateComparisonPassword = (
    password,
    confirmPassword,
    confirmPasswordKey = 'confirmPassword',
  ) => {
    errors[confirmPasswordKey].isValid = true;
    errors[confirmPasswordKey].message = '';
    if (password === confirmPassword) return true;
    errors[confirmPasswordKey].isValid = false;
    errors[confirmPasswordKey].message = '確認用パスワードが一致しません';
    return false;
  };

  /**
   * 姓名の専用バリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @return {boolean} バリデーション結果
   */
  const validateName = (key, val) => {
    errors[key].isValid = true;
    errors[key].message = '';
    if (!validateRequire(key, val)) return false;
    if (!validateMaxLength(key, val, PASSWORD_LENGTH_MAX)) {
      return false;
    }
    if (!validateEmoji(key, val)) return false;
    return true;
  };

  /**
   * OTPの専用バリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @return {boolean} バリデーション結果
   */
  const validateOtp = (key, val) => {
    if (val.length !== 6 || !String(val).match(/^\d+$/)) {
      errors[key].isValid = false;
      errors[key].message = '正しいワンタイムパスワードを入力してください';
    }
    return errors[key].isValid;
  };

  /**
   * 検索条件プリセット名の専用バリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @return {boolean} バリデーション結果
   */
  const validatePresetName = (key, val) => {
    errors[key].isValid = true;
    errors[key].message = '';
    if (!validateRequire(key, val)) return false;
    if (!validateMaxLength(key, val, 30)) {
      return false;
    }
    if (!validateEmoji(key, val)) return false;
    return true;
  };

  /**
   * IPアドレスのバリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @return {boolean} バリデーション結果
   */
  const validateIpAddress = (key, val) => {
    errors[key].isValid = true;
    errors[key].message = '';

    const ipv4Regex =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    if (val !== '' && !ipv4Regex.test(val)) {
      errors[key].isValid = false;
      errors[key].message = '有効なIPアドレスではありません';
    }
    return errors[key].isValid;
  };

  /**
   * ストレージサイズの専用バリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @param {int} limit - 最小文字数
   * @param {boolean} isRequired - 必須フラグ（Optional。デフォルト: true）
   * @return {boolean} バリデーション結果
   */
  const validateStorage = (key, val, min, max, isRequired = true) => {
    // 必須でない場合はvalは空でもOK
    if (isRequired !== true && !val) return true;
    if ((val !== 0 && !val) || val < min || val > max) {
      const message = `${min}〜${max}GBの数値を入力してください`;
      errors[key].isValid = false;
      errors[key].message = message;
    }
    return errors[key].isValid;
  };

  /**
   * ブラー時のバリデーション処理する関数
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @param {function} callback - 実行するバリデーション関数
   * @param {boolean} isRequired - 必須項目フラグ（Optional。デフォルト: true）
   * @return {boolean} バリデーション結果
   */
  const validateOnInput = (key, val, callbackValidation, isRequired = true) => {
    resetError(key);
    if (isRequired === true) return callbackValidation(key, val);
    // 必須ではない場合、値が空であればバリデーションを無視する
    return val ? callbackValidation(key, val) : true;
  };

  /**
   * 卒年追加時のバリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @param {int} max - 最大卒年数
   * @param {int} min - 最小卒年数
   * @param {int[]} notIn - 既に登録済みの年数配列
   * @return {boolean} バリデーション結果
   */
  const validateGraduatedYear = (key, val, max, min, notIn) => {
    errors[key].isValid = true;
    errors[key].message = '';
    if (!validateRequire(key, val)) return false;
    if (val > max) {
      errors[key].isValid = false;
      errors[key].message = `${max}年以下を指定して下さい`;
      return false;
    }
    if (val < min) {
      errors[key].isValid = false;
      errors[key].message = `${min}年以上を指定して下さい`;
      return false;
    }
    if (notIn.includes(val)) {
      errors[key].isValid = false;
      errors[key].message = '既に登録済みの卒業年数です';
      return false;
    }
    return errors[key].isValid;
  };

  /**
   * 開催時間参加人数バリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @return {boolean} バリデーション結果
   */
  const validateTimetableLimit = (key, val) => {
    errors[key].isValid = true;
    errors[key].message = '';
    // 以下の文字を許容 "0,1,2,3,4,5,6,7,8,9"
    if (!String(val).match(/^[0-9]+$/u)) {
      errors[key].isValid = false;
      errors[key].message = '数値のみで入力してください';
      return errors[key].isValid;
    }
    if (
      parseInt(val, 10) < 1 ||
      parseInt(val, 10) > TIMETABLE_LIMIT_LENGTH_MAX
    ) {
      errors[key].isValid = false;
      errors[key].message =
        `1〜${TIMETABLE_LIMIT_LENGTH_MAX}までのみ指定可能です`;
      return errors[key].isValid;
    }
    return errors[key].isValid;
  };

  /**
   * 開催時間出席確認パスワードの専用バリデーション
   * @param {string} key - バリデーション対象Key
   * @param {string} val - バリデーション対象値
   * @param {boolean} isRequired - 必須フラグ（Optional。デフォルト: true）
   * @return {boolean} バリデーション結果
   */
  const validateTimetableReminderPassword = (key, val) => {
    errors[key].isValid = true;
    errors[key].message = '';
    if (!validateMaxLength(key, val, REMINDER_PASSWORD_LENGTH_MAX)) {
      return false;
    }
    // TODO: UI上では半角英数字のみ許容としているがバリデーションがなされていなかった。バリデーションを実行する仕様となった場合、下記のコメントアウトを外せば出席確認パスワードのバリデーションが実行される。
    // if (!validateASCII(key, val)) {
    //   return false;
    // }
    return true;
  };

  return {
    errors,
    PASSWORD_LENGTH_MAX,
    NAME_LENGTH_MAX,
    resetErrors,
    resetError,
    updateErrors,
    validateRequire,
    validateMinLength,
    validateMaxLength,
    validateNumber,
    validateKana,
    validateEmoji,
    validateEmail,
    validateUrl,
    validateRichMessageUrl,
    validateTel,
    validateZipcode,
    validateOtp,
    validatePassword,
    validateComparisonPassword,
    validateName,
    validatePresetName,
    validateStorage,
    validateIpAddress,
    validateOnInput,
    validateGraduatedYear,
    validateTimetableLimit,
    validateTimetableReminderPassword,
  };
}
