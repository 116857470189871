<template>
  <transition name="fade">
    <div v-if="$store.getters['page/isPageLoaded']">
      <div class="entryform_description">
        <p>
          応募者がエントリーフォームに入力する項目を、登録種別ごとにカスタマイズできます。
        </p>
        <p>
          ※卒業年度・登録種別、姓名、セイメイの項目は、応募者登録の際に必須になるためカスタマイズはできません。
        </p>
      </div>

      <div class="entryform-warp">
        <div class="entryform_setting">
          <label class="entryform_setting_select_id_label" for>登録種別</label>
          <select
            v-model="selectedId"
            class="entryform_setting_select_id"
            type="text"
            name="graduated"
            required
          >
            <option value>▼選択してください</option>
            <option
              v-for="graduated in $store.getters['graduateds/graduateds']"
              :key="graduated.year"
              :label="graduatedYearToLabelName(graduated.year)"
              :value="graduated.id"
            >
              {{ graduatedYearToLabelName(graduated.year) }}
            </option>
          </select>
          <div class="entryform_setting_ttl">選択項目</div>
          <entry-form-setting-check-list
            :form-keys="formKeys"
            :form-setting="formSetting"
            :get-form-label="getFormLabel"
            @setPreviewData="setPreviewData"
          />
        </div>

        <div>
          <p class="preview_ttl">プレビュー画面</p>
          <entry-form-setting-preview
            v-if="selectedYear"
            ref="refEntryFormSettingPreview"
            :company-name="$store.getters['company/company'].name"
            :graduateds="$store.getters['graduateds/graduateds']"
            :graduated-year="selectedYear"
            :form-setting="formSetting"
            :toggle-switch="toggleSwitch"
            :is-preview-flagsurvey="hasFlagsurvey"
            :is-setting="true"
          />
        </div>
      </div>

      <div>
        <p class="entryform_setting_select_id_label">アンケート</p>
        <div class="flagsurvey-checkbox">
          <label
            class="entryform_setting_label"
            for="flagsurvey"
            :class="{ checked: formSetting.gender }"
          >
            <input
              id="flagsurvey"
              v-model="hasFlagsurvey"
              class="entryform_setting_input"
              type="checkbox"
            />
            <span class="entryform_setting_text">アンケートを有効にする</span>
          </label>
        </div>
        <div v-if="hasFlagsurvey === true">
          <ul class="flagsurvey_questions_components">
            <flag-survey-question
              v-for="(flagsurveyQuestion, i) in flagsurveyQuestions"
              ref="refQuestion"
              :key="flagsurveyQuestion.id"
              :index="i + 1"
              :question-id="flagsurveyQuestion.id"
              :question-type="flagsurveyQuestion.questionType"
              :question-title="flagsurveyQuestion.questionTitle"
              :question-multi="flagsurveyQuestion.multi"
              :question-flag-group-id="flagsurveyQuestion.tagGroupId"
              :question-tag-groups="flagsurveyQuestion.tagGroups"
              :questions="flagsurveyQuestions"
              :is-entry-form="true"
              @update-question-title="updateQuestionTitle"
              @update-multi="updateMulti"
              @update-tag-groups="updateTagGroups"
              @delete-question="deleteFlagsurveyQuestion"
            />
          </ul>
          <div
            class="btn btn-availability btn-add-question"
            :class="{
              'is-disabled': tagGroups.length === flagsurveyQuestions.length,
            }"
            @click="addFlagsurveyQuestion"
          >
            <img class="icon" src="@/assets/img/add_white.svg" alt />
            質問文の追加
          </div>
          <ul class="footer_caption">
            <li>
              ※アンケート送信時点で各フラググループに設定されているフラグ情報が選択肢になります。
            </li>
          </ul>
        </div>
      </div>

      <div class="submit_btn_block">
        <div class="btn btn-availability" @click="openConfirmModal">
          プレビューの内容で入力項目を変更する
        </div>
      </div>

      <modal-window
        :is-visible="isVisibleEntryFromSubmit"
        :title="`${selectedYearModalTitle}の入力項目を変更します`"
        :message="`${selectedYearModalTitle}の入力項目を変更しますか？`"
        button-text="入力項目を変更"
        :is-loading="isLoading"
        @click="submitEntryForm"
        @close="isVisibleEntryFromSubmit = false"
      />
    </div>
  </transition>
</template>

<script>
import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue';
import { useStore } from 'vuex';

import GraduatedDefines from '@/defines/graduated';
import graduatedsService from '@/services/graduateds';
import tagsService from '@/services/tags';
import { generateUniqueId } from '@/utils/unique-id';
import useEntryFormSetting from '@/composables/useEntryFormSetting';
import EntryFormSettingCheckList from '@/components/features/entryformSetting/components/EntryFormSettingCheckList';
import EntryFormSettingPreview from '@/components/features/entryformSetting/components/EntryFormSettingPreview';
import FlagSurveyQuestion from '@/components/features/flagSurveyQuestion/components/FlagSurveyQuestion';
import { setIsSelectedAnswer } from '@/components/page/line_entry/helpers/FlagsurveyQuestionsSelector';

export default defineComponent({
  name: 'EntryformSetting',
  components: {
    FlagSurveyQuestion,
    EntryFormSettingCheckList,
    EntryFormSettingPreview,
  },
  setup(props, context) {
    const {
      formKeys,
      formSetting,
      getFormLabel,
      toggleSwitch,
      setFormSettingAll,
    } = useEntryFormSetting();
    const store = useStore();
    const refEntryFormSettingPreview = ref(null);
    const refQuestion = ref(null);
    const selectedId = ref(null);
    const selectedYear = ref(null);
    const selectedYearModalTitle = ref('');
    const hasFlagsurvey = ref(false);
    const flagsurveyQuestions = ref([]);
    const tagGroups = ref([]);
    const isLoading = ref(false);
    const isVisibleEntryFromSubmit = ref(false);

    // computed
    const previewQuestions = computed(() => {
      return flagsurveyQuestions.value.map(question => {
        const selectedTagGroup = question.tagGroups.find(x => x.selected);
        return {
          id: question.id,
          multi: question.multi,
          questionType: question.questionType,
          questionTitle: question.questionTitle,
          answers: selectedTagGroup
            ? selectedTagGroup.tags.map(answer => ({
                id: answer.id,
                flag_name: answer.name,
                selected: false,
              }))
            : [],
        };
      });
    });

    // watch
    watch(selectedId, async () => await fetchAll());

    // methods
    const fetchAll = async () => {
      const res = await graduatedsService.getFormSetting(selectedId.value);
      setFormSettingAll(res);
      if (
        res.entry_flagsurvey &&
        res.entry_flagsurvey.entry_flagsurvey_questions &&
        res.entry_flagsurvey.entry_flagsurvey_questions.length > 0
      ) {
        // entry_flagsurvey_questions を含む場合
        hasFlagsurvey.value = true;
        flagsurveyQuestions.value =
          res.entry_flagsurvey.entry_flagsurvey_questions.map(question => ({
            id: String(question.id),
            questionType: question.question_type ? question.question_type : 1,
            questionTitle: question.question_title,
            multi: question.multi,
            tagGroupId: question.tag_group_id,
            tagGroups: tagGroups.value.map(x => Object.assign({}, x)),
          }));
        // tagGroupsのselected/flagsurveyQuestion.tagGroups を更新
        flagsurveyQuestions.value.forEach(question => {
          updateTagGroups({
            questionId: question.id,
            questionFlagGroupId: question.tagGroupId,
          });
        });
      } else {
        // entry_flagsurvey_questions を含まない場合、表示リセット
        resetQuestions();
      }
      selectedYear.value = res.year;
      if (res.year < 3000) {
        selectedYearModalTitle.value = `${res.year}年卒`;
      } else {
        selectedYearModalTitle.value = graduatedYearToLabelName(res.year);
      }
      // ディレイをかけないと効かない
      setTimeout(() => setPreviewData(), 100);
    };
    const getTagGroups = async () => {
      let tagGroups = await tagsService.fetchTagGroups();
      const tagGroupsData = tagGroups.reduce((accumulator, group) => {
        if (group.tags.length > 0) {
          accumulator.push({
            id: group.id,
            name: group.name,
            isAdditional: true,
            selected: false,
            tags: group.tags.map(tag => {
              tag.selected = false;
              return tag;
            }),
          });
        }
        return accumulator;
      }, []);
      return tagGroupsData;
    };
    const submitEntryForm = async ev => {
      if (ev.selected === 'submit') {
        isLoading.value = true;
        const req = { id: selectedId.value };
        Object.keys(formSetting).forEach(key => {
          req[key] = formSetting[key];
        });
        if (hasFlagsurvey.value === true) {
          // entry_flagsurvey_questions を含む場合
          req.entry_flagsurvey_questions = flagsurveyQuestions.value.map(
            question => ({
              question_type: question.questionType,
              question_title: question.questionTitle,
              multi: question.multi,
              tag_group_id: question.tagGroupId,
            }),
          );
        }
        const res = await graduatedsService.updateFormSetting(req);
        if (res.success) {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: `${selectedYearModalTitle.value}の表示項目を変更しました。`,
            type: true,
          });
          await fetchAll();
        } else {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: `${selectedYearModalTitle.value}の表示項目の変更に失敗しました。`,
            type: false,
          });
        }
      }
      isVisibleEntryFromSubmit.value = false;
      isLoading.value = false;
    };
    const openConfirmModal = async () => {
      if (hasFlagsurvey.value === true) {
        // entry_flagsurvey_questions を含む場合
        const questionValidList = refQuestion.value.map(v => v.validate());
        if (questionValidList.some(v => v.success !== true)) {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: questionValidList.find(v => v.success !== true).message,
            type: false,
          });
          return;
        }
      }
      isVisibleEntryFromSubmit.value = true;
    };
    const graduatedYearToLabelName = year => {
      const res = GraduatedDefines.find(graduated => graduated.year === year);
      if (res !== undefined) return res.name_jp;
      return `${year}`;
    };
    const addFlagsurveyQuestion = () => {
      // tagGroupsより多くの質問は作成できない
      if (flagsurveyQuestions.value.length >= tagGroups.value.length) {
        return;
      }
      flagsurveyQuestions.value.push({
        id: `local_${generateUniqueId()}`,
        questionType: 1,
        questionTitle: '',
        multi: false,
        tagGroupId: null,
        tagGroups: tagGroups.value.filter(x => !x.selected),
      });
    };
    const deleteFlagsurveyQuestion = payload => {
      flagsurveyQuestions.value = flagsurveyQuestions.value.filter(
        x => x.id !== payload.questionId,
      );
      updateTagGroups(payload);
      resetTagGroupTagsIsSelected(payload.questionFlagGroupId);
    };
    const updateQuestionTitle = payload => {
      flagsurveyQuestions.value = flagsurveyQuestions.value.map(x => {
        if (x.id === payload.questionId) {
          x.questionTitle = payload.questionTitle;
        }
        return x;
      });
      setPreviewData();
    };
    const updateMulti = payload => {
      flagsurveyQuestions.value = flagsurveyQuestions.value.map(question => {
        if (question.id === payload.questionId) {
          question.multi = payload.multi;
        }
        return question;
      });
      resetTagGroupTagsIsSelected(payload.questionFlagGroupId);
      setPreviewData();
    };
    const updateTagGroups = payload => {
      // 対象QuestionのtagGroupIdを更新（削除の際は無視される）
      flagsurveyQuestions.value = flagsurveyQuestions.value.map(x => {
        if (x.id === payload.questionId) {
          x.tagGroupId = payload.questionFlagGroupId;
        }
        return x;
      });
      // tagGroupsのselectedをQuestionのtagGroupIdから更新
      tagGroups.value = tagGroups.value.map(x => {
        x.selected = flagsurveyQuestions.value
          .map(y => y.tagGroupId)
          .includes(x.id);
        return x;
      });
      // Question毎のtagGroupsを更新
      flagsurveyQuestions.value = flagsurveyQuestions.value.map(x => {
        x.tagGroups = tagGroups.value.filter(
          y => y.id === x.tagGroupId || !y.selected,
        );
        return x;
      });
      resetTagGroupTagsIsSelected(payload.questionFlagGroupId);
      setPreviewData();
    };
    const getTags = tagGroupId => {
      const tagGroup = tagGroups.value.find(
        x => x.id === parseInt(tagGroupId, 10),
      );
      return tagGroup ? tagGroup.tags : [];
    };
    const resetTagGroupTagsIsSelected = tagGroupId => {
      tagGroups.value = tagGroups.value.map(tagGroup => {
        if (tagGroup.id === tagGroupId) {
          tagGroup.tags = tagGroup.tags.map(tag => {
            tag.selected = false;
            return tag;
          });
        }
        return tagGroup;
      });
    };
    const resetQuestions = () => {
      hasFlagsurvey.value = false;
      flagsurveyQuestions.value = [];
      // tagGroupsとtagsのselectedを解除
      tagGroups.value = tagGroups.value.map(tagGroup => {
        tagGroup.selected = false;
        tagGroup.tags.map(tag => {
          tag.selected = false;
          return tag;
        });
        return tagGroup;
      });
    };
    const onSelectAnswer = (questionIndex, answerIndex) => {
      flagsurveyQuestions.value = setIsSelectedAnswer(
        flagsurveyQuestions.value,
        questionIndex,
        answerIndex,
      );
    };
    const setPreviewData = (key = null) => {
      // プレビューに渡す登録種別・卒年、必須項目Object、質問配列を渡す
      if (
        refEntryFormSettingPreview.value &&
        refEntryFormSettingPreview.value.setPreviewData
      ) {
        refEntryFormSettingPreview.value.setPreviewData(
          key,
          previewQuestions.value,
        );
      }
    };

    // lifecycle
    onBeforeMount(async () => {
      selectedId.value = store.getters['graduateds/selectedGraduatedId'];
      const graduatedYear =
        await store.getters['graduateds/selectedGraduatedYear'];
      selectedYear.value = graduatedYear.year;
      tagGroups.value = await getTagGroups();
      flagsurveyQuestions.value = [
        {
          id: `local_${generateUniqueId()}`,
          multi: false,
          questionType: 1,
          questionTitle: '',
          tagGroupId: null,
          tagGroups: tagGroups.value.map(x => Object.assign({}, x)),
        },
      ];
      await fetchAll();
      await store.dispatch('page/SET_LOADED');
    });

    return {
      refEntryFormSettingPreview,
      refQuestion,
      selectedId,
      selectedYear,
      selectedYearModalTitle,
      formSetting,
      formKeys,
      hasFlagsurvey,
      flagsurveyQuestions,
      tagGroups,
      isLoading,
      isVisibleEntryFromSubmit,
      getFormLabel,
      toggleSwitch,
      submitEntryForm,
      openConfirmModal,
      graduatedYearToLabelName,
      addFlagsurveyQuestion,
      deleteFlagsurveyQuestion,
      updateQuestionTitle,
      updateMulti,
      updateTagGroups,
      getTags,
      resetTagGroupTagsIsSelected,
      resetQuestions,
      onSelectAnswer,
      setPreviewData,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

// fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.entryform_description {
  margin-bottom: 30px;
  font-size: 1.4rem;

  p {
    letter-spacing: 0.1;
    margin-bottom: 10px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

.entryform-warp {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}

.entryform_setting {
  width: 295px;
  margin-right: 40px;

  .entryform_setting_select_id {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    padding: 5px;
    border-radius: 6px;
    border: 1px solid #ccc;
  }

  .entryform_setting_ttl {
    padding: 12px 20px;
    background: #e2e2e2;
    font-weight: bold;
  }
}

.entryform_setting_select_id_label {
  display: block;
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: bold;
}

.flagsurvey-checkbox {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  .entryform_setting_label {
    display: flex;
    align-items: center;
    &.checked {
    }
    .entryform_setting_input {
      appearance: none;
      width: 1.4rem;
      height: 1.4rem;
      margin-right: 5px;
      border-radius: 4px;
      border: 2px solid #9d9d9d;
      background: #fff;
      position: relative;
      cursor: pointer;
      &:checked {
        &::after {
          content: url(../../../assets/img/check.svg);
          height: 1.1rem;
          width: 1.5rem;
          margin: -5px 0 0 1px;
          position: absolute;
          bottom: 3px;
          left: 0;
        }
      }
    }
    .entryform_setting_text {
      display: inline-block;
      margin-left: 0.5rem;
      cursor: pointer;
    }
  }
}

.preview_ttl {
  margin-bottom: 16px;
  font-weight: bold;
}

.flagsurvey_questions_components {
  :deep(.flag_survey_heading),
  :deep(.flag_survey_heading_checkbox) {
    font-size: 1.2rem;
    color: #333;
  }

  :deep(.btn-delete),
  :deep(.input_tr > textarea),
  :deep(.input_tr > .ui-select),
  :deep(.flag_survey_checkbox > label) {
    font-size: 1.2rem;
  }
}

.btn-add-question {
  margin-bottom: 20px;

  &.is-disabled {
    background-color: #ccc;
    cursor: default;
  }
}

.flagsurvey-caption {
  margin-bottom: 30px;
}

// submit btn area
.submit_btn_block {
  width: 736px;
  text-align: center;

  .btn {
    padding: 15px 50px;
  }
}

.footer_caption {
  margin: 20px 0 40px;
  > li {
    margin-bottom: 7px;
  }
}

@include form_wrapper_css();

@media (max-width: ($media_query_tablet)) {
  .entryform_description {
    line-height: 1.4;
  }

  .entryform-warp {
    display: block;
    margin-bottom: 40px;
  }

  .entryform_setting {
    width: 100%;
    margin-bottom: 40px;
  }

  .submit_btn_block {
    width: 100%;
  }
}

@media (max-width: ($media_query_sp)) {
  .submit_btn_block {
    background-color: none;

    .btn {
      width: 100%;
      padding: 15px 10px;
    }
  }
}
</style>
