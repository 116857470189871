<template>
  <main class="flexbox width-sp-limit">
    <div class="contents">
      <div class="result_result_wrapper contents_wrapper">
        <div class="result_result_intro">
          <h2 class="result_result_intro_desc">選考結果のお知らせ</h2>
          <div class="result_result_intro_txt">
            <span class="company_name">{{ resultData.applicant.company }}</span>
            で行われた、
            <br />
            <span class="selection_name">{{ resultData.selection.title }}</span>
            の選考結果のお知らせです。
          </div>
        </div>
        <div class="result_result">
          <div v-if="resultData !== null">
            <div class="">
              <div
                v-if="resultData.finished_status.name === 'passed'"
                class="result_item pass"
              >
                <span>
                  {{ resultData.applicant.lastname
                  }}{{ resultData.applicant.firstname }} 様の選考結果は
                </span>
                <span class="result_item_result">
                  {{ resultData.finished_status.description }}
                </span>
                となりました。
              </div>
              <div
                v-if="resultData.finished_status.name === 'offered'"
                class="result_item offered"
              >
                <span>
                  {{ resultData.applicant.lastname
                  }}{{ resultData.applicant.firstname }} 様の選考結果は
                </span>
                <span class="result_item_result">
                  {{ resultData.finished_status.description }}
                </span>
                となりました。
                <br />
                今後の詳細につきましては、別途担当者から御連絡いたします。
              </div>
              <div
                v-if="resultData.finished_status.name === 'dropped'"
                class="result_item dropped"
              >
                <div class="dropped_messages">
                  <p>
                    {{ resultData.applicant.lastname
                    }}{{ resultData.applicant.firstname }} 様
                  </p>
                  <br />
                  <p>
                    こんにちは。
                    <br />
                    {{ resultData.applicant.company }}でございます。
                    <br />
                    <br />
                    この度は弊社の募集に際し、ご応募頂きありがとうございました。
                    <br />
                    <br />
                    慎重に選考を重ねました結果、遺憾ながら今回は採用を見送らせて頂くことになりました。
                    <br />
                    <br />
                    誠に不本意な結論かと存じますが、ご了承下さいますようお願い申し上げます。
                    <br />
                    <br />
                    末筆ながら、今後のご健勝をお祈り申し上げます。
                  </p>
                  <!-- <span class="result_item_result">{{resultData.finished_status.description}}</span> -->
                </div>
              </div>
            </div>
            <div
              v-if="resultData.finished_status.name === 'passed'"
              class="comment result_item_item"
            >
              次の選考の連絡が来るまでお待ちください。
            </div>
            <!-- <div
            v-if="resultData.finished_status.name === 'dropped'"
            class="comment result_item_item"
            >
              今後の{{resultData.applicant.lastname}}{{resultData.applicant.firstname}} 様のますますのご活躍を心よりお祈りしております。
            </div> -->
          </div>
        </div>
        <div v-if="resultData === null">通信に失敗しました。</div>
      </div>
    </div>
  </main>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ResultResult',
  props: {
    resultData: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

main.flexbox {
  justify-content: center;
}
.contents {
  width: 80%;
  & :deep(.result_result_wrapper) {
    width: 90%;
    max-width: 96rem;
    margin: 0 auto;
    text-align: center;
  }
}

.result_result_intro {
  margin-bottom: 2.4rem;
  .result_result_intro_desc {
    font-size: 2rem;
    color: #656565;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
  }
  .result_result_intro_txt {
    font-size: 1.4rem;
  }
}
.result_result {
  .result_item {
    @include flex_center_center;
    > * {
      display: block;
    }
    padding: 3rem;
    width: 100%;
    margin-bottom: 3.4rem;
    border: 2px solid #656565;
    border-radius: 0.4rem;
    .result_item_result {
      @include flex_center_center;
      width: 32rem;
      font-size: 1.4rem;
      border-radius: 60rem;
      margin: 0;
      height: 3rem;
      letter-spacing: 0.1em;
    }
    &.pass {
      background: #ffecf4;
      border-color: #e83d86;
      .result_item_result {
        font-weight: bold;
        color: #ffffff;
        background: #e83d86;
        border: none;
        margin: 10px 0;
      }
    }
    &.offered {
      background: #eff9ff;
      border-color: #1e87bf;
      .result_item_result {
        background: #1e87bf;
        font-weight: bold;
        color: #fff;
        margin: 10px 0;
      }
    }
    &.dropped {
      margin-bottom: 0;
      background: #fff;
      border-color: #333;
      display: flex;
      justify-content: center;
      .dropped_messages {
        text-align: left;
      }
      .result_item_result {
        background: #777777;
        font-weight: bold;
        color: #fff;
        margin: 10px 0;
      }
    }
  }
}
.result_item {
  display: flex;
  flex-flow: column;
}
.result_item_item {
  margin-top: 3rem;
  padding-top: 2rem;
  height: 3rem;
}
.result_result .result_item_result {
  margin: 10px;
}

@media screen and (max-width: 600px) {
  .contents {
    height: 100vh;
    padding: 0 0 30vh;
    & :deep(.result_result_wrapper) {
      width: 100%;
      max-width: 96rem;
      margin: 0 auto;
      text-align: center;
    }
  }

  .result_result_intro {
    margin-bottom: 2.4rem;
    .result_result_intro_desc {
      font-size: 2rem;
      color: #656565;
      font-weight: bold;
      text-align: center;
      margin-bottom: 1rem;
    }
    .result_result_intro_txt {
      font-size: 1.4rem;
    }
  }
  .result_result {
    .result_item {
      padding: 2rem;
      width: 100%;
      border: 2px solid #d6effb;
      border-radius: 0.6rem;
      margin-bottom: 3.4rem;
      display: block;
      > * {
        display: block;
        width: 100%;
        margin: 1rem 0;
      }
      .result_item_result {
        @include flex_center_center;
        width: 100%;
        padding: 1rem;
        font-size: 1.4rem;
        border: 1px solid $border_color;
        border-radius: 60rem;
        margin: 0;
      }
      .dropped {
        margin-bottom: 0;
      }
    }
  }
  .result_item_item {
    // border-top: 1px solid $border_color;
    // margin-top: 3rem;
    // padding-top: 3rem;
  }
}
@media screen and (max-width: 600px) {
  main > div.contents {
    padding-bottom: 0px;
    height: 100%;
  }
}
</style>
