<script setup>
defineOptions({ inheritAttrs: false });
defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  isError: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:modelValue']);

const updateModelValue = event => {
  emit('update:modelValue', event.target.value);
};
</script>

<template>
  <div class="wrapper">
    <select
      v-bind="$attrs"
      :value="modelValue"
      :class="{ error: isError }"
      class="base-select"
      @change="updateModelValue"
    >
      <slot />
    </select>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.wrapper {
  position: relative;
  &::after {
    content: url('../../../assets/img/arrow_down_blue.svg');
    pointer-events: none;
    position: absolute;
    inset: 2px 16px 0 auto;
    width: 14px;
    height: 16px;
    margin: auto;
  }
  &:has(*:disabled)::after {
    opacity: 0.3;
  }
}

.base-select {
  appearance: none;
  width: 100%;
  height: 40px;
  padding: 8px 40px 6px 16px;
  border: 1px solid $mochica_color_gray;
  border-radius: 4px;
  font-size: $font_14;
  line-height: 1.4;
  letter-spacing: 0.05em;
  &:disabled {
    background-color: #f9f9f9;
    color: $mochica_color_gray;
  }
  &.error {
    border: 1px $mochica_color_red solid;
  }
}
</style>
