<template>
  <div>
    <modal :is-view="isVisible">
      <template #content>
        <div class="content">
          <div class="body">
            <section class="modal-header">
              <div class="modal-header_inner">
                <div class="modal-header_ttl">
                  {{
                    isCreateMode === true || isCopyCreate === true
                      ? '連絡事項の作成'
                      : '連絡事項の編集'
                  }}
                </div>
              </div>
              <div
                class="modal-header_cancell"
                @click="$emit('onClickCancel', $event)"
              >
                <img src="@/assets/img/close_black.svg" alt />
              </div>
            </section>
            <section class="modal-body">
              <div class="selection-info">
                <template v-if="isCreateMode === true || isCopyCreate === true">
                  <div class="headlineA">編集メッセージ</div>
                  <select-schedule-time
                    ref="selectScheduleTime"
                    @updateScheduleTime="sendScheduleTime = $event"
                    @updateSendType="sendNoticeType = $event"
                  />
                </template>
                <template v-else>
                  <div class="selection-info_header_title">配信状況</div>
                  <select-schedule-time
                    v-if="noticeItems && noticeItems.schedule_time"
                    ref="selectScheduleTime"
                    :is-edit="true"
                    :initial-schedule-time="noticeItems.schedule_time"
                    :initial-is-active="noticeItems.is_active"
                    @updateScheduleTime="sendScheduleTime = $event"
                    @updateSendType="sendNoticeType = $event"
                    @updateIsActive="selectedIsActive = $event"
                  />
                </template>
                <p class="headlineA">メッセージ</p>
                <ul class="check-list">
                  <li>
                    <p class="rich_heading">連絡事項タイトル</p>
                    <div class="input_tr">
                      <input
                        v-model="noticeTitle"
                        type="text"
                        class="search_input"
                        :class="{ 'is-danger': hasErrorNoticeTitle }"
                        placeholder="入力してください"
                        maxlength="50"
                      />
                    </div>
                    <span v-show="hasErrorNoticeTitle" class="fa fa-warning">
                      連絡事項タイトルを入力してください
                    </span>
                  </li>
                  <li>
                    <p class="rich_heading">全員にメールで送信</p>
                    <div class="input_tr">
                      <input
                        id="forceEmail"
                        v-model="forceEmail"
                        type="checkbox"
                        class="setting_input"
                        @change="checkLineFriend"
                      />
                      <div>
                        <p>
                          <label for="forceEmail">
                            全員にメールで送信する（ファイル添付可）
                          </label>
                        </p>
                        <p class="checkbox-caption">
                          ※ チェックを入れるとメールのみで送信します。
                          <br />
                          ※ 連絡方法がLINEの応募者にもメールで送信されます。
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <p class="rich_heading">メッセージ確認</p>
                    <div class="input_tr">
                      <input
                        id="noticeCheck"
                        v-model="noticeCheck"
                        type="checkbox"
                        class="setting_input"
                      />
                      <div>
                        <p>
                          <label for="noticeCheck">
                            送信したメッセージを応募者が確認したかチェックする
                          </label>
                        </p>
                        <p class="checkbox-caption">
                          ※LINEの場合は応募者に確認ボタンが送信され、メールの場合は文面内に確認リンクが挿入されます。
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="selection-info_notice">
                  <div class="notice__content__btn">
                    <button-base
                      button-text="メッセージテンプレートを使う"
                      icon-file-name="icon_template"
                      :is-disabled="isFixPhraseBtnDisabled"
                      @click="openModalFixedPhraseList"
                    />
                  </div>
                  <div class="input_line_and_email">
                    <input-line-and-email
                      ref="refInputLineAndEmail"
                      :line-content="lineContent"
                      :line-content-limit="1000"
                      :email-title="emailTitle"
                      :email-title-limit="30"
                      :email-content="emailContent"
                      :email-content-limit="2000"
                      line-description="LINEで送信できなかった場合は、メールで送信されます。"
                      email-description=" "
                      :line-captions="[
                        '内容冒頭には宛先の｛姓・名｝さんが挿入されます。',
                      ]"
                      :email-captions="[
                        '内容冒頭には宛先の｛姓・名｝さんが挿入されます。',
                        '末尾には設定している署名が挿入されます。',
                      ]"
                      :files="files"
                      :is-disabled-line="forceEmail"
                      @updateLineContent="lineContent = $event"
                      @updateEmailTitle="emailTitle = $event"
                      @updateEmailContent="emailContent = $event"
                      @updateFiles="updateFiles"
                    />
                  </div>
                </div>
                <p class="headlineA">送信先応募者</p>
                <notice-applicant-preview
                  :is-visible-add-button="true"
                  :applicant-list="localSelectedApplicants"
                  :has-error-line-friend="hasErrorLineFriend"
                  @openApplicantsSelectModal="
                    isVisibleModalNoticeAnnounce = true
                  "
                  @unselectLineFriend="unselectLineFriend"
                />
              </div>
            </section>
            <div class="btn-col2">
              <template v-if="isCreateMode === true || isCopyCreate === true">
                <button
                  class="btn btn-unavailable"
                  @click="
                    $emit('onClickCancel', $event);
                    postPottosEvent(isSendNow ? 21 : 23);
                  "
                >
                  <span>キャンセル</span>
                </button>
                <button
                  v-if="isSendNow"
                  class="btn btn-availability"
                  @click="
                    submitSendNowNoticeAnnounce();
                    postPottosEvent(20);
                  "
                >
                  <span>今すぐ送信する</span>
                </button>
                <button-base
                  v-else
                  class="btn submit-button"
                  button-text="連絡事項を作成"
                  :is-loading="isLoading"
                  @click="
                    createNoticeAnnounce();
                    postPottosEvent(22);
                  "
                />
              </template>
              <template v-else>
                <button
                  class="btn btn-unavailable"
                  @click="$emit('onClickCancel', $event)"
                >
                  <span>キャンセル</span>
                </button>
                <button-base
                  class="btn submit-button"
                  button-text="連絡事項を保存"
                  :is-loading="isLoading"
                  @click="updateNoticeAnnounce"
                />
              </template>
            </div>
          </div>
        </div>
      </template>
    </modal>
    <modal-fixed-phrase-list
      :fixed-phrase-list="fixPhraseList"
      :is-view="isVisibleModalSelectFixPhraseList"
      @openFixedPhrase="openFixedPhrase"
      @onClickCloseModalSelectPhraseList="
        isVisibleModalSelectFixPhraseList = false
      "
    />
    <modal-select-fixed-phrase
      :fixed-phrase="fixedPhrase.data"
      :is-view="isVisibleModalSelectFixPhrase"
      @onClickSubmitModalSelectPhrase="onClickSubmitModalSelectPhrase"
      @onClickCloseModalSelectPhrase="onClickCloseModalSelectPhrase"
    />
    <modal-notice-selectable-applicants
      :send-limit="sendLimit"
      :init-selected-regist-applicants="selectedRegisterApplicantIds"
      :init-selected-line-friends="selectedLineFriendIds"
      :is-visible="isVisibleModalNoticeAnnounce"
      :is-disp-line-friend="!forceEmail"
      @onChangeCurrentSelect="onChangeCurrentSelect"
      @onClickSettle="onClickSettle"
      @onClickCancel="onClickCancelSelectionSearch"
    />
    <modal-window
      :is-visible="isVisibleModalSendNoticeAnnounce"
      :is-loading="isLoading"
      title="メッセージの送信"
      message="現在作成している内容で送信します。よろしいですか？"
      button-text="メッセージを送信"
      @click="sendNoticeAnnounce"
      @close="isVisibleModalSendNoticeAnnounce = false"
    />
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import { postPottosEvent } from '@/utils/pottos';
import applicantService from '@/services/applicants';
import NotificationService from '@/services/notifications';
import fixphraseService from '@/services/fixphrase';
import Applicant from '@/models/applicant';
import { getInitSearchFlagForRequest } from '@/models/applicant-search';
import Modal from '@/components/ui/modals/components/Modal';
import InputLineAndEmail from '@/components/features/inputLineAndEmail/components/InputLineAndEmail';
import SelectScheduleTime from '@/components/features/searchApplicantTab/components/SelectScheduleTime';
import NoticeApplicantPreview from '@/components/features/modalNoticeDetail/components/NoticeApplicantPreview';
import ModalFixedPhraseList from '@/components/features/fixedPhrase/components/ModalFixedPhraseList';
import ModalSelectFixedPhrase from '@/components/features/fixedPhrase/components/ModalSelectFixedPhrase';
import ModalNoticeSelectableApplicants from '@/components/features/modalNoticeDetail/components/ModalNoticeSelectableApplicants';

export default defineComponent({
  name: 'ModalEditNotice',
  components: {
    Modal,
    InputLineAndEmail,
    SelectScheduleTime,
    NoticeApplicantPreview,
    ModalNoticeSelectableApplicants,
    ModalFixedPhraseList,
    ModalSelectFixedPhrase,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    sendLimit: {
      type: Number,
      default: 100,
    },
    noticeItems: {
      type: Object,
      default: () => {},
    },
    selectedApplicants: {
      type: Array,
      default: () => [],
    },
    isCopyCreate: {
      type: Boolean,
      default: false,
    }, // メッセージ未確認応募者に新規連絡事項作成フラグ
  },
  emits: [
    'onClickCreateSubmit',
    'onClickUpdateSubmit',
    'onClickCloseEditModal',
    'onClickCancel',
  ],
  setup(props, context) {
    const store = useStore();
    const refInputLineAndEmail = ref(null);
    const isLoading = ref(false);
    const isFixPhraseBtnDisabled = ref(false);
    const isVisibleModalNoticeAnnounce = ref(false);
    const isVisibleModalSendNoticeAnnounce = ref(false);
    const isVisibleModalSelectFixPhraseList = ref(false);
    const isVisibleModalSelectFixPhrase = ref(false);
    const isVisibleDate = ref(false);
    const sendNoticeType = ref('send_reservation'); // 'send_reservation'|'send_now'
    const selectedIsActive = ref(false);
    const sendScheduleTime = ref('');
    const noticeTitle = ref('');
    const forceEmail = ref(false);
    const noticeCheck = ref(false);
    const hasErrorLineFriend = ref(false);
    const hasErrorNoticeTitle = ref(false);
    const emailTitle = ref('');
    const emailContent = ref('');
    const lineContent = ref('');
    const files = ref([]);
    const fixPhraseList = ref([]);
    const fixedPhrase = reactive({ data: {} });
    const localSelectedApplicants = ref([]);
    const selectedApplicantIds = ref([]);
    const selectedRegisterApplicantIds = ref([]);
    const selectedLineFriendIds = ref([]);
    const tmpSelectedRegisterApplicantsIds = ref([]);
    const tmpSelectedLineFriendIds = ref([]);

    // computed
    const isCreateMode = computed(() => {
      return !props.noticeItems.title;
    });
    const isSendNow = computed(() => {
      return sendNoticeType.value === 'send_now';
    });
    const isSelectedDateError = computed(() => {
      if (moment(sendScheduleTime.value).isAfter(moment().add(7, 'days'))) {
        return true;
      }
      if (moment(sendScheduleTime.value).isBefore(moment().add(10, 'min'))) {
        return true;
      }
      return false;
    });
    const sendApplicantLists = computed(() => {
      if (
        props.noticeItems &&
        props.noticeItems.applicant_notices &&
        props.noticeItems.applicant_notices[0] &&
        props.noticeItems.applicant_notices[0].applicant &&
        props.noticeItems.applicant_notices[0].applicant.id
      ) {
        return props.noticeItems.applicant_notices.map(app => app.applicant);
      }
      return [];
    });

    // watch
    watch(
      () => props.isVisible,
      async () => await resetLocal(),
    );
    watch(
      () => props.selectedApplicants,
      () => setSelectedApplicants(),
    );

    // lifecycle
    onMounted(() => setSelectedApplicants());

    // methods
    const resetLocal = async () => {
      noticeTitle.value = '';
      forceEmail.value = false;
      noticeCheck.value = false;
      emailTitle.value = '';
      emailContent.value = '';
      lineContent.value = '';
      files.value = [];
      sendNoticeType.value = 'send_reservation';
      localSelectedApplicants.value = [];
      selectedApplicantIds.value = [];
      selectedLineFriendIds.value = [];
      isLoading.value = false;
      hasErrorNoticeTitle.value = false;
      sendScheduleTime.value = '';
      if (props.noticeItems.id) {
        // 編集時のためデータ初期化
        noticeTitle.value = props.noticeItems.notice_title;
        selectedIsActive.value = props.noticeItems.is_active;
        forceEmail.value = props.noticeItems.force_email;
        noticeCheck.value = props.noticeItems.notice_check;
        emailTitle.value = props.noticeItems.title;
        emailContent.value = props.noticeItems.content_email;
        lineContent.value = props.noticeItems.content_line;
        files.value = props.noticeItems.files;
        sendScheduleTime.value = props.noticeItems.schedule_time;
      }
      // 応募者選択のデータ初期化
      if (props.isCopyCreate === true) {
        // 応募者選択後、連絡事項複製作成時
        selectedRegisterApplicantIds.value = Applicant.getRegisteredApplicants(
          localSelectedApplicants.value,
        ).map(applicant => applicant.id);
        selectedLineFriendIds.value = Applicant.getLineFriendApplicants(
          localSelectedApplicants.value,
        ).map(applicant => applicant.id);
        selectedApplicantIds.value = localSelectedApplicants.value.map(
          applicant => applicant.id,
        );
      } else {
        selectedRegisterApplicantIds.value = Applicant.getRegisteredApplicants(
          sendApplicantLists.value,
        ).map(applicant => applicant.id);
        selectedLineFriendIds.value = Applicant.getLineFriendApplicants(
          sendApplicantLists.value,
        ).map(applicant => applicant.id);
        const applicantIds = sendApplicantLists.value.map(
          applicant => applicant.id,
        );
        selectedApplicantIds.value = applicantIds;
        localSelectedApplicants.value = await fetchApplicants(applicantIds);
      }
      checkLineFriend();
    };
    const setSelectedApplicants = () => {
      const registeredApplicantIds = Applicant.getRegisteredApplicants(
        props.selectedApplicants,
      ).map(applicant => applicant.id);
      const lineApplicantIds = Applicant.getLineFriendApplicants(
        props.selectedApplicants,
      ).map(applicant => applicant.id);
      localSelectedApplicants.value = props.selectedApplicants;
      selectedApplicantIds.value = props.selectedApplicants.map(
        applicant => applicant.id,
      );
      selectedRegisterApplicantIds.value = registeredApplicantIds;
      tmpSelectedRegisterApplicantsIds.value = registeredApplicantIds;
      selectedLineFriendIds.value = lineApplicantIds;
      tmpSelectedLineFriendIds.value = lineApplicantIds;
    };

    // バリデーション関連
    const validate = async (isCreate = false) => {
      hasErrorNoticeTitle.value = false;
      const isValidatedInput = await refInputLineAndEmail.value.validateAll();
      if (
        (isCreate === true &&
          isSendNow.value === false &&
          isSelectedDateError.value === true) ||
        !sendScheduleTime.value ||
        isValidatedInput === false ||
        !noticeTitle.value
      ) {
        if (!noticeTitle.value) hasErrorNoticeTitle.value = true;
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '入力内容を確認して下さい',
          type: false,
        });
        return false;
      }
      if (hasErrorLineFriend.value === true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message:
            'LINE友達の場合はメールアドレスが登録されていないため、メールを送信できません',
          type: false,
        });
        return false;
      }
      const nowAfter5min = moment().add(5, 'minutes');
      const nowAfter7day = moment().add(7, 'days');
      if (
        isSendNow.value === false &&
        selectedIsActive.value === true &&
        (moment(sendScheduleTime.value).isBefore(moment(), 'day') ||
          moment(sendScheduleTime.value).isAfter(nowAfter7day, 'day'))
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '配信予約日時は、本日より7日以内の日付で設定して下さい',
          type: false,
        });
        return false;
      }
      if (
        isSendNow.value === false &&
        selectedIsActive.value === true &&
        moment(sendScheduleTime.value).isSameOrBefore(nowAfter5min)
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '配信予約日時は、配信時刻より5分以上空けて設定して下さい',
          type: false,
        });
        return false;
      }
      return true;
    };
    const checkLineFriend = () => {
      // メールのみ送信チェック時、LINE友達を選択しているとエラー
      hasErrorLineFriend.value =
        forceEmail.value === true &&
        Applicant.getLineFriendApplicants(localSelectedApplicants.value)
          .length > 0;
    };
    // 連絡事項新規作成・上書き・送信系
    const getPayload = () => {
      const payload = {
        schedule_time: sendScheduleTime.value,
        applicant_ids: selectedApplicantIds.value,
        notice_title: noticeTitle.value,
        title: emailTitle.value,
        content_email: emailContent.value,
        notice_check: noticeCheck.value,
        force_email: forceEmail.value,
      };
      if (forceEmail.value === true) {
        payload.files = files.value;
      } else {
        payload.content_line = lineContent.value;
      }
      return payload;
    };
    const createNoticeAnnounce = async () => {
      const isValid = await validate(true);
      if (isValid !== true) return;
      isLoading.value = true; // 二重ロック解除は、emit後、refsのresetLocalで実行される
      const payload = getPayload();
      const graduatedYear =
        await store.getters['graduateds/selectedGraduatedYear'];
      payload.graduated_year = graduatedYear.year;
      payload.staff_id = store.getters['staff/staff'].id;
      const res = await NotificationService.noticeAnnounceCreate(payload);
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success) context.emit('onClickCreateSubmit');
      isLoading.value = false;
    };
    const updateNoticeAnnounce = async () => {
      isLoading.value = true;
      const result = await validate();
      if (result !== true) {
        isLoading.value = false;
        return;
      }
      const payload = getPayload();
      payload.graduated_year = props.noticeItems.graduated_year;
      payload.notice_id = props.noticeItems.id;
      payload.is_active = selectedIsActive.value;
      payload.staff_id = props.noticeItems.staff_id;
      if (forceEmail.value === true) {
        payload.files = files.value;
      } else {
        payload.content_line = lineContent.value;
      }
      const res = await NotificationService.noticeAnnounceUpdate(payload);
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success === true) {
        context.emit('onClickUpdateSubmit');
        context.emit('onClickCloseEditModal');
      }
      isLoading.value = false;
    };
    const submitSendNowNoticeAnnounce = async () => {
      const result = await validate();
      if (result !== true) return;
      if (selectedApplicantIds.value.length === 0) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '応募者を選択して下さい',
          type: false,
        });
        return;
      }
      if (selectedApplicantIds.value.length > props.sendLimit) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `通知は${props.sendLimit}名以下に設定してください`,
          type: false,
        });
        return;
      }
      isVisibleModalSendNoticeAnnounce.value = true;
    };
    const sendNoticeAnnounce = async ({ selected }) => {
      if (selected !== 'submit') {
        isVisibleModalSendNoticeAnnounce.value = false;
        return;
      }
      const payload = getPayload();
      const graduatedYear =
        await store.getters['graduateds/selectedGraduatedYear'];
      payload.graduated_year = graduatedYear.year;
      payload.staff_id = store.getters['staff/staff'].id;
      payload.new_create = true;
      isLoading.value = true;
      const res = await NotificationService.noticeAnnounceSendJust(payload);
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success === true) context.emit('onClickCreateSubmit');
      isLoading.value = false;
      isVisibleModalSendNoticeAnnounce.value = false;
    };
    // テンプレート一覧モーダル関連
    const openModalFixedPhraseList = async () => {
      isFixPhraseBtnDisabled.value = true;
      const res = await fixphraseService.postFixPhraseList(
        store.getters['graduateds/selectedGraduatedId'],
      );
      if (res.success === true) fixPhraseList.value = res.fixphrase_list;
      isVisibleModalSelectFixPhraseList.value = true;
      isFixPhraseBtnDisabled.value = false;
    };
    const openFixedPhrase = async payload => {
      isVisibleModalSelectFixPhraseList.value = false;
      fixedPhrase.data = payload;
      isVisibleModalSelectFixPhrase.value = true;
    };
    // テンプレート選択モーダル関連
    const onClickCloseModalSelectPhrase = () => {
      isVisibleModalSelectFixPhrase.value = false;
      isVisibleModalSelectFixPhraseList.value = true;
    };
    const onClickSubmitModalSelectPhrase = payload => {
      emailTitle.value = emailTitle.value + payload.mail_title;
      emailContent.value = emailContent.value + payload.mail_content;
      lineContent.value = lineContent.value + payload.line_content;
      isVisibleModalSelectFixPhrase.value = false;
    };
    // 応募者選択モーダル関連
    const fetchApplicants = async applicantIds => {
      const graduatedYear =
        await store.getters['graduateds/selectedGraduatedYear'];
      const page = 1;
      const load = 2000;
      // ID指定応募者のみ検索
      const searchBody = { target_ids: applicantIds };
      // 応募者(LINE+メール) + LINE友達対象
      const searchFlags = {
        ...getInitSearchFlagForRequest(),
        is_all_applicants: true,
      };
      const res = await applicantService.fetchApplicantsSearch(
        graduatedYear.year,
        page,
        load,
        searchBody,
        searchFlags,
      );
      return res.applicants;
    };
    const onClickSettle = async () => {
      isVisibleModalNoticeAnnounce.value = false;
      const applicants = await fetchApplicants(selectedApplicantIds.value);
      tmpSelectedRegisterApplicantsIds.value =
        Applicant.getRegisteredApplicants(applicants).map(
          applicant => applicant.id,
        );
      tmpSelectedLineFriendIds.value = Applicant.getLineFriendApplicants(
        applicants,
      ).map(applicant => applicant.id);
      localSelectedApplicants.value = applicants;
    };
    const onChangeCurrentSelect = async payload => {
      selectedRegisterApplicantIds.value = payload.selectedRegistApplicantIds;
      selectedLineFriendIds.value = payload.selectedLineFriendIds;
      selectedApplicantIds.value = payload.selectedApplicantIds;
    };
    const onClickCancelSelectionSearch = () => {
      selectedRegisterApplicantIds.value =
        tmpSelectedRegisterApplicantsIds.value;
      selectedLineFriendIds.value = tmpSelectedLineFriendIds.value;
      isVisibleModalNoticeAnnounce.value = false;
    };
    const unselectLineFriend = () => {
      selectedLineFriendIds.value = [];
      tmpSelectedLineFriendIds.value = [];
      localSelectedApplicants.value = Applicant.getRegisteredApplicants(
        localSelectedApplicants.value,
      );
      selectedApplicantIds.value = localSelectedApplicants.value.map(
        applicant => applicant.id,
      );
      checkLineFriend();
    };
    // ファイル添付
    const updateFiles = val => {
      files.value = cloneDeep(val);
    };

    return {
      isLoading,
      refInputLineAndEmail,
      isFixPhraseBtnDisabled,
      isVisibleModalNoticeAnnounce,
      isVisibleModalSendNoticeAnnounce,
      isVisibleModalSelectFixPhraseList,
      isVisibleModalSelectFixPhrase,
      isVisibleDate,
      noticeTitle,
      forceEmail,
      noticeCheck,
      hasErrorLineFriend,
      hasErrorNoticeTitle,
      emailTitle,
      emailContent,
      lineContent,
      files,
      fixPhraseList,
      fixedPhrase,
      sendNoticeType,
      selectedIsActive,
      sendScheduleTime,
      localSelectedApplicants,
      selectedApplicantIds,
      selectedRegisterApplicantIds,
      selectedLineFriendIds,
      // computed
      isCreateMode,
      isSendNow,
      isSelectedDateError,
      // methods
      updateFiles,
      resetLocal,
      onClickSettle,
      openModalFixedPhraseList,
      openFixedPhrase,
      onClickCloseModalSelectPhrase,
      onClickSubmitModalSelectPhrase,
      onClickCancelSelectionSearch,
      onChangeCurrentSelect,
      unselectLineFriend,
      checkLineFriend,
      createNoticeAnnounce,
      updateNoticeAnnounce,
      submitSendNowNoticeAnnounce,
      sendNoticeAnnounce,
      postPottosEvent,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/table-normal.scss';
@include validation_effects;
@include common_table_style(
  $col_1: 180px,
  $col_1_ta: left,
  $col_2: 20%,
  $col_2_ta: left,
  $col_3: 20%,
  $col_3_ta: left,
  $col_4: 20%,
  $col_4_ta: left,
  $col_5: 5%,
  $col_5_ta: left,
  $col_6: 5%,
  $col_6_ta: left
);

input:focus.is-danger,
.is-danger {
  border: 1px $mochica_color_red solid;
}

.has-error {
  color: $mochica_color_red;
}

.rich_heading {
  font-size: 1.3rem;
  font-weight: bold;
  color: #1899d6;
  margin-bottom: 1.2rem;
  text-align: left;
}

.select-date-area_column {
  :deep(> div) {
    > input {
      padding-right: 15px;
    }
  }
  &.is-vertical_align_top {
    margin-top: 0;
    margin-bottom: auto;
  }
}

.input_tr {
  display: flex;
  margin-bottom: 30px;
  .input_block {
    display: block;
    margin-bottom: 5px;
  }

  > input {
    min-width: 1.4rem;
  }
  label {
    cursor: pointer;
  }
}

.search_input {
  display: block;
  width: 100%;
  max-width: 400px;
}

.fa-warning {
  display: block;
  margin-top: -26px;
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.setting_input {
  appearance: none;
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.5rem;
  margin-top: -2px;
  border-radius: 0.4rem;
  border: 2px solid #9d9d9d;
  background: #fff;
  position: relative;
  cursor: pointer;
  &:checked {
    &::after {
      content: url('../../../../assets/img/check.svg');
      height: 1.1rem;
      width: 1.5rem;
      margin: -5px 0 0 1px;
      position: absolute;
      bottom: 3px;
      left: 0;
    }
  }
}

.checkbox-caption {
  margin-top: 0.5rem;
  font-size: 1rem;
  line-height: 1.6;
  color: #777;
}

.check-list > li {
  margin: 24px 20px;
  border-bottom: 1px solid #e2e2e2;
  &:last-child {
    border-bottom: none;
  }
}

.headlineA {
  margin-bottom: 20px;
  padding: 15px 20px;
  color: #1899d6;
  font-size: 1.4rem;
  font-weight: bold;
  background-color: #f2f9fc;
}
.content {
  @include full_modal;
  overflow-y: auto;
  .modal-body {
    .selection-info {
      position: relative;
      padding: 10px;
      border-radius: 0.3rem;
      text-align: left;
      background-color: #fff;
      &_ttl {
        font-size: 1.3rem;
        position: relative;
        display: inline-block;
        padding: 10px;
        &::before {
          content: '';
          width: 100%;
          height: 0.1rem;
          display: block;
          background-color: $mochica_color;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      &_notice {
        .notice_block_confirm {
          display: flex;
          justify-content: flex-start;
          margin: 2rem 0;
          padding-bottom: 2rem;
          border-bottom: 1px solid #e3e3e3;
        }
        label.notice_title_confirm {
          width: 20%;
          display: inline-block;
          color: #88c6ed;
        }
        .notice_content_confirm {
          font-family: 'Noto Sans JP', sans-serif;
          line-height: 1.8;
          white-space: pre-wrap;
        }
      }
      &_header_title {
        background-color: #f2f9fc;
        padding: 15px 20px;
        font-size: 1.3rem;
        font-weight: bold;
        color: #1899d6;
        margin-bottom: 20px;
        display: flex;
      }
      &_header_title_send {
        background-color: #f2f9fc;
        padding: 10px 20px;
        .send_contnt_title {
          display: inline-block;
          color: #1899d6;
          font-size: 1.3rem;
          font-weight: bold;
        }
        .fixphrase_button {
          border: 1px solid #027db7;
          background: #1899d6;
          padding: 8px 10px;
          border-radius: 0.4rem;
          font-weight: bold;
          color: #fff;
          letter-spacing: 0.08em;
          align-items: center;
          cursor: pointer;
          transition: all ease 0.3s;
        }
      }
      &_header {
        padding: 0 20px 40px;
        .select-date-area {
          display: flex;
          align-items: center;
          .select-title {
            display: flex;
          }
          label[for='send_now'],
          label[for='send_reservation'] {
            padding-left: 10px;
            cursor: pointer;
          }
          &_column {
            margin-right: 10px;
            .error-area {
              margin-top: 5px;
            }
          }
        }
        .timeSetting {
          display: flex;
          margin: 10px 0 20px 25px;

          :deep(.el-input__inner) {
            height: 35px;
          }
          .select-h {
            border-radius: 2px;
            padding: 0.7rem;
            margin-right: 0.3rem;
            font-size: 1.2rem;
            transition: 0.3s ease;
            border-radius: 0.3rem;
            height: 35px;
          }
          .select-m {
            border-radius: 2px;
            padding: 0.7rem;
            margin-right: 0.3rem;
            font-size: 1.2rem;
            transition: 0.3s ease;
            border-radius: 0.3rem;
            height: 35px;
          }
          .value-input {
            border-radius: 0.3rem;
            padding: 0.8rem;
            font-size: 1.2rem;
            transition: 0.3s ease;
            margin-right: 1rem;
          }
        }
        .nowSend {
          margin-top: 20px;
        }
      }
    }
    & :deep(.area-table) {
      margin: 3rem 0 0;
      width: 100%;
      border: 1px solid $border_color;
      border-top: none;
      box-shadow: $box_shadow;
    }
  }
  .modal-body_btn_item.disabled {
    cursor: default;
  }
  .modal-body_btn_item.btn_custom {
    width: 100%;
  }
}

.notice__content__btn {
  margin: 0 20px 12px;
  padding: 10px;
  background-color: #f0f0f0;
  line-height: 19px;

  > button > svg {
    margin-right: 0.3rem;
    vertical-align: -4px;
    path {
      fill: #fff;
    }
  }
}

.input_line_and_email {
  margin: 0 20px 40px;
}

.notice__warp {
  display: flex;
  margin: 0 20px 40px;
  border: 3px solid #f0f0f0;
  border-top: none;
  .notice__content:nth-child(2) {
    border-left: 3px solid #f0f0f0;
  }
}

.notice__content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.notice__content-inner {
  width: 100%;
  padding: 20px 20px 0;
}

.mail_icon_svg {
  margin-right: 10px;
  height: 18px;
  width: 18px;
}

.mail_icon_svg_path {
  fill: #d28300;
}

.line_icon_svg {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}
.line_icon_svg_path {
  fill: #1dcd00;
}

.notice__header {
  width: 100%;
}

.notice_title {
  text-align: left;
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.notice__title.is_mail {
  margin: 0 0 15px;
  color: #d28300;
  font-weight: bold;
  font-size: 1.3rem;
}

.notice__title.is_line {
  margin: 0 0 15px;
  color: #43a047;
  font-weight: bold;
  font-size: 1.3rem;
}

.notice__description {
  margin-bottom: 20px;
}

.notice_block {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.notice_form-input {
  background: #fff;
  padding: 10px;
  border: 1px solid #90a4ae;
  border-radius: 3px;
  color: #333;
  &:focus {
    transition: 0.3s;
    border-color: $mochica-color;
  }
}

.notice_form-textarea {
  background: $white;
  padding: 10px;
  border: 1px solid #90a4ae;
  border-radius: 3px;
  color: #333;
  line-height: 1.8;
  min-height: 150px;
  &:focus {
    transition: 0.3s;
    border-color: $mochica-color;
  }
}

.notice_content_confirm-atena {
  margin-bottom: 10px;
}

.notice_content_confirm-sign {
  margin-top: 10px;
}

.is_required {
  &:after {
    content: '必須';
    font-weight: 100;
    color: #f4511e;
    font-size: 1.2rem;
    padding-left: 5px;
  }
}

.linechat_block_input-area {
  border-radius: 0px 0px 4px 4px;
  .linechat_block_input-area_header {
    .emoji-picker-wrapper {
      cursor: pointer;
      position: relative;
    }
  }
  .linechat_block_input-area_body {
    textarea {
      border-radius: 0.4rem;
      border: 1px solid;
      border-color: #808080;
      padding: 1em;
      font-size: 1.2rem;
      line-height: 1.6em;
      height: 10rem;
      background-color: #fff;
      margin-bottom: 1rem;
    }
    .btn-send-confirm {
      display: inline-block;
      width: 100%;
      height: 3.2rem;
      line-height: 30px;
      border-radius: 0.4rem;
      border-color: #027db7;
      background: #1899d6;
      text-align: center;
      font-size: 1.2rem;
      font-weight: bold;
      color: white;
      cursor: pointer;
    }
  }
}

img.breadcrumb {
  width: 0.6rem;
  margin: 0 0.8rem;
}

.notice_create_bottom {
  display: flex;
  justify-content: center;
}

.notice_create_bottom_btn {
  background-color: #efefef;
  width: 50%;
  cursor: pointer;
  height: 5rem;
  border-radius: 0.4rem;
  letter-spacing: 0.08em;
  margin-right: 2rem;
}

.notice_create_bottom_btn_sent_btn {
  background: #1899d6;
  color: #fff;
  font-weight: bold;
  width: 50%;
  cursor: pointer;
  height: 5rem;
  border-radius: 0.4rem;
  letter-spacing: 0.08em;
}

.btn-col2 {
  width: 90%;
  margin: 20px auto 0;
  .submit-button {
    padding: 15px 10px;
  }
}

@media (max-width: ($media_query_tablet)) {
  .notice__content__btn {
    margin: 0;
  }
  .notice__warp {
    margin: 0 0 40px;
  }
  .notice__content-inner {
    padding: 15px 15px 0;
  }
}

@media (max-width: ($media_query_sp)) {
  .content .modal-body .selection-info_header .timeSetting {
    flex-wrap: wrap;
    margin: 10px 0 20px;
  }
  .date-column {
    width: 100%;
  }
  .content .modal-body .selection-info_header {
    padding: 0 10px 40px;
  }
  .notice__warp {
    display: block;
  }
  .notice__warp .notice__content:nth-child(2) {
    border-top: 3px solid #f0f0f0;
    border-left: none;
  }
  .check-list > li {
    margin: 24px 5px;
  }

  .input_line_and_email {
    margin: 0 0 40px;
  }
}
</style>
