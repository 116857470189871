<template>
  <div class="form__wrapper">
    <div class="form__inner">
      <div class="form__inner__contents">
        <div class="smartphone">
          <div class="main-wrapper">
            <result-top
              v-if="mode === 'resultTop'"
              :company-name="companyName"
              @openResult="openResult"
            />
            <result-result
              v-if="mode === 'resultResult'"
              :result-data="result"
            />
            <div
              v-if="mode === 'errorResult' || mode === 'canceled'"
              class="error_contents"
            >
              {{ errorMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeMount } from 'vue';
import qs from 'qs';

import applicantRegisterService from '@/services/applicant-registration';
import ResultTop from '@/components/page/forms/mail_result/components/ResultTop';
import ResultResult from '@/components/page/forms/mail_result/components/ResultResult';

export default defineComponent({
  name: 'MailResult',
  components: { ResultTop, ResultResult },
  setup(props, context) {
    const token = ref(null);
    const mode = ref('resultTop');
    const result = ref(null);
    const companyName = ref('');
    const errorMessage = ref('このURLはすでに無効になっています');

    // methods
    const init = async token => {
      const res =
        await applicantRegisterService.getResultFromApplicantIdSelectionId(
          token,
        );
      if (res === null) {
        mode.value = 'errorResult';
        return;
      }
      if (res.finished_status.name === 'canceled') {
        // 応募者が辞退したら、そもそも結果を送る/見る必要がないので、「このURLはすでに無効になっています」を表示。
        mode.value = 'canceled';
        return;
      }
      result.value = res;
      companyName.value = res.applicant.company;
      mode.value = 'resultTop';
    };

    const openResult = async () => {
      const res = await applicantRegisterService.sendSelectionAnnounce(
        token.value,
      );
      if (res.success !== true) {
        errorMessage.value = res.message;
        mode.value = 'errorResult';
        return;
      }
      mode.value = 'resultResult';
    };

    // lifecycle
    onBeforeMount(async () => {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      token.value = query.token;
      await init(token.value);
    });

    return {
      mode,
      result,
      companyName,
      errorMessage,
      openResult,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include form_wrapper_css();

/* ここから元の記述 */
.smartphone {
  width: 100%;
}
.error_contents {
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;
}
</style>
