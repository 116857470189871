<template>
  <modal :is-view="isVisible">
    <template #content>
      <div class="content">
        <div class="body">
          <section class="modal-header">
            <div class="modal-header_inner">
              <div class="modal-header_ttl">
                応募者へ通知
                <img
                  class="breadcrumb"
                  src="@/assets/img/arrow_right_blue.svg"
                  alt
                />
                {{
                  flagSurveyDetailMode === flagSurveyMode.REGIST
                    ? 'アンケート新規登録'
                    : flagSurveyDetailMode === flagSurveyMode.EDIT
                      ? 'アンケート編集'
                      : 'アンケート送信'
                }}
              </div>
            </div>
            <div class="modal-header_cancell" @click="onClickCancel">
              <img class="icon" src="@/assets/img/close_black.svg" alt />
            </div>
          </section>
          <section class="flag_survey_area">
            <div
              v-if="flagSurveyDetailMode === flagSurveyMode.EDIT"
              class="btn btn-caution btn-delete"
              @click="isShowDeleteModal = true"
            >
              アンケートを削除
            </div>
            <div class="flag_survey_form flag_survey_flex">
              <p class="flag_survey_heading">アンケート名</p>
              <div class="input_tr">
                <input
                  v-model="questionTitle"
                  type="text"
                  maxlength="50"
                  placeholder="アンケート名を入力してください（50文字まで）"
                  class="flag_survey_input input_block"
                  :class="{
                    'is-danger':
                      flagSurveyDetailMode !== flagSurveyMode.DETAIL &&
                      !errors.questionTitle.isValid,
                  }"
                  :disabled="flagSurveyDetailMode === flagSurveyMode.DETAIL"
                />
                <span
                  v-show="
                    flagSurveyDetailMode !== flagSurveyMode.DETAIL &&
                    !errors.questionTitle.isValid
                  "
                  class="fa fa-warning font-s"
                >
                  {{ errors.questionTitle.message }}
                </span>
              </div>
              <div class="option_tr" />
            </div>
            <p class="headlineA">アンケート内容</p>
            <ul class="question_list">
              <flag-survey-question
                v-for="(question, i) in questions"
                ref="refQuestion"
                :key="question.id"
                :index="i + 1"
                :question-id="question.id"
                :question-title="question.questionTitle"
                :question-type="question.questionType"
                :question-multi="question.multi"
                :question-flag-group-id="question.flagGroupId"
                :question-tag-groups="question.tagGroups"
                :is-editable="flagSurveyDetailMode !== flagSurveyMode.DETAIL"
                :questions="questions"
                @update-question-title="updateQuestionTitle"
                @update-question-type="updateQuestionType"
                @update-multi="updateMulti"
                @update-tag-groups="updateTagGroups"
                @delete-question="deleteQuestion"
              />
            </ul>
            <button-base
              v-if="flagSurveyDetailMode !== flagSurveyMode.DETAIL"
              class="btn btn-add-question"
              button-text="質問文の追加"
              icon-file-name="add_circle_white"
              @click="addQuestion"
            />
            <ul class="footer_caption">
              <li>
                ※アンケート送信時点で各フラググループに設定されているフラグ情報が選択肢になります。
              </li>
              <li>※自由記述の質問では応募者は800文字まで回答できます。</li>
            </ul>
            <p class="headlineA">メッセージ内容</p>
            <div>
              <div class="notice__content__btn">
                <button
                  v-if="flagSurveyDetailMode !== flagSurveyMode.DETAIL"
                  class="btn btn-availability"
                  @click="openModalSelectFixPhraseList"
                >
                  テンプレートを使用
                </button>
              </div>
              <div class="notice__warp">
                <section class="notice__content">
                  <div class="notice__content-inner is_mail">
                    <header class="notice__header">
                      <h1 class="notice__title is_mail">
                        <img
                          class="mail_icon_svg"
                          src="@/assets/img/mail_gold.svg"
                        />
                        メールの応募者向け
                      </h1>
                      <div
                        v-if="flagSurveyDetailMode !== flagSurveyMode.DETAIL"
                        class="notice__description"
                      >
                        <p class="notice__description-text">
                          こちらに入力した内容は、連絡方法が
                          <span>メール</span>
                          の応募者へ届きます。
                        </p>
                      </div>
                    </header>
                    <div class="notice_block">
                      <label
                        class="notice_title"
                        :class="{
                          is_required:
                            flagSurveyDetailMode !== flagSurveyMode.DETAIL,
                        }"
                      >
                        メールタイトル
                      </label>
                      <input
                        v-model="mailTitle"
                        type="text"
                        maxlength="200"
                        placeholder="メールタイトル（200文字まで）"
                        class="notice_form-input mail-title"
                        :class="{
                          'is-danger':
                            flagSurveyDetailMode !== flagSurveyMode.DETAIL &&
                            !errors.mailTitle.isValid,
                        }"
                        :disabled="
                          flagSurveyDetailMode === flagSurveyMode.DETAIL
                        "
                      />
                      <span
                        v-if="flagSurveyDetailMode !== flagSurveyMode.DETAIL"
                        v-show="!errors.mailTitle.isValid"
                        class="fa fa-warning font-s"
                      >
                        {{ errors.mailTitle.message }}
                      </span>
                    </div>
                    <div class="notice_block">
                      <label
                        class="notice_title"
                        :class="{
                          is_required:
                            flagSurveyDetailMode !== flagSurveyMode.DETAIL,
                        }"
                      >
                        メール内容{{
                          flagSurveyDetailMode === flagSurveyMode.DETAIL
                            ? ''
                            : '(最大1000文字)'
                        }}
                      </label>
                      <textarea
                        v-model="mailContent"
                        maxlength="1000"
                        placeholder="内容（1000文字まで）"
                        class="notice_form-textarea"
                        :disabled="
                          flagSurveyDetailMode === flagSurveyMode.DETAIL
                        "
                        :class="{
                          'is-danger':
                            flagSurveyDetailMode !== flagSurveyMode.DETAIL &&
                            !errors.mailContent.isValid,
                        }"
                      />
                      <span
                        v-if="flagSurveyDetailMode !== flagSurveyMode.DETAIL"
                        v-show="!errors.mailContent.isValid"
                        class="fa fa-warning font-s"
                      >
                        {{ errors.mailContent.message }}
                      </span>
                      <div class="notice_caution">
                        <p class="notice_caution-text">
                          内容冒頭には宛先の｛姓・名｝さんが挿入されます。
                        </p>
                        <p class="notice_caution-text">
                          末尾には、設定している署名と、アンケートのURLが挿入されます。
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="notice__content">
                  <div class="notice__content-inner is_line">
                    <header class="notice__header">
                      <h1 class="notice__title is_line">
                        <img
                          class="line_icon_svg"
                          src="@/assets/img/line_green.svg"
                        />
                        LINEの応募者向け
                      </h1>
                      <div class="notice__description">
                        <p
                          v-if="flagSurveyDetailMode !== flagSurveyMode.DETAIL"
                          class="notice__description-text"
                        >
                          こちらに入力した内容は、連絡方法が
                          <span>LINE</span>
                          の応募者へ届きます。
                        </p>
                        <p class="notice__description-text">
                          LINEの場合はタイトルは含まれません。
                        </p>
                      </div>
                    </header>
                    <div class="notice_block linechat_block_input-area">
                      <label
                        class="notice_title"
                        :class="{
                          is_required:
                            flagSurveyDetailMode !== flagSurveyMode.DETAIL,
                        }"
                      >
                        LINEメッセージ{{
                          flagSurveyDetailMode === flagSurveyMode.DETAIL
                            ? ''
                            : '(最大800文字)'
                        }}
                      </label>
                      <div
                        v-if="flagSurveyDetailMode !== flagSurveyMode.DETAIL"
                        class="linechat_block_input-area_header"
                      >
                        <div class="emoji-picker-wrapper">
                          <emoji-picker
                            :is-outlined="true"
                            @selected="appendEmoji"
                          />
                        </div>
                      </div>
                      <textarea
                        v-model="lineContent"
                        maxlength="800"
                        placeholder="LINEメッセージ（800文字まで）"
                        class="notice_form-textarea form-line"
                        :class="{
                          'is-danger':
                            flagSurveyDetailMode !== flagSurveyMode.DETAIL &&
                            !errors.lineContent.isValid,
                        }"
                        :disabled="
                          flagSurveyDetailMode === flagSurveyMode.DETAIL
                        "
                      />
                      <span
                        v-if="flagSurveyDetailMode !== flagSurveyMode.DETAIL"
                        v-show="!errors.lineContent.isValid"
                        class="fa fa-warning font-s"
                      >
                        {{ errors.lineContent.message }}
                      </span>
                      <div class="notice_caution">
                        <p class="notice_caution-text">
                          内容冒頭には宛先の｛姓・名｝さんが挿入されます。
                        </p>
                        <p class="notice_caution-text">
                          末尾には、アンケートのURLが挿入されます。
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div class="btn-col2">
              <div class="btn btn-unavailable" @click="onClickCancel">
                キャンセル
              </div>
              <div
                v-if="flagSurveyDetailMode === flagSurveyMode.REGIST"
                class="btn btn-availability"
                @click="registerFlagSurvey"
              >
                アンケートを登録
              </div>
              <div
                v-else-if="flagSurveyDetailMode === flagSurveyMode.EDIT"
                class="btn btn-availability"
                @click="updateFlagSurvey"
              >
                アンケートを保存
              </div>
              <div
                v-else
                class="btn btn-availability"
                @click="onClickSubmitSendModal"
              >
                アンケートの送信先を選択
              </div>
            </div>
          </section>
        </div>
        <modal-dialog
          :is-view="isShowDeleteModal"
          :accent-color="'#C31E1E'"
          title="アンケートを削除します。よろしいでしょうか。"
          message="この操作は取り消すことができません。"
          :button-text1="'キャンセル'"
          :button-text2="'削除する'"
          :aleart-flag="true"
          @onSelected="onClickDeleteFlagSurvey"
          @onclickOuter="isShowDeleteModal = false"
        />
        <modal-fixed-phrase-list
          :fixed-phrase-list="fixPhraseList"
          :is-view="isShowModalSelectFixPhraseList"
          @openFixedPhrase="openFixedPhrase"
          @onClickCloseModalSelectPhraseList="
            isShowModalSelectFixPhraseList = false
          "
        />
        <modal-select-fixed-phrase
          :fixed-phrase="fixPhraseObj.data"
          :is-view="isShowModalSelectFixPhrase"
          @onClickSubmitModalSelectPhrase="onClickSubmitModalSelectPhrase"
          @onClickCloseModalSelectPhrase="onClickCloseModalSelectPhrase"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  watch,
} from 'vue';
import { useStore } from 'vuex';

import tagsService from '@/services/tags';
import flagsurveyService from '@/services/flag-survey';
import fixphraseService from '@/services/fixphrase';
import { generateUniqueId } from '@/utils/unique-id';
import useValidation from '@/composables/useValidation';
import Modal from '@/components/ui/modals/components/Modal';
import ModalDialog from '@/components/ui/modals/components/ModalDialog';
import EmojiPicker from '@/components/ui/menus/components/EmojiPicker';
import FlagSurveyQuestion from '@/components/features/flagSurveyQuestion/components/FlagSurveyQuestion';
import ModalFixedPhraseList from '@/components/features/fixedPhrase/components/ModalFixedPhraseList';
import ModalSelectFixedPhrase from '@/components/features/fixedPhrase/components/ModalSelectFixedPhrase';

export default defineComponent({
  name: 'FlagSurveyDetailModal',
  components: {
    Modal,
    ModalDialog,
    EmojiPicker,
    FlagSurveyQuestion,
    ModalFixedPhraseList,
    ModalSelectFixedPhrase,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    flagSurveyMode: {
      type: Object,
      required: true,
    },
    flagSurveyDetailMode: {
      type: Symbol,
      required: true,
    },
    flagSurveyId: {
      type: Number,
      default: null,
    },
  },
  emits: ['onReload', 'onClickCancel', 'onClickSubmitSendModal'],
  setup(props, context) {
    const { errors, resetErrors, validateEmoji, validateRequire } =
      useValidation([
        'questionTitle',
        'mailTitle',
        'mailContent',
        'lineContent',
      ]);
    const store = useStore();
    const QUESTION_LIMIT_COUNT = 10;
    const refQuestion = ref(null);
    const tagGroups = ref([]);
    const fixPhraseList = ref([]);
    const fixPhraseObj = reactive({ data: {} });
    const isShowDeleteModal = ref(false);
    const isShowModalSelectFixPhrase = ref(false);
    const isShowModalSelectFixPhraseList = ref(false);
    const questionTitle = ref('');
    const mailTitle = ref('');
    const questions = ref([]);
    const mailContent = ref('');
    const lineContent = ref('');

    // computed
    const selectedGraduatedId = computed(
      () => store.getters['graduateds/selectedGraduatedId'],
    );

    // watch
    watch(
      () => props.isVisible,
      async val => {
        if (!val) return;
        if (
          !props.flagSurveyId ||
          props.flagSurveyDetailMode === props.flagSurveyMode.REGIST
        ) {
          // 新規作成画面の場合
          init();
          return;
        }
        // 詳細・編集作成画面の場合
        const res = await flagsurveyService.getFlagSurvey({
          flagsurvey_id: props.flagSurveyId,
        });
        questionTitle.value = res.survey.title;
        mailTitle.value = res.survey.mail_title;
        mailContent.value = res.survey.mail_content;
        lineContent.value = res.survey.line_content;
        questions.value = res.survey.flagsurvey_questions.map(x => ({
          id: `local_${generateUniqueId()}`,
          questionTitle: x.question_title,
          questionType: x.question_type ? x.question_type : 1,
          multi: x.multi,
          flagGroupId: x.flag_group_id,
          tagGroups: tagGroups.value.filter(y => !y.selected),
        }));
      },
    );

    // methods
    const init = async () => {
      resetErrors();
      const res = await tagsService.fetchTagGroups();
      tagGroups.value = res.reduce((result, group) => {
        if (group.tags.length > 0) {
          result.push({ id: group.id, name: group.name, selected: false });
        }
        return result;
      }, []);
      questionTitle.value = '';
      questions.value = [
        {
          id: `local_${generateUniqueId()}`,
          questionTitle: '',
          questionType: 1,
          flagGroupId: null,
          tagGroups: tagGroups.value.map(x => Object.assign({}, x)),
        },
      ];
      mailTitle.value = '';
      mailContent.value = '';
      lineContent.value = '';
      isShowDeleteModal.value = false;
      isShowModalSelectFixPhrase.value = false;
      isShowModalSelectFixPhraseList.value = false;
    };
    const registerFlagSurvey = async () => {
      const valid = validate();
      const questionValidList = refQuestion.value.map(v => v.validate());
      if (valid.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: valid.message,
          type: false,
        });
        return;
      }
      if (questionValidList.some(v => v.success !== true)) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: questionValidList.find(v => v.success !== true).message,
          type: false,
        });
        return;
      }
      const res = await flagsurveyService.createFlagSurvey(
        createFlagSurveySaveRequest(),
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success !== true) return;
      setTimeout(context.emit('onReload'), 1000);
      init();
    };
    const updateFlagSurvey = async () => {
      const valid = validate();
      const questionValidList = refQuestion.value.map(v => v.validate());
      if (valid.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: valid.message,
          type: false,
        });
        return;
      }
      if (questionValidList.some(v => v.success !== true)) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: questionValidList.find(v => v.success !== true).message,
          type: false,
        });
        return;
      }
      const flagSurveySaveObj = createFlagSurveySaveRequest();
      flagSurveySaveObj.flagsurvey_id = props.flagSurveyId;
      const res = await flagsurveyService.updateFlagSurvey(flagSurveySaveObj);
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success !== true) return;
      context.emit('onReload');
      init();
    };
    const deleteFlagSurvey = async () => {
      const res = await flagsurveyService.deleteFlagSurvey({
        flagsurvey_id: props.flagSurveyId,
      });
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success !== true) return;
      context.emit('onReload');
      init();
    };
    const getFixPhraseList = async graduatedId => {
      const res = await fixphraseService.postFixPhraseList(graduatedId);
      if (res.success === true) fixPhraseList.value = res.fixphrase_list;
    };
    const openModalSelectFixPhraseList = async () => {
      await getFixPhraseList(selectedGraduatedId.value);
      isShowModalSelectFixPhraseList.value = true;
    };
    const onClickDeleteFlagSurvey = async ({ choice }) => {
      isShowDeleteModal.value = false;
      // キャンセル以外の場合に削除
      if (choice !== 1) await deleteFlagSurvey();
    };
    const validate = () => {
      resetErrors();
      let isValid = true;
      if (
        validateRequire('questionTitle', questionTitle.value) !== true ||
        validateEmoji('questionTitle', questionTitle.value) !== true
      ) {
        isValid = false;
      }
      if (validateRequire('mailTitle', mailTitle.value) !== true) {
        isValid = false;
      }
      if (validateRequire('mailContent', mailContent.value) !== true) {
        isValid = false;
      }
      if (validateRequire('lineContent', lineContent.value) !== true) {
        isValid = false;
      }
      if (isValid !== true) {
        return { success: false, message: '入力内容を確認してください' };
      }
      return { success: true, message: '' };
    };
    const addQuestion = () => {
      if (questions.value.length >= QUESTION_LIMIT_COUNT) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `作成できる質問は${QUESTION_LIMIT_COUNT}個までです`,
          type: false,
        });
        return;
      }
      const newQuestion = {
        id: `local_${generateUniqueId()}`,
        questionTitle: '',
        questionType: 1,
        multi: false,
        flagGroupId: null,
        tagGroups: tagGroups.value.filter(x => x.selected !== true),
      };
      if (tagGroups.value.filter(x => x.selected !== true).length === 0) {
        // タググループを全て選択した場合は自由記述がデフォルトとなる
        newQuestion.questionType = 2;
      }
      questions.value.push(newQuestion);
    };
    const deleteQuestion = payload => {
      questions.value = questions.value.filter(
        x => x.id !== payload.questionId,
      );
      updateTagGroups(payload);
    };
    const updateQuestionTitle = payload => {
      questions.value = questions.value.map(x => {
        if (x.id === payload.questionId) {
          x.questionTitle = payload.questionTitle;
        }
        return x;
      });
    };
    const updateQuestionType = payload => {
      questions.value = questions.value.map(x => {
        if (x.id === payload.questionId) {
          if (payload.questionType === 2) x.multi = false;
          x.questionType = payload.questionType;
        }
        return x;
      });
    };
    const updateMulti = payload => {
      questions.value = questions.value.map(x => {
        if (x.id === payload.questionId) x.multi = payload.multi;
        return x;
      });
    };
    const updateTagGroups = payload => {
      // 対象QuestionのflagGroupIdを更新（削除の際は無視される）
      questions.value = questions.value.map(x => {
        if (x.id === payload.questionId)
          x.flagGroupId = payload.questionFlagGroupId;
        return x;
      });
      // tagGroupsのselectedをQuestionのflagGroupIdから更新
      tagGroups.value = tagGroups.value.map(x => {
        const selectedTagGroups = questions.value.map(y => y.flagGroupId);
        x.selected = selectedTagGroups.includes(x.id);
        return x;
      });
      // Question毎のtagGroupsを更新
      questions.value = questions.value.map(x => {
        x.tagGroups = tagGroups.value.filter(
          y => y.id === x.flagGroupId || !y.selected,
        );
        return x;
      });
    };
    const appendEmoji = emoji => {
      const textArea = document.querySelector(
        '.notice_form-textarea.form-line',
      );
      const beforeText = textArea.value.substring(0, textArea.selectionStart);
      const afterText = textArea.value.substring(
        textArea.selectionStart,
        textArea.value.length,
      );
      lineContent.value = `${beforeText}${emoji}${afterText}`;
    };
    const openFixedPhrase = payload => {
      isShowModalSelectFixPhraseList.value = false;
      fixPhraseObj.data = payload;
      isShowModalSelectFixPhrase.value = true;
    };
    const onClickCloseModalSelectPhrase = () => {
      isShowModalSelectFixPhrase.value = false;
      isShowModalSelectFixPhraseList.value = true;
    };
    const onClickSubmitModalSelectPhrase = () => {
      const insertObj = fixPhraseObj.data;
      mailTitle.value = mailTitle.value + insertObj.mail_title;
      mailContent.value = mailContent.value + insertObj.mail_content;
      lineContent.value = lineContent.value + insertObj.line_content;
      isShowModalSelectFixPhrase.value = false;
    };
    const onClickCancel = () => {
      context.emit('onClickCancel');
      init();
    };
    const onClickSubmitSendModal = () => {
      context.emit('onClickSubmitSendModal');
      init();
    };
    const createFlagSurveySaveRequest = () => {
      return {
        title: questionTitle.value,
        questions: questions.value.map(x => ({
          question_title: x.questionTitle,
          question_type: x.questionType,
          multi: x.multi,
          flag_group_id: x.flagGroupId,
        })),
        mail_title: mailTitle.value,
        mail_content: mailContent.value,
        line_content: lineContent.value,
      };
    };

    onBeforeMount(async () => await init());

    return {
      refQuestion,
      errors,
      tagGroups,
      fixPhraseList,
      fixPhraseObj,
      isShowDeleteModal,
      isShowModalSelectFixPhrase,
      isShowModalSelectFixPhraseList,
      questionTitle,
      mailTitle,
      mailContent,
      lineContent,
      questions,
      selectedGraduatedId,
      registerFlagSurvey,
      updateFlagSurvey,
      addQuestion,
      deleteQuestion,
      updateQuestionTitle,
      updateQuestionType,
      updateMulti,
      updateTagGroups,
      appendEmoji,
      onClickCloseModalSelectPhrase,
      onClickSubmitModalSelectPhrase,
      onClickCancel,
      onClickSubmitSendModal,
      createFlagSurveySaveRequest,
      onClickDeleteFlagSurvey,
      openFixedPhrase,
      openModalSelectFixPhraseList,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content {
  @include full_modal;
  overflow-y: auto;
  .modal-body {
    padding-bottom: 40px;
    // text-align: start;
    & :deep(.area-table) {
      margin: 2rem 0 0;
    }
  }
  .modal-body_btn_item.disabled {
    cursor: default;
  }
}

.body {
  padding: 0 20px;
}

img.breadcrumb {
  width: 0.6rem;
  margin: 0 0.8rem;
}

.flag_survey_area {
  max-width: 1200px;
  padding: 30px;
  margin: 80px auto 0;
  text-align: left;
  background-color: #fff;
}

.btn-delete {
  margin-bottom: 40px;
}

.question_list {
  margin-bottom: 30px;
  border-bottom: 1px solid #f0f0f0;
}

.flag_survey_form {
  text-align: left;
}

.flag_survey_flex {
  display: flex;
  padding-bottom: 30px;
}

.flag_survey_heading {
  min-width: 152px;
  max-width: 152px;
  font-size: 1.3rem;
  font-weight: bold;
  color: #1899d6;
  padding: 0.8rem 0 0 0;
  margin-right: 10px;
  text-align: left;
}

.input_tr {
  width: 100%;
  max-width: 750px;
  margin-right: auto;
  margin-bottom: -5px;
  .input_block {
    display: block;
    margin-bottom: 5px;
  }
}

.option_tr {
  min-width: 110px;
  max-width: 110px;
  height: 36px;
  margin-left: 20px;
}

.flag_survey_input {
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  color: #333;
  font-family: 'Noto Sans JP', sans-serif;
  &:disabled {
    -webkit-text-fill-color: #333;
    opacity: 1;
  }
}

.footer_caption {
  margin: 20px 0 40px;
  > li {
    margin-bottom: 7px;
  }
}

.btn-col2 {
  max-width: 1200px;
  margin: 0 auto;
}

.btn-add-question {
  width: auto;
  &.is-disabled {
    opacity: 0.6;
    cursor: default;
    &:hover {
      background-color: #16b2d9;
    }
  }
}

.headlineA {
  margin-bottom: 20px;
  padding: 15px 20px;
  color: #1899d6;
  font-size: 1.4rem;
  font-weight: bold;
  background-color: #f2f9fc;
}

.notice__content__btn {
  min-height: 3px;
  margin: 0 20px;
  background-color: #f0f0f0;
  > button {
    margin: 10px;
  }
}

.notice__warp {
  display: flex;
  margin: 0 20px 40px;
  border: 3px solid #f0f0f0;
  border-top: none;
  .notice__content:nth-child(2) {
    border-left: 3px solid #f0f0f0;
  }
}

.notice__content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.notice__content-inner {
  width: 100%;
  padding: 20px 20px 0;
}

.mail_icon_svg {
  margin-right: 10px;
  height: 18px;
  width: 18px;
}

.mail_icon_svg_path {
  fill: #d28300;
}

.line_icon_svg {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}
.line_icon_svg_path {
  fill: #1dcd00;
}

.notice__header {
  width: 100%;
}

.notice_title {
  text-align: left;
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.notice__title.is_mail {
  margin: 0 0 15px;
  color: #d28300;
  font-weight: bold;
  font-size: 1.3rem;
}

.notice__title.is_line {
  margin: 0 0 15px;
  color: #43a047;
  font-weight: bold;
  font-size: 1.3rem;
}

.notice__description {
  margin-bottom: 20px;
}

.notice__description-text {
  line-height: 1.4;
  span {
    font-weight: bold;
    padding: 3px 5px;
  }
}

.notice_block {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.notice_form-input {
  background: #fff;
  padding: 10px;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  color: #333;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-appearance: none;
  &:focus {
    transition: 0.3s;
    border-color: $mochica-color;
  }
  &:disabled {
    -webkit-text-fill-color: #333;
    opacity: 1;
  }
}

.notice_form-textarea {
  background: $white;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  color: #333;
  font-size: 1.4rem;
  line-height: 1.4;
  padding: 0.8rem;
  min-height: 150px;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-appearance: none;
  &:focus {
    transition: 0.3s;
    border-color: $mochica-color;
  }
  &:disabled {
    -webkit-text-fill-color: #333;
    opacity: 1;
  }
}

.notice_caution {
  margin-top: 10px;
}

.notice_caution-text {
  margin-bottom: 10px;
  &:before {
    content: '※';
    padding-right: 5px;
  }
}

.is_required {
  &:after {
    content: '必須';
    font-weight: 100;
    color: #f4511e;
    font-size: 1.2rem;
    padding-left: 5px;
  }
}

.linechat_block_input-area {
  border-radius: 0px 0px 4px 4px;
  .emoji-picker-wrapper {
    margin-bottom: 7px;
  }
  .linechat_block_input-area_body {
    textarea {
      border-radius: 0.4rem;
      border: 1px solid;
      border-color: #808080;
      font-size: 1.4rem;
      line-height: 1.4;
      padding: 0.8rem;
      height: 10rem;
      background-color: #fff;
      margin-bottom: 1rem;
    }
  }
}

@media (max-width: ($media_query_tablet)) {
  .notice__content__btn {
    margin: 0;
  }
  .notice__warp {
    margin: 0 0 40px;
  }
  .notice__content-inner {
    padding: 15px 15px 0;
  }
}

@media (max-width: ($media_query_sp)) {
  .flag_survey_area {
    padding: 20px;
  }
  .notice__warp {
    display: block;
  }
  .notice__warp .notice__content:nth-child(2) {
    border-top: 3px solid #f0f0f0;
    border-left: none;
  }
}

@media (max-width: 830px) {
  .flag_survey_flex {
    display: block;
    padding-bottom: 20px;
  }

  .flag_survey_form {
    margin-bottom: 20px;
  }

  .input_tr {
    margin-bottom: 10px;
  }

  .option_tr {
    min-height: 0;
    height: 100%;
    margin: 0;
  }

  .flag_survey_heading {
    padding: 0 0 1rem 0;
    margin-right: 0;
  }

  .btn-add-question {
    width: 100%;
  }
}
.warning-bottom {
  margin-bottom: 10px;
}
@include validation_effects3;
</style>
