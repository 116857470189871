<template>
  <div class="admin-edit-body">
    <div class="admin-edit">
      <div class="admin-input_list">
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">アカウント 有効／無効</label>
          <label class="switch">
            <input
              v-model="isDeactive"
              type="checkbox"
              class="admin-input_list-row-edit"
              name="company-deactive"
              @click="comDeactive = !comDeactive"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">
            ファイルアップロード 有効／無効
          </label>
          <label class="switchFileUpload">
            <input
              v-model="isDeactiveFileUpload"
              type="checkbox"
              class="admin-input_list-row-edit"
              name="company-deactive-fileupload"
              @click="comDeactiveFileUpload = !comDeactiveFileUpload"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">
            アカウント納品 済み／未
          </label>
          <label class="switchIssue">
            <input
              v-model="isDeactiveIssue"
              type="checkbox"
              class="admin-input_list-row-edit"
              name="company-deactive-issue"
              @click="comDeactiveIssue != comDeactiveIssue"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">アカウント納品日</label>
          <label class="switchIssue">
            <el-date-picker
              :model-value="comIssueAt"
              class="admin-input_list-row-edit"
              type="date"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
              placeholder
              name="issue_at"
              @update:model-value="comIssueAt = $event"
            />
          </label>
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">
            ストレージサイズ（GB）
            <span class="red">※</span>
          </label>
          <input
            v-model="comStorage"
            class="admin-input_list-row-edit"
            :class="{ 'is-danger': errors.comStorage.isValid !== true }"
            type="number"
            name="company-storage"
            min="1"
            :max="maxStorageSize"
            @input="
              resetError('comStorage');
              validateStorage('comStorage', comStorage, 0, maxStorageSize);
            "
          />
          <span
            v-if="errors.comStorage.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.comStorage.message }}
          </span>
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">
            会社名
            <span class="red">※</span>
          </label>
          <input
            v-model="comName"
            class="admin-input_list-row-edit"
            :class="{ 'is-danger': errors.comName.isValid !== true }"
            maxlength="64"
            @input="validateOnInput('comName', comName, validateRequire)"
          />
          <span
            v-if="errors.comName.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.comName.message }}
          </span>
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">
            会社メールアドレス
            <span class="red">※</span>
          </label>
          <input
            v-model="comEmail"
            class="admin-input_list-row-edit"
            :class="{ 'is-danger': errors.comEmail.isValid !== true }"
            maxlength="254"
            @input="validateOnInput('comEmail', comEmail, validateEmail)"
          />
          <span
            v-if="errors.comEmail.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.comEmail.message }}
          </span>
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">
            MOCHICAでのメール受信機能
          </label>
          <label class="switchEmailFlag">
            <input
              v-model="isDeactiveEmailFlag"
              type="checkbox"
              class="admin-input_list-row-edit"
              name="company-deactive-emailflag"
              @click="comDeactiveEmailFlag = !comDeactiveEmailFlag"
            />
            <span class="slider round"></span>
          </label>
          <label class="admin-input_list-row-subitem">
            ※応募者への個別メッセージ、連絡事項送信時に、ハッシュメールアドレスを使用する
          </label>
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">会社郵便番号</label>
          <input
            v-model="comZipcode"
            class="admin-input_list-row-edit"
            :class="{ 'is-danger': errors.comZipcode.isValid !== true }"
            maxlength="7"
            @input="
              validateOnInput('comZipcode', comZipcode, validateZipcode, false)
            "
          />
          <span
            v-if="errors.comZipcode.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.comZipcode.message }}
          </span>
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">会社住所</label>
          <input
            v-model="comAddress"
            class="admin-input_list-row-edit"
            maxlength="50"
          />
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">会社電話番号</label>
          <input
            v-model="comTel"
            class="admin-input_list-row-edit"
            :class="{ 'is-danger': errors.comTel.isValid !== true }"
            maxlength="11"
            @input="validateOnInput('comTel', comTel, validateTel, false)"
          />
          <span
            v-if="errors.comTel.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.comTel.message }}
          </span>
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">
            LINE チャンネルシークレット
          </label>
          <input
            v-model="comSecret"
            class="admin-input_list-row-edit"
            maxlength="32"
          />
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">LINE アクセストークン</label>
          <input
            v-model="comToken"
            class="admin-input_list-row-edit"
            maxlength="172"
          />
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">LINE ID</label>
          <input
            v-model="comLineId"
            class="admin-input_list-row-edit"
            maxlength="16"
          />
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">LINE QR</label>
          <input
            v-model="comLineQr"
            class="admin-input_list-row-edit"
            maxlength="255"
          />
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">LINE友達URL</label>
          <input
            v-model="comLineUrl"
            class="admin-input_list-row-edit"
            :class="{ 'is-danger': errors.comLineUrl.isValid !== true }"
            maxlength="150"
            @input="
              validateOnInput('comLineUrl', comLineUrl, validateUrl, false)
            "
          />
          <span
            v-if="errors.comLineUrl.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.comLineUrl.message }}
          </span>
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">LINE Webhook</label>
          <input
            v-model="comLineWebhook"
            class="admin-input_list-row-edit"
            maxlength="9"
          />
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">
            MOCHICA 管理者（姓）
            <span class="red">※</span>
          </label>
          <input
            v-model="comStaffLastname"
            class="admin-input_list-row-edit"
            :class="{ 'is-danger': errors.comStaffLastname.isValid !== true }"
            maxlength="64"
            @input="
              validateOnInput(
                'comStaffLastname',
                comStaffLastname,
                validateRequire,
              )
            "
          />
          <span
            v-if="errors.comStaffLastname.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.comStaffLastname.message }}
          </span>
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">
            MOCHICA 管理者（名）
            <span class="red">※</span>
          </label>
          <input
            v-model="comStaffFirstname"
            class="admin-input_list-row-edit"
            :class="{ 'is-danger': errors.comStaffFirstname.isValid !== true }"
            maxlength="64"
            @input="
              validateOnInput(
                'comStaffFirstname',
                comStaffFirstname,
                validateRequire,
              )
            "
          />
          <span
            v-if="errors.comStaffFirstname.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.comStaffFirstname.message }}
          </span>
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">管理者 電話番号</label>
          <input
            v-model="comStaffTel"
            class="admin-input_list-row-edit"
            :class="{ 'is-danger': errors.comStaffTel.isValid !== true }"
            maxlength="11"
            @input="
              validateOnInput('comStaffTel', comStaffTel, validateTel, false)
            "
          />
          <span
            v-if="errors.comStaffTel.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.comStaffTel.message }}
          </span>
        </div>
        <button-base
          class="admin-input_list-cancel-btn"
          button-text="キャンセル"
          :is-outlined="true"
          @click="$emit('cancelCompany')"
        />
        <button-base
          class="admin-input_list-submit-btn"
          button-text="企業情報を変更する"
          :is-loading="isLoading"
          @click="editCompany"
        />
      </div>
    </div>
    <div class="other-edit-column">
      <div class="staff-edit">
        <admin-list-view-staffs
          :staffs="staffs"
          :is-admin="true"
          :company-id="companyId"
          class="staffs-member_list"
          @commitedEmailChange="commitedEmailChange"
          @commitedRoleChange="commitedRoleChange"
          @onClickSettleDeleteStaff="onClickSettleDeleteStaff"
        />
      </div>
      <div class="plan-edit">
        <admin-edit-plan
          :company-id="companyId"
          :plan-id="comPlanId"
          :company-name="comName"
          :applicants-count="applicantsCount"
          @onSelectedPlanId="selectedPlanData.data = $event"
          @onClickOpenDeleToApplicantsModal="onClickOpenDeleToApplicantsModal"
          @onClickDownloadApplicantsCsv="downloadDeleteToApplicantsCsv"
          @onClickOpenModalSettingPlan="onClickOpenModalSettingPlan"
        />
      </div>
      <div v-if="comPlanId == 3 && staff.super_admin" class="delete-applicants">
        <admin-delete-all-applicants
          :company-id="companyId"
          @onClickOpenModalDeleteApplicantsConfirm="
            isVisibleDeleteApplicantsConfirm = true
          "
        />
      </div>
    </div>
    <modal-delete-to-applicants-list
      :is-view="isVisibleDeleteToApplicantList"
      :delete-to-applicants-ids="deleteToApplicantsIds"
      @onClickCancel="isVisibleDeleteToApplicantList = false"
      @onClickDownloadApplicantsCsv="downloadDeleteToApplicantsCsv"
    />
    <modal-window
      :is-visible="isVisibleSettingPlanConfirm"
      :is-new-design="true"
      :message="`プランを ${
        selectedPlanData.data !== null ? selectedPlanData.data.name_ja : ''
      } に変更します`"
      max-width="570px"
      title="プラン変更の確認"
      :caution-note="`※変更後のプランで上限人数を超えた分の応募者は削除されます。\n\n※応募者に選考が割り当てられていた場合、削除後も目標対比の集計対象に含まれます。`"
      modal-type="danger"
      button-text="変更内容を確認"
      @close="isVisibleSettingPlanConfirm = false"
      @click="onClickSettingPlanConfirm"
    />
    <modal-window
      :is-visible="isVisibleSettingPlan"
      :is-loading="isLoading"
      :is-new-design="true"
      max-width="570px"
      title="プラン変更"
      message="変更ボタンを押すとプランの変更が実行されます"
      :caution-note="`※変更後のプランで上限人数を超えた分の応募者は削除されます。\n\n※応募者に選考が割り当てられていた場合、削除後も目標対比の集計対象に含まれます。`"
      modal-type="danger"
      button-text="プランを変更"
      @close="isVisibleSettingPlan = false"
      @click="onClickSettingPlan"
    />
    <modal-window
      :is-visible="isVisibleDeleteApplicantsConfirm"
      :is-loading="isLoading"
      title="応募者の一括削除"
      message="応募者が全て削除されます"
      modal-type="danger"
      button-text="応募者を一括削除"
      @close="isVisibleDeleteApplicantsConfirm = false"
      @click="onClickDeleteApplicants"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

import storages from '@/components/page/admin/defines/storages';
import adminService from '@/services/admin';
import useValidation from '@/composables/useValidation';
import AdminListViewStaffs from '@/components/page/admin/components/AdminListViewStaffs';
import ModalDeleteToApplicantsList from '@/components/page/admin/components/ModalDeleteToApplicantsList';
import AdminEditPlan from '@/components/page/admin/components/AdminEditPlan.vue';
import AdminDeleteAllApplicants from '@/components/page/admin/components/AdminDeleteAllApplicants.vue';

import Staff from '@/models/staff';

export default defineComponent({
  name: 'AdminEditCompany',
  components: {
    AdminListViewStaffs,
    AdminEditPlan,
    ModalDeleteToApplicantsList,
    AdminDeleteAllApplicants,
  },
  props: {
    companyId: {
      type: Number,
      required: true,
    },
    staff: {
      type: Object,
      required: true,
    },
  },
  emits: ['cancelCompany', 'successEdit'],
  setup(props, context) {
    const {
      errors,
      resetError,
      resetErrors,
      validateEmail,
      validateUrl,
      validateOnInput,
      validateRequire,
      validateStorage,
      validateTel,
      validateZipcode,
    } = useValidation([
      'comName',
      'comEmail',
      'comZipcode',
      'comTel',
      'comStaffLastname',
      'comStaffFirstname',
      'comStaffTel',
      'comStorage',
      'comLineUrl',
    ]);
    const store = useStore();
    const comDeactive = ref(false);
    const comDeactiveFileUpload = ref(false);
    const comDeactiveIssue = ref(false);
    const comDeactiveEmailFlag = ref(false);
    const comIssueAt = ref(null);
    const comName = ref('');
    const comEmail = ref('');
    const comTel = ref('');
    const comZipcode = ref('');
    const comAddress = ref('');
    const comSecret = ref('');
    const comToken = ref('');
    const comLineId = ref('');
    const comLineQr = ref('');
    const comLineUrl = ref('');
    const comLineWebhook = ref('');
    const comStaffLastname = ref('');
    const comStaffFirstname = ref('');
    const comStaffTel = ref('');
    const comPlanId = ref(null);
    const applicantsCount = ref(0);
    const staffs = ref([]);
    const deleteToApplicantsIds = ref([]);
    const selectedPlanData = reactive({ data: null });
    const isLoading = ref(false);
    const isVisibleDeleteToApplicantList = ref(false);
    const isVisibleSettingPlan = ref(false);
    const isVisibleSettingPlanConfirm = ref(false);
    const isVisibleDeleteApplicantsConfirm = ref(false);
    const comStorage = ref(storages.DEFAULT_SIZE);

    // computed
    const maxStorageSize = computed(() => storages.MAX_SIZE);
    const isDeactive = computed({
      get: () => comDeactive.value,
      set: newVal => (comDeactive.value = newVal),
    });
    const isDeactiveFileUpload = computed({
      get: () => comDeactiveFileUpload.value,
      set: newVal => (comDeactiveFileUpload.value = newVal),
    });
    const isDeactiveIssue = computed({
      get: () => comDeactiveIssue.value,
      set: newVal => (comDeactiveIssue.value = newVal),
    });
    const isDeactiveEmailFlag = computed({
      get: () => comDeactiveEmailFlag.value,
      set: newVal => (comDeactiveEmailFlag.value = newVal),
    });
    const validateAll = () => {
      resetErrors();
      let isValid = true;
      if (!validateRequire('comName', comName.value)) isValid = false;
      if (!validateEmail('comEmail', comEmail.value)) isValid = false;
      if (!validateZipcode('comZipcode', comZipcode.value, false)) {
        isValid = false;
      }
      if (!validateTel('comTel', comTel.value, false)) isValid = false;
      if (!validateUrl('comLineUrl', comLineUrl.value, false)) {
        isValid = false;
      }
      if (!validateRequire('comStaffLastname', comStaffLastname.value)) {
        isValid = false;
      }
      if (!validateRequire('comStaffFirstname', comStaffFirstname.value)) {
        isValid = false;
      }
      if (!validateTel('comStaffTel', comStaffTel.value, false)) {
        isValid = false;
      }
      if (!validateRequire('comStorage', comStorage.value)) {
        isValid = false;
      }
      if (
        !validateStorage(
          'comStorage',
          comStorage.value,
          0,
          maxStorageSize.value,
        )
      ) {
        isValid = false;
      }
      return isValid;
    };
    const editCompany = async () => {
      if (validateAll() !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          type: false,
          message: '入力内容を確認して下さい',
        });
        return;
      }
      isLoading.value = true;
      const res = await adminService.updateCompany(
        props.companyId,
        comDeactive.value,
        comDeactiveFileUpload.value,
        comDeactiveIssue.value,
        comDeactiveEmailFlag.value,
        comIssueAt.value
          ? `${moment(comIssueAt.value).format('YYYY-MM-DD')} 09:00:00`
          : null,
        comStorage.value,
        comEmail.value,
        comToken.value,
        comName.value,
        comTel.value,
        comZipcode.value,
        comAddress.value,
        comSecret.value,
        comLineId.value,
        comLineQr.value,
        comLineUrl.value,
        comLineWebhook.value,
        comStaffLastname.value,
        comStaffFirstname.value,
        comStaffTel.value,
      );
      isLoading.value = false;
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        type: res.success,
        message: res.message,
      });
      if (res.success === true) context.emit('successEdit');
    };
    const getCompanyData = async payload => {
      const res = await adminService.getCompany(payload);
      if (res.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '企業の読み込みに失敗しました。リロードしてください。',
          type: false,
        });
        return;
      }
      comDeactive.value = res.company.deactive;
      comDeactiveFileUpload.value = res.company.fileupload;
      comDeactiveIssue.value = res.company.issue;
      comDeactiveEmailFlag.value = res.company.email_flag;
      comIssueAt.value = res.company.issue_at;
      comStorage.value = res.company.storagesize;
      comName.value = res.company.name;
      comEmail.value = res.company.email;
      comZipcode.value = res.company.zipcode;
      comAddress.value = res.company.address;
      comTel.value = res.company.tel;
      comSecret.value = res.company.line_channel_secret;
      comToken.value = res.company.line_access_token;
      comLineId.value = res.company.line_id;
      comLineQr.value = res.company.qr;
      comLineUrl.value = res.company.line_url;
      comLineWebhook.value = res.company.webhook;
      comPlanId.value = res.company.plan_id;
      applicantsCount.value = res.company.applicants_count;
      comStaffLastname.value = res.staff.lastname;
      comStaffFirstname.value = res.staff.firstname;
      comStaffTel.value = res.staff.tel;
    };
    const commitedEmailChange = async payload => {
      const res = await adminService.updateStaffEmail({
        staff_id: payload.staff_id,
        email: payload.staff_email,
        company_id: props.companyId,
      });
      fetchStaffsData();
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
    };
    const commitedRoleChange = async payload => {
      const res = await adminService.updateStaffRole({
        staff_id: payload.staff_id,
        role: Number(payload.role),
        company_id: props.companyId,
      });
      fetchStaffsData();
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
    };
    const fetchStaffsData = async () => {
      const res = await adminService.fetchStaffsByCompanyId({
        company_id: props.companyId,
      });
      staffs.value = res.staffs.map(Staff.createFromJSON);
    };
    // 削除予定の応募者IDを取得
    const fetchDeleToApplicantsId = async planId => {
      const res = await adminService.fetchDeleToApplicantsId({
        company_id: props.companyId,
        selected_plan_id: planId,
      });
      if (!res.success) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
        return false;
      }
      deleteToApplicantsIds.value = res.applicant;
      return res;
    };
    // 削除予定応募者のcsvダウンロード
    const downloadDeleteToApplicantsCsv = async () => {
      const res = await adminService.downloadDeleteToApplicantsCsv({
        company_id: props.companyId,
        selected_plan_id: selectedPlanData.data.id,
      });
      let blob;
      const csvName = 'mochica.csv';
      const csv = [res];
      const type = { type: 'text/csv; charset=Shift-JIS' };
      const link = document.createElement('a');
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      if (window.navigator.msSaveOrOpenBlob) {
        // for ie
        blob = new Blob([bom, csv], type);
        window.navigator.msSaveOrOpenBlob(blob, csvName);
      } else if (window.webkitURL && window.webkitURL.createObjectURL) {
        // for chrome (and safari)
        blob = new Blob([bom, csv], type);
        link.setAttribute('download', csvName);
        link.setAttribute('href', window.webkitURL.createObjectURL(blob));
        link.click();
        window.URL.revokeObjectURL(link.href);
      } else if (window.URL && window.URL.createObjectURL) {
        // for firefox
        blob = new Blob([bom, csv], type);
        link.setAttribute('download', csvName);
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.click();
        window.URL.revokeObjectURL(link.href);
      }
    };
    const onClickSettleDeleteStaff = async payload => {
      const res = await adminService.deleteStaff({
        staff_id: payload.staff_id,
        company_id: props.companyId,
      });
      fetchStaffsData();
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
    };
    // 削除予定応募者一覧モーダル
    const onClickOpenDeleToApplicantsModal = async () => {
      await fetchDeleToApplicantsId(selectedPlanData.data.id);
      isVisibleDeleteToApplicantList.value = true;
    };
    // プラン変更
    const onClickOpenModalSettingPlan = (planId = null) => {
      if (planId === null) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: 'プランを選択してください',
          type: false,
        });
        return;
      }
      isVisibleSettingPlanConfirm.value = true;
    };
    const onClickDeleteApplicants = async ({ selected }) => {
      if (selected === 'submit') {
        isLoading.value = true;
        const res = await adminService.deleteApplicants({
          company_id: props.companyId,
        });
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
        isVisibleDeleteApplicantsConfirm.value = false;
        await getCompanyData(props.companyId);
        isLoading.value = false;
      }
      isVisibleDeleteApplicantsConfirm.value = false;
    };
    const onClickSettingPlanConfirm = ({ selected }) => {
      if (selected === 'submit') isVisibleSettingPlan.value = true;
      isVisibleSettingPlanConfirm.value = false;
    };
    const onClickSettingPlan = async ({ selected }) => {
      if (selected === 'submit') {
        // スタンダードプラン以外はcsvをダウンロードする
        if (
          applicantsCount.value >= selectedPlanData.data.applicant_limit &&
          selectedPlanData.data.plan_id !== 2
        ) {
          downloadDeleteToApplicantsCsv();
        }
        isLoading.value = true;
        const res = await adminService.changePlan({
          company_id: props.companyId,
          selected_plan_id: selectedPlanData.data.id,
        });
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
        await getCompanyData(props.companyId);
        isLoading.value = false;
      }
      closeSettingPlanModal();
    };
    const closeSettingPlanModal = () => {
      isVisibleSettingPlan.value = false;
      isVisibleSettingPlanConfirm.value = false;
    };

    onBeforeMount(async () => {
      await fetchStaffsData();
      await getCompanyData(props.companyId);
    });

    return {
      errors,
      comDeactive,
      comDeactiveFileUpload,
      comDeactiveIssue,
      comDeactiveEmailFlag,
      comIssueAt,
      comName,
      comEmail,
      comTel,
      comZipcode,
      comAddress,
      comSecret,
      comToken,
      comLineId,
      comLineQr,
      comLineUrl,
      comLineWebhook,
      comStaffLastname,
      comStaffFirstname,
      comStaffTel,
      comPlanId,
      applicantsCount,
      isLoading,
      staffs,
      deleteToApplicantsIds,
      selectedPlanData,
      isVisibleDeleteToApplicantList,
      isVisibleSettingPlan,
      isVisibleSettingPlanConfirm,
      isVisibleDeleteApplicantsConfirm,
      comStorage,
      maxStorageSize,
      isDeactive,
      isDeactiveFileUpload,
      isDeactiveIssue,
      isDeactiveEmailFlag,
      // methods
      editCompany,
      commitedEmailChange,
      commitedRoleChange,
      downloadDeleteToApplicantsCsv,
      onClickSettleDeleteStaff,
      onClickOpenDeleToApplicantsModal,
      onClickOpenModalSettingPlan,
      onClickDeleteApplicants,
      onClickSettingPlanConfirm,
      onClickSettingPlan,
      closeSettingPlanModal,
      resetError,
      validateEmail,
      validateUrl,
      validateOnInput,
      validateRequire,
      validateStorage,
      validateTel,
      validateZipcode,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
@import '@/assets/datepicker.scss';

.admin-edit-body {
  display: flex;
  width: 100%;
}

.admin-edit {
  @include flex_center();
  align-items: flex-start;
  padding: 2rem;
  line-height: 1.5;
  width: 320px;
  min-width: 500px;
}
.admin-input_list {
  background: #fff;
  padding: 20px;
  width: 50rem;
  margin-right: 2rem;
}
.admin-input_list-row {
  margin-bottom: 1.2rem;
  padding-bottom: 1.2rem;
  &:not(:last-child) {
    border-bottom: 1px solid #f1f1f1;
  }
}
.admin-input_list-row-item {
  display: block;
  font-weight: bold;
  // color: $mochica_color;
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
}
.admin-input_list-row-subitem {
  display: block;
  font-size: 1.2rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}
.admin-input_list-row-edit {
  display: block;
  border: 1px solid $border_color;
  padding: 0.4rem;
  width: 20rem;
}
.admin-input_list-submit-btn {
  width: 100%;
  height: 48px;
  margin-top: 24px;
  border-radius: 24px !important;
}
.admin-input_list-cancel-btn {
  width: 100%;
  height: 48px;
  margin-top: 24px;
  border-radius: 24px !important;
}
.admin-input_regist-view {
  @extend .admin-input_list;
  background: #eaeaea;
  margin-right: 0;
}

.regist-block__title {
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 0.6rem;

  &:nth-of-type(3) {
    margin-top: 2.4rem;
  }
}
.regist-block__list {
  padding: 2rem;
  background: #fff;
  border-radius: 0.4rem;
}
.regist-block__list-item {
  margin-bottom: 0.8rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #f1f1f1;

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

input:focus {
  border: 1px green solid;
}
input:focus.is-danger,
.is-danger {
  border: 1px $mochica_color_red solid;
}
.fa-warning {
  color: $mochica_color_red;
  padding-top: 4px;
  font-size: 1.2rem;
}
span.red {
  color: #f00;
}

input[type='text'].el-input__inner {
  border: none;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
  input {
    display: none;
    &:checked + .slider {
      background-color: $mochica_color_red;
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(-26px);
      -ms-transform: translateX(-26px);
      transform: translateX(-26px);
    }
    &:focus + .slider {
      box-shadow: 0 0 1px $mochica_color_red;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $mochica_color;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 30px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    &.round {
      border-radius: 34px;
      &:before {
        border-radius: 50%;
      }
    }
  }
}
.switchFileUpload {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
  input {
    display: none;
    &:checked + .slider {
      background-color: $mochica_color;
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
    &:focus + .slider {
      box-shadow: 0 0 1px $mochica_color_red;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $mochica_color_red;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    &.round {
      border-radius: 34px;
      &:before {
        border-radius: 50%;
      }
    }
  }
}
.switchIssue {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
  input {
    display: none;
    &:checked + .slider {
      background-color: $mochica_color;
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
    &:focus + .slider {
      box-shadow: 0 0 1px $mochica_color_red;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $mochica_color_red;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    &.round {
      border-radius: 34px;
      &:before {
        border-radius: 50%;
      }
    }
  }
}
.switchEmailFlag {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
  input {
    display: none;
    &:checked + .slider {
      background-color: $mochica_color;
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
    &:focus + .slider {
      box-shadow: 0 0 1px $mochica_color_red;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $mochica_color_red;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    &.round {
      border-radius: 34px;
      &:before {
        border-radius: 50%;
      }
    }
  }
}
.other-edit-column {
  padding: 2rem;
}
.other-edit-area {
  display: flex;
  flex-direction: column;
}
.plan-edit {
  background: #fff;
  padding: 2rem;
  line-height: 1.5;
  // max-width: 560px;
  height: fit-content;
}
.delete-applicants {
  margin-top: 2rem;
  background: #fff;
  padding: 2rem;
  line-height: 1.5;
  // max-width: 560px;
  height: fit-content;
}
.staff-edit {
  flex: 1;
  background: #fff;
  padding: 2rem;
  line-height: 1.5;
  margin-bottom: 2rem;
  // max-width: 560px;
  height: fit-content;
}
.staffs-member_list {
  white-space: nowrap;
  overflow: auto;
}

:deep(.el-input) {
  width: 20rem;
}
</style>
