<template>
  <transition name="modal">
    <div v-if="isVisible" class="overlay" @click="handleOuterClick">
      <button
        v-if="isVisibleCloseButton"
        class="close-button"
        @click.stop
        @click="$emit('close')"
      >
        <img src="@/assets/img/close_white.svg" />
      </button>
      <div class="wrapper" :style="styles" @click.stop>
        <div
          v-if="title"
          class="header"
          :class="generateHeaderClass(modalType)"
        >
          {{ title }}
        </div>
        <div class="content">
          <slot v-if="$slots.content" name="content" />
          <p v-else-if="message" class="message">
            {{ message }}
            <span v-if="cautionNote" class="caution-note">
              {{ cautionNote }}
            </span>
          </p>
        </div>
        <div
          v-if="message && !$slots.content"
          class="footer"
          :class="{ 'is-new-design': isNewDesign }"
        >
          <button-base
            :button-text="buttonTextSecondary"
            button-type="secondary"
            @click="$emit('click', { selected: 'cancel', data })"
          />
          <button-base
            :button-text="buttonText"
            :button-type="generateBtnTypeText(modalType)"
            :is-disabled="isDisabled"
            :is-loading="isLoading"
            @click="$emit('click', { selected: 'submit', data })"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent, watch } from 'vue';
const definedModalType = ['primary', 'danger'];

export default defineComponent({
  name: 'ModalWindow',
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    cautionNote: {
      type: String,
      default: '',
    },
    modalType: {
      type: String,
      default: 'primary',
    },
    maxWidth: {
      type: String,
      default: '500px',
    },
    maxHeight: {
      type: String,
      default: 'none',
    },
    isVisibleCloseButton: {
      type: Boolean,
      default: true,
    },
    buttonTextSecondary: {
      type: String,
      default: 'キャンセル',
    },
    buttonText: {
      type: String,
      default: 'はい',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    // TODO: すべてのモーダルでボタン周囲の余白を新しいデザインに変更し終えたらこの props を削除して標準のスタイルに変更する
    isNewDesign: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'click', 'clickOuter'],
  setup(props, context) {
    if (
      (props.message && context.slots.content) ||
      (!props.message && !context.slots.content)
    ) {
      console.error('slot または props.message のいずれかを使用してください');
    }

    const styles = {
      maxWidth: props.maxWidth,
      maxHeight: props.maxHeight,
    };

    let storedScrollTop = 0;
    watch(
      () => props.isVisible,
      async val => {
        const bodyEl = document.querySelector('body');

        if (val) {
          storedScrollTop = window.pageYOffset;
          bodyEl.classList.add('-noscroll');
          bodyEl.style.top = `-${storedScrollTop}px`;
        } else {
          bodyEl.classList.remove('-noscroll');
          bodyEl.style.removeProperty('top');
          window.scrollTo(0, storedScrollTop);
        }
      },
    );

    const generateHeaderClass = modalType => {
      return definedModalType.includes(modalType)
        ? `modal-${modalType}`
        : 'modal-primary';
    };

    const generateBtnTypeText = modalType => {
      return definedModalType.includes(modalType) ? modalType : 'primary';
    };

    const handleOuterClick = () => {
      context.emit('clickOuter');
    };

    return {
      styles,
      generateHeaderClass,
      generateBtnTypeText,
      handleOuterClick,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.overlay {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(112, 112, 112, 0.95);
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
}

.close-button {
  width: 20px;
  height: 20px;
  position: fixed;
  top: 26px;
  right: 34px;
  cursor: pointer;
}

.wrapper {
  background-color: $white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  line-height: 1.5;
  border-radius: 6px;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  min-width: 500px;
  width: 100%;

  .header {
    text-align: center;
    padding: 20px 20px;
    font-size: 1.4rem;
    font-weight: bold;

    &.modal-primary {
      border-bottom: 1px solid $mochica_color;
      color: $mochica_color;
    }
    &.modal-danger {
      border-bottom: 1px solid $mochica_color_red;
      color: $mochica_color_red;
    }
  }

  .content {
    overflow-y: auto;

    .message {
      padding: 50px 20px 40px;
      line-height: 1.5;
      font-size: 1.4rem;
      text-align: center;

      .caution-note {
        display: inline-block;
        white-space: pre-line;
        margin-top: 15px;
        font-size: 1.1rem;
        color: #777777;
      }
    }
  }

  .footer {
    padding: 0 10px 10px;
    margin-top: 10px;

    // すべてのモーダルでボタン周囲の余白を新しいデザインに変更し終えたらこの style を標準のスタイルに変更する
    &.is-new-design {
      padding: 0 24px 24px;
    }

    button {
      width: calc(50% - 5px);
      padding: 15px 10px;
    }

    button + button {
      margin: 0 0 0 10px;
    }
  }
}

// フェードイン・フェードアウトアニメーション
.modal-enter-from {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}

@media (max-width: ($media_query_tablet)) {
  .close-button {
    top: 15px;
    right: 15px;
  }
}

@media (max-width: ($media_query_sp)) {
  .overlay {
    padding: 30px;
  }
  .close-button {
    top: 10px;
    right: 10px;
  }

  .wrapper {
    min-width: 100%;
  }

  .footer {
    display: flex;
    flex-direction: column-reverse;

    button {
      width: 100% !important;
    }

    button + button {
      margin: 0 0 10px !important;
    }
  }
}
</style>
