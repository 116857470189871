<template>
  <div ref="areaTable" class="area-table">
    <table :class="{ 'table-scrollable': isScrollable }">
      <!-- 重複チェックの場合このコンポーネントの最初のみヘッダーを表示する -->
      <thead
        :class="{
          'is-hide': isDoubleCheck === true && applicants.length === 0,
        }"
      >
        <tr>
          <th
            v-for="(col, iCol) in filteredHeader"
            :key="iCol"
            :ref="refCols[col]"
            :style="[getColumnStyle(col.name, 18)]"
            :class="[col.name, { sortable: col.sort_by && isSortable }]"
          >
            <div
              v-if="col.sort_by && isSortable"
              @click="onClickHeader(col, iCol)"
            >
              <span>{{ col.name_jp }}</span>
              <div class="area-icon-sortable">
                <span
                  v-if="headerSortBy === col.sort_by"
                  :class="{ selected: headerSortBy === col.sort_by }"
                  class="icon-sortable"
                >
                  <span v-if="headerSortOrder !== true">
                    <img
                      class="icon-sortable-svg-up"
                      src="@/assets/img/rect_arrow_down_blue.svg"
                      style="transform: rotate(180deg)"
                      alt
                    />
                  </span>
                  <span v-else>
                    <img
                      class="icon-sortable-svg-down"
                      src="@/assets/img/rect_arrow_down_blue.svg"
                      alt
                    />
                  </span>
                </span>
                <span v-else class="icon-sortable">
                  <img
                    class="icon-sortable-svg-up"
                    src="@/assets/img/rect_arrow_down_gray.svg"
                    alt
                  />
                </span>
              </div>
            </div>
            <div v-else>
              <div v-if="col.name === 'isSelect'">
                <input
                  ref="refSelectAll"
                  type="checkbox"
                  name="select_head"
                  value="1"
                  @change="onClickCheckAll"
                />
              </div>
              <div v-else>
                <span>{{ col.name_jp }}</span>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="applicant in applicants"
          :key="applicant.id"
          :class="{
            'can-move-detail': canMoveDetail,
            'is-disabled': applicant.isDisabled,
            highlight: highlightApplicantIds.includes(applicant.id),
          }"
        >
          <td
            v-for="(col, iCol) in filteredHeader"
            :key="iCol"
            :class="[
              col.name,
              { 'red-bold': col.name === 'tel' && isDoubleCheck === true },
            ]"
            :style="[getColumnStyle(col.name, 16)]"
            @click="onClickColumn(applicant, col)"
          >
            <div v-if="col.filter">
              <template v-if="col.filter === 'unreadLineCount'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                  class="has-unread-line"
                >
                  {{ applicant.unreadLineCount }}件
                </span>
              </template>
              <template v-if="col.filter === 'unreadMailCount'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                  class="has-unread-mail"
                >
                  {{ applicant.unreadMailCount }}件
                </span>
              </template>
              <template v-if="col.filter === 'currentStatus'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                  :class="
                    applicant.status.progress.name === null
                      ? 'not_set_selection'
                      : applicant.status.progress.name
                  "
                  class="status_default"
                >
                  {{
                    $utils.getValueFromNestedObject(applicant, relation) ===
                    null
                      ? '選考未割当て'
                      : $utils.getValueFromNestedObject(applicant, relation)
                  }}
                </span>
              </template>
              <template v-if="col.filter === 'invitedStatus'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                >
                  {{
                    $utils.getValueFromNestedObject(applicant, relation)
                      ? '済'
                      : '未'
                  }}
                </span>
              </template>
              <template v-if="col.filter === 'momentYmdNoSpace'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                >
                  {{
                    $utils.parseDateTime(
                      $utils.getValueFromNestedObject(applicant, relation),
                      'YYYY/MM/DD',
                    )
                  }}
                </span>
              </template>
              <template v-if="col.filter === 'count'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                  :class="{
                    'has-unread': $utils.getValueFromNestedObject(
                      applicant,
                      relation,
                    ),
                  }"
                >
                  {{
                    $utils.getValueFromNestedObject(applicant, relation) !==
                    null
                      ? `${$utils.getValueFromNestedObject(
                          applicant,
                          relation,
                        )}件`
                      : '-'
                  }}
                </span>
              </template>
              <template v-if="col.filter === 'contactTypeStatus'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                  class="contact_type_status"
                >
                  <span
                    v-if="
                      $utils.getValueFromNestedObject(applicant, relation) === 1
                    "
                    class="contact_mail"
                  >
                    {{ 'メール' }}
                  </span>
                  <span
                    v-if="
                      $utils.getValueFromNestedObject(applicant, relation) === 2
                    "
                    class="contact_line"
                  >
                    {{ 'LINE' }}
                  </span>
                  <span
                    v-if="
                      $utils.getValueFromNestedObject(applicant, relation) === 3
                    "
                    class="contact_block"
                  >
                    {{ 'ブロック中' }}
                  </span>
                </span>
              </template>
              <template v-if="col.filter === 'attendanceAt'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                >
                  {{
                    $utils.parseDateTime(
                      $utils.getValueFromNestedObject(applicant, relation),
                      'YYYY年M月D日',
                    )
                  }}
                </span>
              </template>
              <template v-if="col.filter === 'reminderCheck'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                >
                  {{ applicant.status.progress.reminder_check ? '済み' : '' }}
                </span>
              </template>
              <template v-if="col.filter === 'sendStatusDescription'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                >
                  {{
                    applicant.status.progress.name === 'announced'
                      ? '送信済み'
                      : applicant.status.progress.send_status_description
                  }}
                </span>
              </template>
              <template v-if="col.filter === 'sendedAt'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                >
                  {{
                    $utils.parseDateTime(
                      applicant.status.progress.sended_at,
                      'YYYY年M月D日',
                    )
                  }}
                </span>
              </template>
              <template v-if="col.filter === 'finishedSendStatusDescription'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                >
                  {{
                    applicant.status.progress.finished_send_status_description
                  }}
                </span>
              </template>
              <template v-if="col.filter === 'finishedSendedAt'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                >
                  {{
                    $utils.parseDateTime(
                      applicant.status.progress.finished_sended_at,
                      'YYYY年M月D日',
                    )
                  }}
                </span>
              </template>
              <template v-if="col.filter === 'announceCheck'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                >
                  {{ applicant.status.progress.announce_check ? '済み' : '未' }}
                </span>
              </template>
              <template v-if="col.filter === 'resultCheck'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                >
                  {{ applicant.status.progress.result_check ? '済み' : '未' }}
                </span>
              </template>
              <template v-if="col.filter === 'richmessageCheck'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                >
                  {{ applicant.richmessageCheck ? '済み' : '未' }}
                </span>
              </template>
              <template v-if="col.filter === 'flagsurveyCheck'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                >
                  {{ applicant.flagsurveyCheck ? '済み' : '未' }}
                </span>
              </template>
              <template v-if="col.filter === 'applicantName'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                  :class="{
                    name_color_red: applicant.nameColorRed
                      ? applicant.nameColorRed
                      : false,
                  }"
                >
                  {{ $utils.getValueFromNestedObject(applicant, relation) }}
                </span>
              </template>
              <template v-if="col.filter === 'applicantTel'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                  :class="{
                    tel_color_red: applicant.telColorRed
                      ? applicant.telColorRed
                      : false,
                  }"
                >
                  {{ $utils.getValueFromNestedObject(applicant, relation) }}
                </span>
              </template>
              <template v-if="col.filter === 'messageCreatedAt'">
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                >
                  {{
                    $utils.parseDateTime(
                      applicant.messageCreatedAt,
                      'YYYY/MM/DD HH:mm',
                    )
                  }}
                </span>
              </template>
            </div>
            <template v-else>
              <span
                v-for="(relation, iRelation) in col.relation"
                :key="iRelation"
              >
                <template v-if="col.name === 'not_announe_lamp'">
                  {{
                    applicant.not_announe_lamp === true &&
                    (applicant.status.progress.name === 'passed' ||
                      applicant.status.progress.name === 'offered' ||
                      applicant.status.progress.name === 'dropped' ||
                      applicant.status.progress.name === 'hired' ||
                      applicant.status.progress.name === 'canceled' ||
                      applicant.status.progress.name === 'offer_canceled')
                      ? '未'
                      : ''
                  }}
                </template>
                <template v-else>
                  {{ $utils.getValueFromNestedObject(applicant, relation) }}
                </template>
              </span>
            </template>
            <template v-if="col.isAdditional">
              <template v-if="flagMode">
                <span
                  v-for="(tag, iTag) in applicantsTags &&
                  applicantsTags[applicant.id] &&
                  applicantsTags[applicant.id][col.name]
                    ? applicantsTags[applicant.id][col.name]
                    : []"
                  :key="iTag"
                >
                  {{ tag.name }}
                </span>
              </template>
              <span
                v-else-if="
                  !flagMode &&
                  applicant.surveyData &&
                  applicant.surveyData.columns.length > 0
                "
              >
                {{
                  applicant.surveyData &&
                  applicant.surveyData.columns[
                    iCol +
                      applicant.surveyData.columns.length -
                      filteredHeader.length
                  ]
                }}
              </span>
            </template>
            <span v-if="isSelectable === true && col.name === 'isSelect'">
              <input
                :ref="'select_' + applicant.id"
                type="checkbox"
                name="select"
                value="1"
                :checked="
                  selectedApplicants.find(id => id === applicant.id) !==
                  undefined
                "
                :disabled="applicant.isDisabled"
                @change="onClickCheck(applicant, $event)"
              />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
  nextTick,
} from 'vue';
import cloneDeep from 'lodash/cloneDeep';

import Breakpoints from '@/defines/breakpoints';
import { applicantListColumns } from '@/defines/applicant';

export default defineComponent({
  name: 'SelectableApplicantTable',
  props: {
    applicants: {
      type: Array,
      default: () => [],
    },
    initSelectedApplicants: {
      type: Array,
      default: () => [],
    },
    highlightApplicantIds: {
      type: Array,
      default: () => [],
    },
    invisibleColumns: {
      type: Array,
      default: () => [],
    },
    headerAdditional: {
      type: Array,
      default: () => [],
    },
    headerPreAdditional: {
      type: Array,
      default: () => [],
    },
    applicantsTags: {
      type: Object,
      default: () => {},
    },
    flagMode: {
      type: Boolean,
      default: true,
    },
    isSortable: {
      type: Boolean,
      default: true,
    },
    isScrollable: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    isFixedColumns: {
      type: Boolean,
      default: false,
    },
    isDoubleCheck: {
      type: Boolean,
      default: false,
    },
    isInitialSelectAll: {
      type: Boolean,
      default: false,
    },
    canMoveDetail: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onChangeSort', 'onChangeCurrentSelect'],
  setup(props, context) {
    const refSelectAll = ref([]);
    const refCols = ref({});
    // 左側に固定表示されるカラム配列
    const FIXED_COLUMNS = [
      'isSelect', // チェックボックス
      'event_title', // 選考名
      'unread_count', // 未読
      'status', // ステータス
      'attendanceAt', // 参加日
      'reminderCheck', // 参加確認
      'not_announe_lamp', // 結果未通知
      'name', // 名前
    ];
    // SPサイズの場合に非表示にするカラム配列
    const HIDE_COLUMNS_ON_SP = [
      'event_title', // 選考名
      'attendanceAt', // 参加日
      'reminderCheck', // 参加確認
      'not_announe_lamp', // 結果未通知
      'name_kana', // セイメイ
      'message_created_at', // 新着メッセージ日時
      'contact_type_status', // 連絡方法
      'gender', // 性別
      'school', // 学校名
      'faculty', // 学部
      'department', // 学科
      'department_category', // 文理
      'email', // メールアドレス
      'tel', // 電話番号
      'channel', // 登録経路
      'invited_status', // エントリーフォーム送信
      'invited_at', // 送信日時
      'created_at', // 登録日
      'activated_at', // エントリー日
      'staff', // 担当者
    ];
    // 選択中応募者
    const selectedApplicants = ref(props.initSelectedApplicants);
    // ヘッダーの基本配列
    const headerBasic = ref(applicantListColumns);
    // ヘッダーのソートキー
    const headerSortBy = ref('');
    // ヘッダーのソート順
    const headerSortOrder = ref(null);
    // 固定カラムの幅データ配列
    const columnsWidth = ref(
      FIXED_COLUMNS.map(v => ({ col_name: v, width: 0 })),
    );
    // 実際に表示するヘッダー
    const filteredHeader = computed(() =>
      // headerPreAdditionalはヘッダーの一番先頭に並ぶ
      cloneDeep(props.headerPreAdditional)
        .concat(
          headerBasic.value.filter(col => {
            if (props.isSelectable === false && col.name === 'isSelect') {
              // isSelectableがfalseの場合、チェックボックスは非表示
              return false;
            }
            if (
              window.innerWidth <= Breakpoints.MAX_SP_SIZE &&
              HIDE_COLUMNS_ON_SP.includes(col.name)
            ) {
              // 一定の画面サイズ以下の場合、特定のカラムは強制的に非表示
              return false;
            }
            return true;
          }),
        )
        // headerAdditionalはヘッダーの最後尾に並ぶ
        .concat(cloneDeep(props.headerAdditional))
        // invisibleColumnsで指定された場合は非表示にする
        .filter(col => !props.invisibleColumns.find(v => col.name === v)),
    );

    // watch
    watch(
      () => props.initSelectedApplicants,
      val => (selectedApplicants.value = val),
    );
    watch(
      () => props.applicants,
      () => {
        resetCheckAll();
        emitCurrentSelectApplicants();
      },
    );
    watch(
      () => props.invisibleColumns,
      () => updateColumnsWidth(),
    );

    // methods
    // カラム押下時のイベント
    const onClickColumn = (applicant, col) => {
      if (col.name !== 'isSelect' && col.name !== 'createNewTab') {
        // チェックボック以外のカラム押下時は応募者詳細画面への遷移
        window.open(`/applicants/${applicant.id}?menu=0`, '_blank');
      }
    };
    // チェックボックス押下時のイベント
    const onClickCheck = (applicant, ev, checked) => {
      const index = selectedApplicants.value.findIndex(
        applicantId => applicantId === applicant.id,
      );
      const newSelected = Object.assign([], selectedApplicants.value);
      if ((ev && ev.target.checked) || (ev === null && checked)) {
        newSelected.push(applicant.id);
      } else if (index !== undefined) {
        newSelected.splice(index, 1);
        if (refSelectAll.value[0]) refSelectAll.value[0].checked = false;
      }
      selectedApplicants.value = newSelected;
      emitCurrentSelectApplicants();
    };
    // 全選択チェックボックス押下時のイベント
    const onClickCheckAll = ev => {
      let selectedAllApplicantsIds = props.applicants.map(
        applicant => applicant.id,
      );
      const currentSelectedApplicantsIds = Object.assign(
        [],
        selectedApplicants.value,
      );
      const disabledApplicantIds = props.applicants
        .filter(x => x.isDisabled)
        .map(x => x.id);
      if (disabledApplicantIds.length) {
        selectedAllApplicantsIds = selectedAllApplicantsIds.filter(
          x => !disabledApplicantIds.includes(x),
        );
      }
      if (ev.target.checked) {
        selectedApplicants.value = currentSelectedApplicantsIds
          .concat(selectedAllApplicantsIds)
          .filter((x, i, self) => self.indexOf(x) === i);
      } else {
        selectedApplicants.value = currentSelectedApplicantsIds.filter(
          applicantId => selectedAllApplicantsIds.indexOf(applicantId) === -1,
        );
      }
      emitCurrentSelectApplicants();
    };
    // ヘッダー押下時のイベント
    const onClickHeader = col => {
      if (
        headerSortOrder.value === null ||
        headerSortBy.value !== col.sort_by
      ) {
        headerSortOrder.value = true;
      } else {
        headerSortOrder.value = !headerSortOrder.value;
      }
      headerSortBy.value = col.sort_by;
      context.emit('onChangeSort', {
        sort_by: headerSortBy.value,
        sort_order: headerSortOrder.value,
      });
    };
    // 未読数で並び替え押下時のイベント（他のcomponentから呼ばれている関数なので注意）
    const onClickShowUnreadCount = col => {
      headerSortOrder.value = col.sort_order;
      headerSortBy.value = col.sort_by;
      context.emit('onChangeSort', {
        sort_by: headerSortBy.value,
        sort_order: headerSortOrder.value,
      });
    };
    // 選択中応募者を親コンポーネントへemitする
    const emitCurrentSelectApplicants = () => {
      context.emit('onChangeCurrentSelect', {
        selectedApplicants: selectedApplicants.value,
      });
    };
    // 全選択チェックボックスのリセット
    const resetCheckAll = () => {
      if (refSelectAll.value && refSelectAll.value[0]) {
        refSelectAll.value[0].checked = props.isInitialSelectAll;
      }
    };
    // 固定カラムデータの更新
    const updateColumnsWidth = () => {
      if (
        props.isFixedColumns !== true ||
        window.innerWidth <= Breakpoints.MAX_SP_SIZE
      ) {
        return;
      }
      // DOMの更新が終了したタイミングでrefの値をローカルの配列に代入
      nextTick(() => {
        // 非表示のカラムをつめる
        columnsWidth.value = columnsWidth.value.map(col => ({
          ...col,
          width:
            props.invisibleColumns.find(v => col.col_name === v) ||
            !refCols.value[col.col_name]
              ? 0
              : refCols.value[col.col_name],
        }));
      });
    };
    // カラムのスタイルを取得
    const getColumnStyle = (columnName, baseZIndex = 0) => {
      if (
        props.isFixedColumns !== true ||
        window.innerWidth <= Breakpoints.MAX_SP_SIZE
      ) {
        return {};
      }
      const obj = { position: 'sticky', zIndex: baseZIndex };
      if (FIXED_COLUMNS.includes(columnName)) {
        // FIXED_COLUMNSは横スクロールで隠さない為z-indexを1上げる
        obj.zIndex = baseZIndex + 1;
        // FIXED_COLUMNSはscrollさせないように固定配置
        const widthList = [];
        [...Array(FIXED_COLUMNS.indexOf(columnName)).keys()].forEach(index => {
          widthList.push(columnsWidth.value[index].width);
        });
        obj.left = `${
          widthList.length > 0
            ? widthList.reduce((accumulator, val) => accumulator + val)
            : 0
        }px`;
      }
      return obj;
    };

    // lifecycle
    onMounted(() => {
      resetCheckAll();
      updateColumnsWidth();
    });

    return {
      refSelectAll,
      refCols,
      headerSortBy,
      headerSortOrder,
      selectedApplicants,
      filteredHeader,
      getColumnStyle,
      onClickColumn,
      onClickShowUnreadCount,
      onClickHeader,
      onClickCheck,
      onClickCheckAll,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.area-table {
  max-height: 800px;
  overflow-x: auto;
  table {
    width: 100%;
    thead {
      &.is-hide {
        display: none;
      }
      tr {
        background-color: #e2e2e2;
        th {
          position: sticky;
          z-index: 12;
          top: 0;
          background-color: #e2e2e2;
          padding: 0 1.5rem;
          height: 3.6rem;
          text-align: left;
          border-collapse: collapse;
          white-space: nowrap;
          input[type='checkbox'] {
            appearance: none;
            width: 1.4rem;
            height: 1.4rem;
            border-radius: 0.4rem;
            border: 2px solid #9d9d9d;
            background: #fff;
            position: relative;
            transform: translate3d(0px, 0px, 0px);
            cursor: pointer;
            &:checked {
              &::after {
                content: url(../../../../assets/img/check.svg);
                height: 1.1rem;
                width: 1.5rem;
                margin: -5px 0 0 1px;
                position: absolute;
                bottom: 3px;
                left: 0;
              }
            }
          }
          &:first-of-type {
            min-width: 3rem;
          }
          > div {
            display: flex;
            align-items: center;
          }
          &.sortable > div {
            cursor: pointer;
          }
          .area-icon-sortable {
            display: inline;
            margin-left: 4px;
            .icon-sortable {
              img {
                width: 14px;
                height: 7px;
              }
              &.selected {
                * {
                  color: $mochica_color;
                  stroke: $mochica_color;
                }
                svg {
                  transform: rotate(180deg);
                  height: 0.8rem;
                  &.icon-sortable-svg-down {
                    transform: rotate(0deg);
                  }
                }
              }
            }
            .icon-sortable-svg-up {
              width: 1.2rem;
              fill: none;
              stroke-width: 2;
              stroke: $mochica_color_gray;
            }
            .icon-sortable-svg-down {
              @extend .icon-sortable-svg-up;
            }
          }
        }
      }
    }
    tbody {
      background: white;
      white-space: nowrap;
      tr {
        height: 4.4rem;
        border: none;
        border-bottom: 1px solid #e2e2e2;
        transition: all ease 0.1s;
        cursor: pointer;
        &:hover {
          background-color: #f1f1f1;
        }
        td {
          padding: 0 1.5rem;
          border-collapse: collapse;
          background: #fff;
          z-index: 10;
          .name_color_red {
            color: $mochica_color_red;
            font-weight: bold;
          }
          .tel_color_red {
            color: $mochica_color_red;
            font-weight: bold;
          }
          .has-unread {
            color: #e83d86;
            position: sticky;
            left: 0;
          }
          .has-unread-line,
          .has-unread-mail {
            display: block;
            text-align: center;
            color: #e83d86;
          }
          .status_default {
            display: inline-block;
            border-radius: 83px;
            width: 9rem;
            text-align: center;
            height: 28px;
            line-height: 27px;
          }
          .not_set_selection {
            background-color: $not_set_selection_bg;
            color: $white;
            font-weight: bold;
          }
          .not_announced {
            background-color: $not_announced_bg;
            color: $white;
            font-weight: bold;
          }
          .announced {
            color: $announced_color;
            font-weight: bold;
          }
          .not_evaluated {
            background-color: $not_evaluated_bg;
            color: $white;
            font-weight: bold;
          }
          .pending {
            background-color: $pending_bg;
            color: $white;
            font-weight: bold;
          }
          .finished {
            border-color: $finished_border;
            background-color: $finished_bg;
            color: #fff;
            font-weight: bold;
          }
          .hired {
            color: $hired_color;
            font-weight: bold;
          }
          .offered {
            color: $offered_color;
            font-weight: bold;
          }
          .dropped {
            color: $dropped_color;
            font-weight: bold;
          }
          .canceled {
            color: $canceled_color;
            font-weight: bold;
          }
          .offer_canceled {
            color: $offer_canceled_color;
            font-weight: bold;
          }
          .passed {
            background-color: $passed_bg;
            color: $white;
            font-weight: bold;
          }
          .create_new_tab {
            padding: 0.5rem 1rem;
            border-radius: 0.4rem;
            cursor: pointer;
            &:hover {
              background-color: #fff;
            }
          }
        }
        td.not_announe_lamp {
          color: #e83d86;
          text-align: center;
          padding: 0;
          padding-right: 1.5rem;
        }
        td.red-bold {
          color: $mochica_color_red;
          font-weight: bold;
        }
      }
      tr.can-move-detail {
        cursor: pointer;
        td {
          &:first-of-type {
            min-width: 3rem;
            // @include flex_center_center;
          }
        }
        input[type='checkbox'] {
          appearance: none;
          width: 1.4rem;
          height: 1.4rem;
          border-radius: 0.4rem;
          border: 2px solid #9d9d9d;
          background: #fff;
          position: relative;
          transform: translate3d(0px, 0px, 0px);
          cursor: pointer;
          &:checked {
            &::after {
              content: url(../../../../assets/img/check.svg);
              height: 1.1rem;
              width: 1.5rem;
              margin: -5px 0 0 1px;
              position: absolute;
              bottom: 3px;
              left: 0;
            }
          }
        }
      }
      tr.is-disabled {
        opacity: 0.6;
        > td {
          background-color: #ededed;
        }
      }
      tr.highlight {
        > td {
          background-color: #f9f2d9;
        }
      }
    }
  }
  .table-scrollable {
    text-align: left;
    thead {
      tr {
        th {
          table-layout: fixed;
          height: 3.6rem;
          padding-left: 1rem;
        }
      }
    }
    tbody {
      overflow-y: scroll;
      // height: 400px;
      tr {
        td {
          table-layout: fixed;
          height: 3.6rem;
          padding-left: 1rem;
        }
      }
    }
  }
  .contact_line {
    color: #1dcd00;
    font-weight: bold;
  }
  .contact_block {
    color: $mochica_color_red;
  }
}

@media (max-width: ($media_query_sp)) {
  .area-table table {
    font-size: 1.1rem;
    thead tr th {
      padding: 0 5px;
      &:first-of-type {
        padding: 0 5px 0 10px;
      }
    }
    tbody tr td {
      padding: 0 5px;
      &:first-of-type {
        padding: 0 5px 0 10px;
      }
    }
    tbody tr td .status_default {
      width: 100%;
      height: auto;
      padding: 0 5px;
      font-size: 0.9rem;
    }
  }
}
</style>
