<template>
  <li class="talk_wrapper">
    <div
      class="talk_inner"
      :class="{ 'is-staff-talk': !isApplicantTalk(talk) }"
    >
      <img
        v-if="isApplicantTalk(talk)"
        src="@/assets/img/chat_item_black.svg"
      />
      <div>
        <div class="talk_head">
          <div v-if="isSystemMessage(talk)" class="talk_label is-system">
            <span :class="isLineMessage(talk) ? 'icon-line' : 'icon-mail'">
              システム
            </span>
          </div>
          <div v-else-if="isLineMessage(talk)" class="talk_label is-line">
            <span>LINE</span>
          </div>
          <div v-else class="talk_label is-mail">
            <span>メール</span>
          </div>
          <p>
            <template v-if="isApplicantTalk(talk)">
              {{ applicantFullName }}
            </template>
            <template v-else-if="talk.staff">
              {{ talk.staff.lastname + ' ' + talk.staff.firstname }}
            </template>
            <template v-else>システム管理</template>
            <span class="date_for_pc">
              -
              {{
                $utils.parseDateTime(
                  new Date(talk.created_at).toISOString(),
                  'YYYY/MM/DD HH:mm',
                )
              }}
            </span>
          </p>
        </div>
        <div class="talk_body_outer">
          <div
            v-if="isLineMessage(talk)"
            class="talk_body is-line"
            :class="{
              'is-line': !isSystemMessage(talk),
              'is-system': isSystemMessage(talk),
            }"
          >
            <div class="talk_content">
              <p
                v-if="checkDownloadContent(talk.content)"
                class="download_link"
                @click="postLineContentDownload(talk.message_id, talk.content)"
              >
                <img src="@/assets/img/icon_download.svg" />
                <span>{{ talk.content }}</span>
              </p>
              <p v-else-if="checkImage(talk.content)" class="line_talk_image">
                <img :src="talk.content" alt="添付画像" />
              </p>
              <p v-else>{{ talk.content }}</p>
            </div>
            <div
              v-if="talk.unread_need && !talk.system"
              class="unread_check_wrapper"
              :class="{ 'is-opened': talk.unread_check === true }"
            >
              <input
                v-model="talk.unread_check"
                onclick="return false;"
                type="checkbox"
                name="unread_check_box"
                @click="onClickUnreadCheck(talk)"
              />
              <label @click="onClickUnreadCheck(talk)">
                <div class="toggle_unread" />
                <p>
                  {{
                    talk.unread_check === true
                      ? '確認ボタンをクリック済み'
                      : '確認ボタンを未クリック'
                  }}
                </p>
              </label>
            </div>
          </div>
          <div
            v-else
            class="talk_body"
            :class="{
              'is-mail': !isSystemMessage(talk),
              'is-system': isSystemMessage(talk),
            }"
          >
            <p class="mail_title">{{ talk.mail_title }}</p>
            <div class="talk_content">
              <p>{{ talk.mail_content }}</p>
              <div
                v-if="talk.uploads && talk.uploads.length > 0"
                class="mail_uploaded_file_area"
              >
                <p>添付ファイル</p>
                <ul>
                  <li
                    v-for="upload in talk.uploads"
                    :key="`upload_${upload.id}`"
                    @click="$emit('downloadFile', upload)"
                  >
                    <img src="@/assets/img/icon_download.svg" />
                    <span>
                      {{ upload.filename }} ({{
                        conversionToMegaByte(upload.filesize, 100)
                      }}MB)
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div
              v-if="talk.unread_need && !talk.system"
              class="unread_check_wrapper"
              :class="{ 'is-opened': talk.unread_check === true }"
            >
              <input
                v-model="talk.unread_check"
                onclick="return false;"
                type="checkbox"
                name="unread_check_box"
                @click="onClickUnreadCheck(talk)"
              />
              <label @click="onClickUnreadCheck(talk)">
                <div class="toggle_unread" />
                <p>
                  {{
                    talk.unread_check === true
                      ? '確認ボタンをクリック済み'
                      : '確認ボタンを未クリック'
                  }}
                </p>
              </label>
            </div>
          </div>
          <div v-if="isApplicantTalk(talk)" class="toggle_open_wrapper">
            <div
              class="toggle_open"
              :class="{ opened: talk.already === 1 }"
              @click="updateMessageOpened(talk.id, talk.already === 1 ? 2 : 1)"
            />
            <p>{{ talk.already === 1 ? '既読' : '未読' }}</p>
          </div>
        </div>
        <span
          class="date_for_mobile"
          :class="{ 'is-applicant_talk': isApplicantTalk(talk) }"
        >
          {{
            $utils.parseDateTime(
              new Date(talk.created_at).toISOString(),
              'YYYY/MM/DD HH:mm',
            )
          }}
        </span>
      </div>
    </div>
  </li>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

import lineTalkService from '@/services/linetalk';
import { conversionToMegaByte, getFileExtension } from '@/utils/file';
import { postPottosEvent } from '@/utils/pottos';

export default defineComponent({
  name: 'TalkItem',
  props: {
    talk: {
      type: Object,
      required: true,
    },
  },
  emits: ['downloadFile', 'updateMessage', 'reloadMessages'],
  setup(props, context) {
    const store = useStore();
    const applicant = computed(() => {
      return store.getters['applicant/applicant'];
    });
    const applicantFullName = computed(() => {
      return store.getters['applicant/applicantFullName'];
    });

    const onClickUnreadCheck = async talk => {
      store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
        accentColor: '#1899D6',
        title: 'メッセージ確認',
        message: 'メッセージ確認を変更しますか？',
        buttonText1: 'キャンセル',
        buttonText2: 'はい',
        onSelected: async payload => {
          if (payload.choice === 2) {
            const res = await lineTalkService.unreadCheckMessage({
              applicant_id: applicant.value.id,
              message_id: talk.id,
              unread_check: !talk.unread_check,
            });
            if (res.success) {
              await context.emit('reloadMessages');
              store.dispatch('notification/VISIBLE_NOTIFICATION', {
                message: 'メッセージ確認を変更しました',
                type: true,
              });
              store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
            } else {
              store.dispatch('notification/VISIBLE_NOTIFICATION', {
                message: 'メッセージ確認を変更できませんでした',
                type: false,
              });
              store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
            }
          } else {
            store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
          }
        },
        onclickOuter: async () => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        isDisabledShowModalDisplayed: true,
      });
    };
    const checkImage = payload => {
      const res = payload.indexOf('storage.googleapis.com');
      return res !== -1;
    };
    const checkDownloadContent = text => {
      // LINEメッセージがリンクであるかチェック
      return getFileExtension(text) !== null;
    };
    const postLineContentDownload = async (
      lineMessageId,
      lineMessageContent,
    ) => {
      const isPrefix = getFileExtension(lineMessageContent);
      if (isPrefix !== null) {
        await lineTalkService.postLineContentDownload(
          lineMessageId,
          lineMessageContent,
        );
      }
    };
    const updateMessageOpened = async (messageId, isOpened) => {
      postPottosEvent(52);
      const res = await lineTalkService.updateMessageOpened(
        applicant.value.id,
        messageId,
        isOpened,
      );
      if (res.success) context.emit('updateMessage', { messageId, isOpened });
    };
    const isSystemMessage = talk => {
      return talk.system === true;
    };
    const isLineMessage = talk => {
      return talk.content;
    };
    const isApplicantTalk = talk => {
      return talk.io === false;
    };

    return {
      applicantFullName,
      onClickUnreadCheck,
      checkImage,
      checkDownloadContent,
      postLineContentDownload,
      updateMessageOpened,
      isSystemMessage,
      isLineMessage,
      isApplicantTalk,
      conversionToMegaByte,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.talk_wrapper {
  padding: 1.5rem;
}

.talk_inner {
  display: flex;
  > img {
    display: block;
    width: 2.7rem;
    height: 2.7rem;
    margin-right: 1rem;
    border-radius: 2.7rem;
  }
  &.is-staff-talk {
    flex-direction: row-reverse;
    .talk_head {
      flex-direction: row-reverse;
      > p {
        text-align: right;
      }
    }
    .talk_label {
      margin-left: 0.5rem;
      margin-right: 0;
    }
    .talk_body {
      margin: 0 0 0 auto;
      border-radius: 4px 0 4px 4px;
    }
  }
}

.talk_head {
  display: flex;
  > p {
    font-size: 1.2rem;
    line-height: 2;
    white-space: nowrap;
  }
}

.talk_label {
  width: 8.4rem;
  padding: 0.5rem 0.6rem;
  margin-right: 0.5rem;
  line-height: 1;
  border-radius: 4px 4px 0 0;
  color: #fff;
  background-color: #ccc;
  > span {
    vertical-align: -2px;
  }
  &.is-line {
    border-color: $line_color;
    background-color: $line_color;
    span::before {
      content: '';
      mask-image: url(../../../../../assets/img/icon_line_white.svg);
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      display: inline-block;
      position: relative;
      background-color: #ffffff;
      height: 17px;
      width: 17px;
      top: 2px;
      margin-right: 6px;
    }
  }
  &.is-mail {
    border-color: $email_color;
    background-color: $email_color;
    span::before {
      content: '';
      mask-image: url(../../../../../assets/img/icon_email.svg);
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      display: inline-block;
      position: relative;
      background-color: #ffffff;
      height: 15px;
      width: 15px;
      margin-right: 3px;
    }
  }
  &.is-system {
    padding-left: 1rem;
    border-color: $system_color;
    background-color: $system_color;
    .icon-line::before {
      content: '';
      mask-image: url(../../../../../assets/img/icon_line_white.svg);
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      display: inline-block;
      position: relative;
      background-color: #ffffff;
      height: 17px;
      width: 17px;
      top: 2px;
      margin-right: 1px;
    }
    .icon-mail::before {
      content: '';
      mask-image: url(../../../../../assets/img/icon_email.svg);
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      display: inline-block;
      position: relative;
      background-color: #ffffff;
      height: 15px;
      width: 15px;
    }
  }
}

.talk_body_outer {
  display: flex;
}

.talk_body {
  display: block;
  width: 530px;
  border: 2px solid #ccc;
  background-color: #fff;
  border-radius: 0 4px 4px 4px;
  line-height: 1.6;
  letter-spacing: 0.1rem;
  white-space: pre-wrap;
  word-break: break-all;
  &.is-line {
    border-color: $line_color;
  }
  &.is-mail {
    border-color: $email_color;
  }
  &.is-system {
    border-color: $system_color;
  }
}

.talk_content {
  padding: 1rem;
}

.mail_title {
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.download_link {
  cursor: pointer;
  > span {
    margin-left: 0.5rem;
    border-bottom: 1px solid $mochica_color;
    font-weight: bold;
    color: $mochica_color;
  }
}

.line_talk_image {
  text-align: center;
  > img {
    max-width: 100%;
    height: auto;
  }
}

.mail_uploaded_file_area {
  padding: 1.5rem;
  margin-top: 1rem;
  border: 1px solid #ededed;
  background-color: #f8f8f8;
  border-radius: 3px;
  > p {
    font-weight: bold;
    color: #888;
    &::before {
      content: '';
      mask-image: url(../../../../../assets/img/icon_file.svg);
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      display: inline-block;
      position: relative;
      background-color: #888888;
      height: 14px;
      width: 14px;
      margin-right: 4px;
      top: 1px;
    }
  }
  > ul {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    border-top: 4px solid #ededed;
    > li {
      margin-bottom: 0.3rem;
      color: $mochica_color;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
      > span {
        margin-left: 0.5rem;
        border-bottom: 1px solid $mochica_color;
      }
    }
  }
}

.toggle_open_wrapper {
  display: flex;
  > p {
    line-height: 2rem;
  }
}

.toggle_open {
  display: inline-block;
  margin: 0 0.5rem 0 1rem;
  height: 2rem;
  width: 2rem;
  line-height: 2rem;
  text-align: center;
  border-radius: 1rem;
  background-color: #fff;
  border: 1px dashed #bbb;
  cursor: pointer;
  &::before {
    content: '';
    mask-image: url(../../../../../assets/img/icon_check.svg);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    display: inline-block;
    position: relative;
    background-color: #cccccc;
    height: 13px;
    width: 13px;
  }
  &.opened {
    background-color: #06c755;
    border: 1px solid #05a345;
    &::before {
      background-color: #ffffff;
    }
  }
}

.unread_check_wrapper {
  display: flex;
  justify-content: right;
  width: calc(100% - 2rem);
  margin: 0 1rem;
  border-top: 1px solid #e3e3e3;
  > input {
    display: none;
  }
  > label {
    display: flex;
    margin: 1rem 1rem 1rem auto;
    cursor: pointer;
    > p {
      line-height: 1.6rem;
      color: #777;
    }
    .toggle_unread {
      display: inline-block;
      margin-right: 6px;
      height: 1.6rem;
      width: 1.6rem;
      line-height: 1.6rem;
      text-align: center;
      border-radius: 0.8rem;
      background-color: #fff;
      border: 1px dashed #bbb;
      cursor: pointer;
      &::before {
        content: '';
        mask-image: url(../../../../../assets/img/icon_check.svg);
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        display: inline-block;
        position: relative;
        background-color: #cccccc;
        height: 11px;
        width: 11px;
        top: -1px;
      }
    }
  }
  &.is-opened {
    > label {
      > p {
        color: #4698d1;
        font-weight: bold;
      }
      .toggle_unread {
        background-color: #4698d1;
        border: 1px solid #1b7bbe;
        &::before {
          background-color: #ffffff;
        }
      }
    }
  }
}

.date_for_pc {
  display: inline-block;
  margin-left: 0.5rem;
}

.date_for_mobile {
  display: none;
}

@media (max-width: ($media_query_sp)) {
  .talk_body {
    width: 285px;
  }
  .talk_wrapper {
    > img {
      display: none;
    }
  }
  .date_for_pc {
    display: none;
  }
  .date_for_mobile {
    text-align: right;
    display: block;
    margin-top: 10px;
    color: #777;
    &.is-applicant_talk {
      text-align: left;
    }
  }
  .talk_head > p {
    width: calc(280px - 92px);
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .toggle_open_wrapper {
    > p {
      display: none;
    }
  }
  .talk_inner {
    display: flex;
    > img {
      display: none;
    }
  }
}
</style>
