<template>
  <main class="guide">
    <div class="content">
      <div class="title">
        <!-- <icon-logo class="logo" /> -->
        <span class="text">初期設定</span>
      </div>
      <guide-step-bar class="step-bar" :current-step="step" />
      <form class="form">
        <section v-if="step === 1" class="step-section">
          <h1 class="heading">初期登録種別の設定</h1>
          <div class="guide-text">
            <p>
              LINE公式アカウントを友達追加した際に振り分けられる年度を設定します。
            </p>
            <div class="supplement">
              <p class="item">※後から変更することも可能です。</p>
              <p class="item">
                ※ご利用時のメインとなる年代/対象をご選択ください。
              </p>
            </div>
          </div>
          <div v-if="graduateds.length > 0" class="select-graduated">
            <label class="c-selsect">
              <select v-model="selectedGraduatedId" class="select">
                <option
                  v-for="graduated in graduateds"
                  :key="graduated.year"
                  class="select-elements"
                  :label="graduatedYearToLabelName(graduated.year)"
                  :value="graduated.id"
                >
                  {{ graduatedYearToLabelName(graduated.year) }}
                </option>
              </select>
            </label>
          </div>
        </section>
        <section v-if="step === 2" class="step-section">
          <h1 class="heading">未読メッセージの通知設定</h1>
          <div class="guide-text">
            <p>
              MOCHICA上に未読のメッセージがある場合、
              <br class="sp-hide-br" />
              9時,13時,16時の3回、MOCHICAに社員登録されている
              <br class="sp-hide-br" />
              メールアドレス宛に届きます。
            </p>
            <div class="supplement">
              <p class="item">※後から変更することも可能です。</p>
              <p class="item">
                ※未読メッセージの詳細は
                <a
                  class="link"
                  href="https://help.mochica.jp/operation/%e6%9c%aa%e8%aa%ad%e4%b8%80%e8%a6%a7%e3%81%ae%e8%a1%a8%e7%a4%ba/"
                  target="_blank"
                >
                  こちら
                </a>
              </p>
              <p class="item">
                ※未読メッセージの処理方法は
                <a
                  class="link"
                  href="https://help.mochica.jp/operation/readline/"
                  target="_blank"
                >
                  こちら
                </a>
              </p>
            </div>
          </div>
          <div class="line-unread-alert">
            <label
              class="entryform-setting-label"
              for="linealert"
              :class="{ checked: linealert }"
            >
              <input
                id="linealert"
                v-model="linealert"
                class="entryform_setting_input"
                type="checkbox"
              />
              <span class="entryform_setting_text">通知を受け取る</span>
            </label>
          </div>
        </section>
        <section v-if="step === 3" class="step-section">
          <h1 class="heading">エントリーフォームの設定</h1>
          <div class="guide-text">
            <p>
              エントリーフォームの項目をカスタマイズしてください。（後で変更可）
            </p>
            <div class="supplement">
              <p class="item">
                ※姓名、フリガナ、卒年度の項目は必須のため外すことができません。
                <br />
                ※メールアドレス入力をスキップ機能のON/OFFは管理画面の「会社設定」から変更いただけます。
                <br />
                ※入力項目が多いと、応募者が登録する際の工数が増加しますので、最小限の項目設定を推奨いたします。
              </p>
            </div>
          </div>
          <div class="guide-entry-form-setting">
            <div class="entryform-warp">
              <div class="entryform_setting">
                <label class="guide-entry-form-setting-label" for="">
                  エントリーフォームの項目
                </label>
                <entry-form-setting-check-list
                  :graduated-year="selectedGraduatedYear"
                  :form-keys="formKeys"
                  :form-setting="formSetting"
                  :get-form-label="getFormLabel"
                  @setPreviewData="setPreviewData"
                />
              </div>
              <div class="preview_wrapper">
                <p class="guide-entry-form-setting-label">プレビュー画面</p>
                <entry-form-setting-preview
                  ref="refEntryFormSettingPreview"
                  :company-name="company.name"
                  :graduateds="graduateds"
                  :graduated-year="selectedGraduatedYear"
                  :form-setting="formSetting"
                  :toggle-switch="toggleSwitch"
                />
              </div>
            </div>
          </div>
        </section>
        <section v-if="step === 4" class="step-section">
          <h1 class="heading">初期設定の確認</h1>
          <div class="guide-text">
            <p>
              以下の内容で初期設定を完了します。
              <br class="sp-show-br" />
              内容をご確認ください。
            </p>
          </div>
          <div class="confirm-layout">
            <div class="confirm">
              <div class="confirm-item">
                <div class="confirm-item-header">
                  <h2 class="confirm-item-heading">初期登録種別の設定</h2>
                  <a class="edit-step-link" @click="moveStep(1)">設定を修正</a>
                </div>
                <hr class="confirm-hr" />
                <div>{{ graduatedYearToLabelName(selectedGraduatedYear) }}</div>
              </div>
              <div class="confirm-item">
                <div class="confirm-item-header">
                  <h2 class="confirm-item-heading">未読メッセージの通知設定</h2>
                  <a class="edit-step-link" @click="moveStep(2)">設定を修正</a>
                </div>
                <hr class="confirm-hr" />
                <div v-if="linealert === true">
                  <p>通知を受け取る</p>
                  <p class="confirm-supplement">
                    ※毎日9時、13時、16時に届きます。
                  </p>
                </div>
                <div v-else>通知を受け取らない</div>
              </div>
              <div class="confirm-item">
                <div class="confirm-item-header">
                  <h2 class="confirm-item-heading">エントリーフォームの設定</h2>
                  <a class="edit-step-link" @click="moveStep(3)">設定を修正</a>
                </div>
                <hr class="confirm-hr" />
                <div class="confirm-entry-form">
                  <div class="item-title">選択項目</div>
                  <ul class="item-list">
                    <li
                      v-for="(isCheckedLabel, index) in isCheckedLabels"
                      :key="`label${index}`"
                      class="item"
                    >
                      {{ isCheckedLabel }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section v-if="step === 5" class="step-section">
          <div class="completed-circle-layout">
            <img src="@/assets/img/completed_circle.svg" />
          </div>
          <h1 class="heading">登録が完了しました</h1>
          <div class="completed-text">
            <p>
              MOCHICAの初期設定が完了しました。
              <br />
              以下のボタンからMOCHICAにログインしてください。
            </p>
          </div>
          <div class="to-dashboard-button-layout">
            <button
              type="button"
              class="to-dashboard-button"
              @click="$router.push({ name: 'DashboardBasic' })"
            >
              管理画面へ
            </button>
          </div>
        </section>
        <hr v-if="step !== 5" class="-hr" />
        <div v-if="step !== 5" class="actions">
          <button
            type="button"
            class="btn btn-unavailable"
            :disabled="step === 1"
            @click="prevStep()"
          >
            戻る
          </button>
          <button
            v-if="step < 4"
            type="button"
            class="btn btn-availability"
            @click="nextStep()"
          >
            次へ
          </button>
          <button
            v-else
            type="button"
            class="btn btn-availability register-btn"
            :disabled="isLoading"
            @click="register()"
          >
            登録する
          </button>
        </div>
      </form>
      <div class="logout">
        <button
          class="logout_btn"
          @click.prevent="
            logout();
            postPottosEvent(14);
          "
        >
          ログアウト
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';

import initialSettingsService from '@/services/initial-settings';
import GraduatedDefines from '@/defines/graduated';
import useEntryFormSetting from '@/composables/useEntryFormSetting';
// FIXME: 既存のバグによりガイド画面は表示されないため一時的にコメントアウト。CSS で SVG を挿入すること。
// import iconLogo from '@/assets/img/logo.svg?inline';
import GuideStepBar from '@/components/page/guide/components/GuideStepBar';
import EntryFormSettingCheckList from '@/components/features/entryformSetting/components/EntryFormSettingCheckList';
import EntryFormSettingPreview from '@/components/features/entryformSetting/components/EntryFormSettingPreview';
import { postPottosEvent } from '@/utils/pottos';

export default defineComponent({
  name: 'Guide',
  components: {
    GuideStepBar,
    // iconLogo,
    EntryFormSettingCheckList,
    EntryFormSettingPreview,
  },
  setup(props, context) {
    const store = useStore();
    const {
      formKeys,
      formSetting,
      toggleSwitch,
      isCheckedLabels,
      getFormLabel,
    } = useEntryFormSetting();
    const refEntryFormSettingPreview = ref(null);
    const step = ref(1);
    const isLoading = ref(false);
    const linealert = ref(true);
    const initSelectGraduated = store.getters['graduateds/graduateds'][0];
    const selectedGraduatedId = ref(initSelectGraduated.id);
    const selectedGraduatedYear = ref(initSelectGraduated.year);

    // computed
    const graduateds = computed(() => store.getters['graduateds/graduateds']);
    const company = computed(() => store.getters['company/company']);

    watch(selectedGraduatedId, newVal => {
      selectedGraduatedYear.value = graduateds.value.filter(
        item => item.id === newVal,
      )[0].year;
    });

    const graduatedYearToLabelName = year => {
      const res = GraduatedDefines.find(
        defineTitle => defineTitle.year === year,
      );
      if (res !== undefined) return res.name_jp;
      return `${year}年卒業`;
    };

    const scrollTop = () => {
      window.scrollTo({
        top: 0,
      });
    };

    const nextStep = () => {
      step.value += 1;
      scrollTop();
      setTimeout(() => {
        // 初期値をプレビュー画面にセット
        setPreviewData();
      }, 100);
    };
    const prevStep = () => {
      step.value += -1;
      scrollTop();
      setTimeout(() => {
        // 初期値をプレビュー画面にセット
        setPreviewData();
      }, 100);
    };

    const moveStep = stepNumber => {
      step.value = stepNumber;
      scrollTop();
      setTimeout(() => {
        // 初期値をプレビュー画面にセット
        setPreviewData();
      }, 100);
    };

    const setPreviewData = (key = null) => {
      // refが取得できるまでディレイが必要
      if (
        refEntryFormSettingPreview.value &&
        refEntryFormSettingPreview.value.setPreviewData
      ) {
        // プレビューに渡す登録種別・卒年、必須項目Objectを渡す
        refEntryFormSettingPreview.value.setPreviewData(key);
      }
    };

    const register = async () => {
      isLoading.value = true;
      const res = await initialSettingsService.initializeCompanySetting({
        graduated_id: selectedGraduatedId.value,
        linealert: linealert.value,
        form_setting: { ...formSetting },
      });
      if (res.success !== true) {
        isLoading.value = false;
        return;
      }
      await store.dispatch('auth/INIT');
      step.value = step.value + 1;
      isLoading.value = false;
    };

    const logout = () => {
      store.dispatch('auth/LOGOUT');
    };

    return {
      refEntryFormSettingPreview,
      formKeys,
      formSetting,
      toggleSwitch,
      isCheckedLabels,
      getFormLabel,
      step,
      isLoading,
      linealert,
      selectedGraduatedId,
      selectedGraduatedYear,
      graduateds,
      company,
      graduatedYearToLabelName,
      nextStep,
      prevStep,
      moveStep,
      setPreviewData,
      register,
      logout,
      postPottosEvent,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
@include form_wrapper_css();

.guide {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  background-color: #fafafa;
  min-height: 100vh;
  .content {
    width: 900px;
    max-width: 100%;
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      .logo {
        max-width: 250px;
        width: 100%;
        path {
          fill: #88c6ed;
        }
        @media (max-width: ($media_query_sp)) {
          max-width: 136px;
        }
      }
      .text {
        font-size: 2rem;
        font-weight: bold;
        margin-left: 10px;
        @media (max-width: ($media_query_sp)) {
          font-size: 1.2rem;
        }
      }
    }
    .step-bar {
      margin: 40px 0;
    }
    .form {
      width: 100%;
      background-color: $white;
      margin-top: 30px;
      border: 1px solid $border_color;
      border-radius: 4px;
      @media (max-width: (900px)) {
        // このメディアクエリ値は親要素widthに依存
        border-radius: 0;
        border-left: none;
        border-right: none;
      }
      .step-section {
        padding: 40px 30px;
      }
      .heading {
        display: flex;
        justify-content: center;
        font-size: 2.4rem;
        font-weight: bold;
        margin: 0;
        @media (max-width: (900px)) {
          // このメディアクエリ値は親要素widthに依存
          font-size: 1.6rem;
        }
      }
      .guide-text {
        max-width: 530px;
        margin: 30px auto 0 auto;
        padding: 15px 20px;
        border: 1px solid $border_color;
        font-size: 14px;
        line-height: 2;
        .sp-show-br {
          display: none;
        }
        @media (max-width: ($media_query_sp)) {
          background-color: $white;
          margin: 20px 0 0 0;
          padding: 0;
          border: none;
          .sp-show-br {
            display: block;
          }
          .sp-hide-br {
            display: none;
          }
        }
        .supplement {
          font-size: 12px;
          color: $mochica_color_gray;
          .item {
            margin-top: 5px;
            line-height: 1.6;
            .link {
              cursor: pointer;
            }
          }
        }
      }
      .completed-text {
        margin-top: 20px;
        font-size: 14px;
        line-height: 2;
        text-align: center;
        @media (max-width: ($media_query_sp)) {
          text-align: left;
        }
      }
      .select-graduated {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        > .c-selsect {
          width: 100%;
          max-width: 530px;
          height: 50px;
          display: inline-block;
          position: relative;
        }
        > .c-selsect::after {
          position: absolute;
          top: 50%;
          right: 7px;
          width: 6px;
          height: 6px;
          content: '';
          margin-top: -4px;
          border: 0;
          border-bottom: solid 2px #ccc;
          border-right: solid 2px #ccc;
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        > .c-selsect > .select {
          width: 530px;
          height: 50px;
          max-width: 100%;
          padding: 7px 20px 7px 9px;
          border-radius: 4px;
          border: 1px solid #ccc;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          &::-ms-expand {
            display: none;
          }
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        }
        > .c-selsect > .select > .select-elements {
          color: #333333;
        }
      }
      .line-unread-alert {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        .entryform-setting-label {
          display: flex;
          align-items: center;
          width: 530px;
          max-width: 100%;
          padding: 15px 10px;
          border: 1px solid #dedede;
          background-color: #fcfcfc;
          border-radius: 4px;
          cursor: pointer;
          &.checked {
            background: #def2ff;
          }
          .entryform_setting_input {
            appearance: none;
            width: 1.4rem;
            height: 1.4rem;
            margin-right: 5px;
            border-radius: 4px;
            border: 1px solid #dedede;
            background: #fff;
            position: relative;
            cursor: pointer;
            &:checked {
              &::after {
                content: url(../../assets/img/check.svg);
                height: 1.1rem;
                width: 1.5rem;
                margin: -5px 0 0 1px;
                position: absolute;
                bottom: 3px;
                left: 0;
              }
            }
          }
          .entryform_setting_text {
            display: inline-block;
            margin-left: 0.5rem;
          }
        }
      }
      .-hr {
        border: none;
        border-top: 1px solid $border_color;
      }
      .actions {
        display: flex;
        justify-content: space-between;
        padding: 30px;
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 50px;
          width: 200px;
          max-width: calc(50% - 16px);
          &:disabled {
            opacity: 0;
            pointer-events: none;
          }
        }
        .register-btn {
          &:disabled {
            color: #333;
            background-color: #999;
            opacity: 0.3;
            cursor: not-allowed;
          }
        }
      }
      .guide-entry-form-setting {
        margin-top: 30px;
        display: flex;
        justify-content: center;
      }
      .confirm-layout {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        .confirm {
          width: 100%;
          max-width: 530px;
          background-color: $mochica_light_gray_bg;
          padding: 20px;
          border: 1px solid $border_color;
          border-radius: 4px;
          font-size: 1.3rem;
          @media (max-width: (900px)) {
            // このメディアクエリ値は親要素widthに依存
            font-size: 1rem;
          }
          .confirm-item {
            &:not(:first-child) {
              margin-top: 20px;
            }
            .confirm-item-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .confirm-item-heading {
                font-size: 1.4rem;
                font-weight: bold;
                @media (max-width: (900px)) {
                  // このメディアクエリ値は親要素widthに依存
                  font-size: 1rem;
                }
              }
              .edit-step-link {
                font-size: 1.4rem;
                font-weight: normal;
                @media (max-width: (900px)) {
                  // このメディアクエリ値は親要素widthに依存
                  font-size: 1rem;
                }
              }
            }
          }
          .confirm-hr {
            border: none;
            border-top: 4px solid $border_color;
            margin: 15px 0;
          }
          .confirm-supplement {
            color: $mochica_color_gray;
            margin-top: 10px;
          }
          .confirm-entry-form {
            display: flex;
            &:not(:first-child) {
              margin-top: 20px;
            }
            .item-title {
              width: 84px;
              font-weight: bold;
            }
            .item-list {
              width: 100%;
              .item {
                &:not(:first-child)::before {
                  content: '';
                  display: block;
                  width: 100%;
                  height: 1px;
                  background-color: $border_color;
                  margin: 10px 0;
                }
              }
            }
          }
          .confirm-entry-form-border {
            padding-bottom: 15px;
            border-bottom: 1px solid $border_color;
          }
        }
      }
      .completed-circle-layout {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
      }
      .to-dashboard-button-layout {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        .to-dashboard-button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 64px;
          width: 100%;
          max-width: 320px;
          background-color: $white;
          border: 1px solid $mochica_color;
          color: $mochica_color;
          cursor: pointer;
        }
      }
    }
    .logout {
      display: flex;
      justify-content: flex-end;
      margin: 40px 0;
      padding: 0 30px;
      color: $mochica_color;
      .logout_btn {
        cursor: pointer;
      }
      @media (max-width: (900px)) {
        // このメディアクエリ値は親要素widthに依存
        justify-content: center;
      }
    }
  }
}

%after-under-line {
  content: '';
  display: block;
  height: 4px;
  width: 100%;
  background-color: $mochica_color_gray2;
  margin-top: 15px;
}

.supplement {
  color: $mochica_color_gray;
  margin-bottom: 20px;
}

.entryform-warp {
  display: flex;
  align-items: flex-start;
}

.entryform_setting {
  width: 295px;
  margin-right: 40px;
}

.guide-entry-form-setting-label {
  display: block;
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  &::after {
    @extend %after-under-line;
  }
}

@media (max-width: ($media_query_tablet)) {
  .entryform-warp {
    display: block;
    overflow: hidden;
  }

  .entryform_setting {
    width: 100%;
    margin-bottom: 40px;
  }

  .preview_wrapper {
    width: 100%;
  }
}
</style>
