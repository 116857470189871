<template>
  <main class="flexbox width-sp-limit">
    <div class="contents">
      <div class="form_desc_wrapper contents_wrapper">
        <div class="mochica_desc">
          <div class="company_name">{{ companyName }}</div>
          <div class="selection_title">お知らせ</div>
        </div>
        <p class="mail_desc">
          {{ companyName }}で行われた、イベントについてのお知らせです。
          <br />
          下のボタンから確認して下さい。
        </p>
        <button class="one_time_btn" @click.prevent="$emit('openResult')">
          通知を確認する
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ResultTop',
  props: {
    companyName: {
      type: String,
      required: true,
    },
  },
  emits: ['openResult'],
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.flexbox {
  justify-content: center;
}

.mochica_desc {
  text-align: center;
  padding: 3rem 0 3rem;
  .company_name {
    font-size: 1.8rem;
    font-weight: bold;
  }
  .selection_title {
    font-size: 1.4rem;
    font-weight: bold;
  }
}
.contents {
  width: 80%;
}

@media screen and (max-width: 600px) {
  main > div.contents {
    padding-bottom: 0px;
    height: 100%;
  }
  .contents {
    height: 100vh;
    padding: 0 0 30vh;
    & :deep(.result_result_wrapper) {
      width: 100%;
      max-width: 96rem;
      margin: 0 auto;
      text-align: center;
    }
  }
}
.one_time_btn {
  width: 100%;
  border: none;
  background-color: #656565;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  padding: 10px 0;
  border-radius: 20px;
  cursor: pointer;
}
.mail_desc {
  padding-bottom: 10px;
}
.form_desc_wrapper.contents_wrapper {
  width: 100%;
  text-align: center;
}
</style>
