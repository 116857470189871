import cloneDeep from 'lodash/cloneDeep';

import Staff from '@/models/staff';

const Applicant = function _Applicant(
  id,
  firstname,
  lastname,
  firstnameKana,
  lastnameKana,
  email,
  gender,
  school,
  zipcode,
  address,
  graduated,
  birth,
  faculty,
  department,
  departmentCategory,
  tel,
  channel,
  contactType,
  tagGroups,
  status,
  staff,
  memo,
  createdAt,
  activatedAt,
  invitedAt,
  updatedAt,
  notAnnouneLamp,
  unreadCount,
  unreadLineCount,
  unreadMailCount,
  attendanceDate,
  surveyData,
  lineBlock,
  contactTypeId,
  contactTypeStatus,
  attendanceAt,
  emailRecieve,
  messageCreatedAt,
) {
  const applicant = Object.create(Applicant.prototype);
  applicant.id = id;
  applicant.firstname = firstname;
  applicant.lastname = lastname;
  applicant.firstnameKana = firstnameKana;
  applicant.lastnameKana = lastnameKana;
  applicant.email = email;
  applicant.gender = gender ? gender : { id: 0, name: '', description: '' };
  applicant.school = school;
  applicant.zipcode = zipcode;
  applicant.address = address;
  applicant.graduated = graduated;
  applicant.birth = birth;
  applicant.faculty = faculty;
  applicant.department = department;
  applicant.departmentCategory = departmentCategory
    ? departmentCategory
    : { id: 0, name: '', description: '' };
  applicant.tel = tel;
  applicant.channel = channel;
  applicant.contactType = contactType;
  applicant.tagGroups = tagGroups ? tagGroups.map(tagGroup => tagGroup) : null;
  applicant.status = {
    selectionId: status && status.selection_id ? status.selection_id : null,
    eventTitle: status && status.event_title ? status.event_title : null,
    progress:
      status && status.progress != null
        ? status.progress
        : {
            name: null,
            description: null,
            reminder_check: false,
            send_status_id: null,
            send_status_description: null,
            sended_at: null,
          },
  };
  applicant.staff = Staff.createFromJSON(staff);
  applicant.memo = memo;
  applicant.createdAt = createdAt;
  // (応募者が情報を自身で確認した日付)というものが追加されていますがこれは現状使わない項目だと思います。
  applicant.activatedAt = activatedAt;
  // 未承認に応募者の欄で送信日時を表す項目です。
  applicant.invitedAt = invitedAt;
  // 詳細に表示する更新日時です。
  applicant.updatedAt = updatedAt;
  applicant.not_announe_lamp = notAnnouneLamp;
  // chat未読
  applicant.unreadCount = unreadCount;
  applicant.unreadLineCount = unreadLineCount;
  applicant.unreadMailCount = unreadMailCount;
  applicant.attendanceDate = attendanceDate;
  applicant.surveyData = surveyData;
  applicant.lineBlock = lineBlock;
  applicant.contactTypeId = contactTypeId;
  applicant.contactTypeStatus = contactTypeStatus;
  applicant.attendanceAt = attendanceAt;
  applicant.attendanceAt = attendanceAt;
  applicant.emailRecieve = emailRecieve;
  applicant.messageCreatedAt = messageCreatedAt;
  return applicant;
};

Applicant.createFromJSON = json =>
  Applicant(
    json.id,
    json.firstname,
    json.lastname,
    json.firstname_kana,
    json.lastname_kana,
    json.email,
    json.gender,
    json.school,
    json.zipcode,
    json.address,
    json.graduated,
    json.birth,
    json.faculty,
    json.department,
    json.department_category,
    json.tel,
    json.channel,
    json.contact_type,
    json.tag_groups,
    json.status,
    json.staff,
    json.memo,
    json.created_at,
    json.activated_at,
    json.invited_at,
    json.updated_at,
    json.not_announe_lamp,
    json.unread_count,
    json.unread_line_count,
    json.unread_mail_count,
    json.attendance_date,
    json.survey_data,
    json.line_block,
    json.contact_type_id,
    json.contact_type_status,
    json.attendance_at,
    json.email_recieve,
    json.message_created_at,
  );

Applicant.prototype = {
  jsonObjCreateApplicant() {
    return {
      id: this.id,
      lastname: this.lastname,
      firstname: this.firstname,
      lastname_kana: this.lastnameKana,
      firstname_kana: this.firstnameKana,
      gender_id: this.gender.id,
      school: this.school,
      faculty: this.faculty,
      department: this.department,
      department_category_id: this.departmentCategory.id,
      tel: this.tel,
      birth: this.birth,
      zipcode: this.zipcode,
      address: this.address,
      graduated: this.graduated,
      memo: this.memo,
      contact_type_id: this.contactTypeId,
      constact_type_status: this.contactTypeStatus,
      line_block: this.lineBlock,
      email_recieve: this.emailRecieve,
    };
  },
};

/**
 * ファイル名から拡張子を分離して取得
 * @param {String} text - ベースとなる文字列
 * @param {Object} applicant - 応募者オブジェクト
 * @param {String} companyName - 会社名
 * @return {String} 変換された文字列
 */
Applicant.replaceApplicantNameAndCompanyName = (
  text,
  applicant,
  companyName,
) => {
  const applicantName =
    applicant.firstname == null
      ? applicant.lastname
      : `${applicant.lastname} ${applicant.firstname}`;
  return text
    .replace(/\[applicant_name\]/g, applicantName)
    .replace(/\[company_name\]/g, companyName);
};

/**
 * 応募者配列から本登録応募者のみ取得
 * @param {[Object]} applicants - 応募者オブジェクト配列
 * @return {[Object]} 応募者配列
 */
Applicant.getRegisteredApplicants = applicants => {
  return cloneDeep(
    applicants.filter(
      applicant =>
        (applicant.contact_type_id === 2 && applicant.email !== null) ||
        applicant.contact_type_id === 1 ||
        (applicant.contactTypeId === 2 && applicant.email !== null) ||
        applicant.contactTypeId === 1 ||
        (applicant.contactType === 'LINE' && applicant.email !== null) ||
        applicant.contactType === 'メール',
    ),
  );
};

/**
 * 応募者配列からLINE友達のみ取得
 * @param {[Object]} applicants - 応募者オブジェクト配列
 * @return {[Object]} 応募者配列
 */
Applicant.getLineFriendApplicants = applicants => {
  return cloneDeep(
    applicants.filter(
      applicant =>
        (applicant.contactTypeId === 2 && applicant.email === null) ||
        (applicant.contact_type_id === 2 && applicant.email === null) ||
        (applicant.contactType === 'LINE' && applicant.email === null),
    ),
  );
};

export default Applicant;
