<template>
  <modal :is-view="isVisible === true">
    <template #content>
      <div class="content">
        <div class="body">
          <div class="modal-header">
            <div class="modal-header_inner">
              <div class="modal-header_ttl"></div>
            </div>
            <div class="modal-header_cancell" @click="$emit('onClickCancel')">
              <img class="icon" src="@/assets/img/close_black.svg" alt />
            </div>
          </div>
          <div class="modal-body">
            <div class="selection_search_area">
              <selections-search
                :announce-type="modalType"
                :is-announce-modal="isAnnounceModal"
                :init-graduated-id="initGraduatedId"
                :announce-title="announceSelectionsSearchTitle"
                :is-just-select="isJustSelect"
                :disabled-ids="disabledIds"
                @onSelected="$emit('onSelected', $event)"
                @onChangeCurrentSelect="$emit('onChangeCurrentSelect', $event)"
              />
            </div>
            <div v-if="modalType === 'select-applicant'" class="btn-col2">
              <div class="btn btn-unavailable" @click="$emit('onClickCancel')">
                <img
                  class="icon-leftArrow"
                  src="@/assets/img/arrow_left_white.svg"
                />
                {{ backButtonTitle }}
              </div>
              <div
                class="btn btn-availability"
                @click="$emit('onClickSettle', true)"
              >
                <img src="@/assets/img/arrow_right_white.svg" alt />
                応募者の選択を確定する
              </div>
            </div>
            <div v-else class="btn-col2">
              <div class="btn btn-unavailable" @click="$emit('onClickCancel')">
                {{ backButtonTitle }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent } from 'vue';

import Modal from '@/components/ui/modals/components/Modal';
import SelectionsSearch from '@/components/features/selectionsSearch/components/SelectionsSearch';

export default defineComponent({
  name: 'ModalSelectionSearch',
  components: { Modal, SelectionsSearch },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    isAnnounceModal: {
      type: Boolean,
      default: false,
    },
    announceSelectionsSearchTitle: {
      type: String,
      default: '',
    },
    modalType: {
      type: String,
      default: '',
    },
    backButtonTitle: {
      type: String,
      default: 'キャンセル',
    },
    initGraduatedId: {
      type: Number,
      default: null,
    },
    // 応募者の有無に関わらず、選択したい場合
    isJustSelect: {
      type: Boolean,
      default: false,
    },
    // isJustSelectと併用してください。選択を無効化したい選考IDを指定します。
    disabledIds: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'onClickCancel',
    'onClickSettle',
    'onSelected',
    'onChangeCurrentSelect',
  ],
  setup(props, context) {},
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content {
  @include full_modal;
}
.modal-body {
  text-align: start;
  .selection_search_area {
    padding: 20px;
    background: #fff;
  }
}
img.breadcrumb {
  width: 0.6rem;
  margin: 0 0.8rem;
}
.btn-col2 {
  margin: 20px 0;
}
@media (max-width: ($media_query_sp)) {
  .content .modal-body {
    padding-top: 80px;
  }
}
</style>
